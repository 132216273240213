// eslint-disable-next-line no-restricted-imports
import { Trans } from "@lingui/macro";
import { ADDRESS_ZERO } from "@uniswap/v3-sdk";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import Badge from "components/Badge";
import { ButtonEmpty, ButtonError, ButtonPrimary } from "components/Button";
import { DarkCard, LightCard } from "components/Card";
import { AutoColumn } from "components/Column";
import { RowBetween, RowFixed } from "components/Row";
import { SwitchLocaleLink } from "components/SwitchLocaleLink";
import { SUBGRAPH_URL } from "constants/subgraph";
import request, { gql } from "graphql-request";
import useBTCPrice from "hooks/useBTCPrice";
import { useMinerPoolContract } from "hooks/useContract";
import useMinerInfo from "pages/Mine/hooks/useMinerInfo";
import React, { useCallback, useEffect, useState } from "react";
import Countdown from "react-countdown";
import { Clock } from "react-feather";
import { RibbonContainer } from "react-ribbons";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { CustomLightSpinner, ThemedText } from "theme";
import { shortenAddress } from "utils";
import { getBalanceNumber, getFullDisplayBalance } from "utils/formatBalance";

import BuyMinerModal from "./BuyMinerModal";
import SellMinerModal from "./SellMinerModal";

const PageWrapper = styled.div`
  min-width: 800px;
  max-width: 960px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    min-width: 680px;
    max-width: 680px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    min-width: 600px;
    max-width: 600px;
  `};

  @media only screen and (max-width: 620px) {
    min-width: 500px;
    max-width: 500px;
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    min-width: 340px;
    max-width: 340px;
  `};
`;

const BadgeText = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

// responsive text
// disable the warning because we don't use the end prop, we just want to filter it out
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Label = styled(({ end, ...props }) => (
  <ThemedText.DeprecatedLabel {...props} />
))<{ end?: boolean }>`
  display: flex;
  font-size: 16px;
  justify-content: ${({ end }) => (end ? "flex-end" : "flex-start")};
  align-items: center;
`;

const HoverText = styled(ThemedText.DeprecatedMain)`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  :hover {
    color: ${({ theme }) => theme.deprecated_text4};
    text-decoration: none;
  }
`;

const ResponsiveRow = styled(RowBetween)`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    width: 100%:
  `};
`;

const StyledRibbonContainer = styled(RibbonContainer)`
  margin-right: 1rem;
  height: 100%;
  width: 100%;
`;

interface CaveInfo {
  id: string;
  owner: string;
  stakedPower: string;
  expiration: string;
}

const currentDate = () => new Date();

export default function MinerManage() {
  const { id } = useParams<{ id?: string }>();
  const { account, chainId, connector } = useWeb3React();
  const darkmode = Boolean(window.matchMedia("(prefers-color-scheme: dark)"));
  const [openModal, setOpenModal] = useState<number>(0);
  const [claiming, setClaiming] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const [unstaking, setUnstaking] = useState(false);
  const [caveInfo, setCaveInfo] = useState<CaveInfo>();
  const activeChain = chainId ? chainId : 137;
  const apiUrl = SUBGRAPH_URL[activeChain];

  const minerPoolContract = useMinerPoolContract();
  const minerData = useMinerInfo(activeChain, Number(id));

  const {
    owner,
    caveId,
    expiration,
    stakedPower,
    onSale,
    salePrice,
    pendingRewards,
    loading,
    fetchMinerData,
  } = minerData;

  const btcPrice = useBTCPrice();

  const handleOpenModal = (n: number) => {
    setOpenModal(n);
  };

  useEffect(() => {
    request(
      apiUrl,
      gql`
        query GetCave($id: String!) {
          cave(id: $id) {
            id
            owner
            stakedPower
            expiration
          }
        }
      `,
      {
        id: caveId.toString(),
      }
    )
      .then(({ cave }) => {
        if (cave) {
          setCaveInfo(cave);
        }
      })
      .catch(({ error }) => {
        console.log(error);
      });
  }, [apiUrl, caveId, id]);

  const handleWithdrawn = useCallback(async () => {
    try {
      setClaiming(true);
      const tx = await minerPoolContract?.harvest(id?.toString() ?? 9999999);
      await tx?.wait();
      fetchMinerData();
      setClaiming(false);
    } catch (e) {
      setClaiming(false);
      console.error(e);
    } finally {
      setClaiming(false);
    }
  }, [fetchMinerData, id, minerPoolContract]);

  const handleCancelSale = useCallback(async () => {
    try {
      setCanceling(true);
      const tx = await minerPoolContract?.sellMiner(id ?? 0, 0);
      await tx?.wait();
      setCanceling(false);
      fetchMinerData();
    } catch (e) {
      setCanceling(false);
      console.error(e);
    } finally {
      setCanceling(false);
    }
  }, [fetchMinerData, id, minerPoolContract]);

  const handleUnStake = useCallback(async () => {
    try {
      setUnstaking(true);
      const tx = await minerPoolContract?.unStakeMiner(id ?? 0);
      await tx?.wait();
      setUnstaking(false);
      /* history.push('/mine') */
    } catch (e) {
      setUnstaking(false);
      console.error(e);
    } finally {
      setUnstaking(false);
    }
  }, [history, id, minerPoolContract]);

  const dateFished = new Date(expiration * 1000);
  const isOwner = owner === account;
  const rewardsUSD = getBalanceNumber(pendingRewards, 8) * btcPrice;

  if (loading) {
    return (
      <>
        <PageWrapper>
          <AutoColumn gap="md">
            <Link
              style={{
                textDecoration: "none",
                width: "fit-content",
                marginBottom: "0.5rem",
              }}
              to="/mine"
            >
              <HoverText>
                ← <Trans>Back to mine</Trans>
              </HoverText>
            </Link>
            <Trans>Loading</Trans>...
          </AutoColumn>
        </PageWrapper>
        <SwitchLocaleLink />
      </>
    );
  }
  if (owner === ADDRESS_ZERO) {
    return (
      <>
        <PageWrapper>
          <AutoColumn gap="md">
            <Link
              style={{
                textDecoration: "none",
                width: "fit-content",
                marginBottom: "0.5rem",
              }}
              to="/mine"
            >
              <HoverText>
                ← <Trans>Back to mine</Trans>
              </HoverText>
            </Link>
            <Trans>Invalid Miner</Trans>
          </AutoColumn>
        </PageWrapper>
        <SwitchLocaleLink />
      </>
    );
  }
  return (
    <>
      <PageWrapper>
        <AutoColumn gap="md">
          <AutoColumn gap="sm">
            <Link
              style={{
                textDecoration: "none",
                width: "fit-content",
                marginBottom: "0.5rem",
              }}
              to="/mine"
            >
              <HoverText>
                ← <Trans>Back to mine</Trans>
              </HoverText>
            </Link>
            <ResponsiveRow>
              <RowFixed>
                <ThemedText.LargeHeader mr={2}>
                  <Trans>Miner</Trans>
                </ThemedText.LargeHeader>
                <Badge style={{ marginRight: "8px" }}>
                  <BadgeText>{id}</BadgeText>
                </Badge>
              </RowFixed>
              <RowFixed>
                <Clock />
                <ThemedText.LargeHeader ml="0.5rem">
                  <Countdown date={dateFished}>
                    <Trans>Expired</Trans>
                  </Countdown>
                </ThemedText.LargeHeader>
              </RowFixed>
            </ResponsiveRow>
          </AutoColumn>
          <ResponsiveRow align="flex-start">
            <StyledRibbonContainer>
              <DarkCard
                width="100%"
                height="100%"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginRight: "12px",
                }}
              >
                <div style={{ marginRight: 12, marginBottom: "0.5rem" }}>
                  <img
                    src="/images/nfts/miner-nft.png"
                    width={290}
                    alt="miner-nft"
                    style={{ borderRadius: "10px" }}
                  />
                </div>
                {isOwner ? (
                  onSale ? (
                    <>
                      <div style={{ marginTop: 12, marginBottom: 12 }}>
                        <Trans>Price</Trans>:{" "}
                        {getFullDisplayBalance(salePrice, 6, 2)} USDC
                      </div>
                      <ButtonError
                        error
                        onClick={handleCancelSale}
                        disabled={canceling}
                      >
                        {canceling ? (
                          <>
                            <Trans>Canceling</Trans>
                            <CustomLightSpinner
                              src="/images/blue-loader.svg"
                              alt="loader"
                              size="18px"
                              style={{
                                marginLeft: "0.5em",
                                textDecoration: "bold",
                              }}
                            />
                          </>
                        ) : (
                          <Trans>Cancel sale</Trans>
                        )}
                      </ButtonError>
                    </>
                  ) : (
                    <ButtonPrimary
                      mt="0.5rem"
                      onClick={() => handleOpenModal(1)}
                      disabled={dateFished < currentDate()}
                      mb="0.5rem"
                    >
                      <Trans>Sell Miner</Trans>
                    </ButtonPrimary>
                  )
                ) : (
                  ""
                )}
                {!isOwner && onSale && (
                  <>
                    <div style={{ marginTop: 12, marginBottom: 12 }}>
                      <Trans>Price</Trans>:{" "}
                      {getFullDisplayBalance(salePrice, 6, 2)} USDC
                    </div>
                    <ButtonPrimary
                      onClick={() => handleOpenModal(2)}
                      disabled={dateFished < currentDate()}
                    >
                      <Trans>Buy Miner</Trans>
                    </ButtonPrimary>
                  </>
                )}
              </DarkCard>
              {/* {onSale && (
                <LeftLargeRibbon backgroundColor={darkmode ? '#2172E5' : '#25B728'} color="#FFFFFF" fontFamily="Poppins">
                  <Trans>On Sale</Trans>
                </LeftLargeRibbon>
              )} */}
            </StyledRibbonContainer>
            <AutoColumn gap="sm" style={{ width: "100%", height: "100%" }}>
              <DarkCard>
                <AutoColumn gap="md" style={{ width: "100%" }}>
                  <RowBetween style={{ alignItems: "flex-start" }}>
                    <AutoColumn gap="md">
                      <Label>
                        <Trans>Power</Trans>
                      </Label>
                    </AutoColumn>
                    <AutoColumn gap="md">
                      <Label>
                        {getFullDisplayBalance(stakedPower, 6, 2)} MHs
                      </Label>
                    </AutoColumn>
                  </RowBetween>
                  <LightCard padding="12px 16px">
                    <AutoColumn gap="md">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ThemedText.BodyPrimary>
                          <Trans>Rewards</Trans>
                        </ThemedText.BodyPrimary>
                      </div>
                    </AutoColumn>
                    <AutoColumn gap="md">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <ThemedText.BodyPrimary>
                          {getFullDisplayBalance(pendingRewards, 8, 6)} WBTC
                        </ThemedText.BodyPrimary>
                        <img
                          src="/images/tokens/polygon-ico.png"
                          alt=""
                          width="20px"
                          style={{ marginLeft: "5px" }}
                        />
                      </div>
                    </AutoColumn>
                    <AutoColumn gap="md">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <ThemedText.DeprecatedItalic>
                          {rewardsUSD.toFixed(2)} USD
                        </ThemedText.DeprecatedItalic>
                      </div>
                    </AutoColumn>
                  </LightCard>
                  {pendingRewards.gt(0) ? (
                    <ButtonPrimary
                      mt="1rem"
                      disabled={!isOwner || pendingRewards.eq(0) || claiming}
                      onClick={handleWithdrawn}
                    >
                      {claiming ? (
                        <>
                          <Trans>Withdrawing</Trans>
                          <CustomLightSpinner
                            src="/images/blue-loader.svg"
                            alt="loader"
                            size="24px"
                            style={{ marginLeft: "0.5rem" }}
                          />
                        </>
                      ) : (
                        <Trans>Withdraw</Trans>
                      )}
                    </ButtonPrimary>
                  ) : (
                    <ButtonError
                      error
                      mt="1rem"
                      disabled={!isOwner || unstaking}
                      onClick={handleUnStake}
                    >
                      {unstaking ? (
                        <>
                          <Trans>Removing</Trans>
                          <CustomLightSpinner
                            src="/images/blue-loader.svg"
                            alt="loader"
                            size="24px"
                            style={{ marginLeft: "0.5rem" }}
                          />
                        </>
                      ) : (
                        <Trans>Remove stake</Trans>
                      )}
                    </ButtonError>
                  )}
                </AutoColumn>
              </DarkCard>
              <DarkCard>
                <AutoColumn gap="md" style={{ width: "100%" }}>
                  <AutoColumn gap="md">
                    <RowBetween style={{ alignItems: "flex-start" }}>
                      <AutoColumn gap="md">
                        <Label>
                          <Trans>Cave</Trans>
                        </Label>
                      </AutoColumn>
                      <AutoColumn gap="md">
                        <Label>{caveId}</Label>
                      </AutoColumn>
                    </RowBetween>
                  </AutoColumn>
                  {caveInfo && (
                    <LightCard padding="12px 16px" mb="1rem">
                      <AutoColumn gap="md">
                        <RowBetween>
                          <RowFixed>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "4.5rem",
                                margin: "auto",
                              }}
                            >
                              <ThemedText.DeprecatedMain>
                                <Trans>Owner</Trans>
                              </ThemedText.DeprecatedMain>
                            </div>
                          </RowFixed>
                          <RowFixed>
                            <ThemedText.DeprecatedMain>
                              {shortenAddress(caveInfo.owner)}
                            </ThemedText.DeprecatedMain>
                          </RowFixed>
                        </RowBetween>
                        <RowBetween>
                          <RowFixed>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "4.5rem",
                                margin: "auto",
                              }}
                            >
                              <ThemedText.DeprecatedMain>
                                <Trans>Power</Trans>
                              </ThemedText.DeprecatedMain>
                            </div>
                          </RowFixed>
                          <RowFixed>
                            <ThemedText.DeprecatedMain>
                              {getFullDisplayBalance(
                                new BigNumber(caveInfo.stakedPower),
                                6,
                                2
                              )}{" "}
                              MHs
                            </ThemedText.DeprecatedMain>
                          </RowFixed>
                        </RowBetween>
                        <RowBetween>
                          <RowFixed>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "4.5rem",
                                margin: "auto",
                              }}
                            >
                              <ThemedText.DeprecatedMain>
                                <Trans>Expiration</Trans>
                              </ThemedText.DeprecatedMain>
                            </div>
                          </RowFixed>
                          <RowFixed>
                            <ThemedText.DeprecatedMain>
                              {new Date(
                                Number(caveInfo.expiration) * 1000
                              ).toLocaleString()}
                            </ThemedText.DeprecatedMain>
                          </RowFixed>
                        </RowBetween>
                      </AutoColumn>
                    </LightCard>
                  )}
                </AutoColumn>
                <ButtonEmpty
                  as={Link}
                  to={`/manage-cave/${caveId}`}
                  width="100%"
                >
                  <Trans>Show cave</Trans>
                </ButtonEmpty>
              </DarkCard>
            </AutoColumn>
          </ResponsiveRow>
        </AutoColumn>
        <SellMinerModal
          isOpen={openModal === 1}
          onDismiss={() => setOpenModal(0)}
          chainId={activeChain}
          minerId={Number(id) ?? 9999999}
          update={fetchMinerData}
        />
        <BuyMinerModal
          isOpen={openModal === 2}
          onDismiss={() => setOpenModal(0)}
          chainId={activeChain}
          price={salePrice}
          minerId={Number(id) ?? 9999999}
          update={fetchMinerData}
        />
      </PageWrapper>
      <SwitchLocaleLink />
    </>
  );
}
