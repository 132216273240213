/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { DarkCard, LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { RowFixed } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import request, { gql } from 'graphql-request'
import React, { useEffect, useState } from 'react'
import { PlusCircle } from 'react-feather'
import styled from 'styled-components/macro'
import { ExternalLink, ThemedText } from 'theme'
import { shortenAddress } from 'utils/address'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { isMobile } from 'utils/userAgent'

const ResponsiveGrid = styled.div`
  display: grid;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 1rem;
`

// const ButtonChecked = styled(ButtonRadioCheckedCenter)<{ isMobile?: boolean }>`
// width: 30%;
// color: ${({ theme }) => theme.secondary3};
// font-size: ${({ isMobile }) => (isMobile ? '12px' : '16px')};
// border-radius: 12px;
// border-color: ${({ theme }) => theme.secondary1};
// margin-top: 2rem;
// margin-bottom: 0.5rem;
// `

const Label = styled(({ ...props }) => <ThemedText.DeprecatedLabel {...props} />)`
  display: flex;
  font-size: 20px;
  justify-content: flex-start;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
`

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.1rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

function shortenTx(address: string, charsStart = 4, charsEnd?: number): string {
  return `${address.substring(0, charsStart + 2)}...${address.substring(64 - (charsEnd || charsStart))}`
}

interface SaleRow {
  id: string
  dateTime: number
  buyer: string
  amount: number
  price: string
  ids: string[]
}

interface Props {
  variant?: CurrencyAmount<Currency>
}

const MintHistory: React.FC<Props> = ({ variant }) => {
  const { account, chainId } = useWeb3React()

  const apiUrl = 'https://api.thegraph.com/subgraphs/name/jamonswap/greenblock-nft'

  const [history, setHistory] = useState<SaleRow[]>([])

  useEffect(() => {
    request(
      apiUrl,
      gql`
        query {
          sales(orderBy: dateTime, orderDirection: desc, first: 10) {
            id
            dateTime
            buyer
            amount
            price
            ids
          }
        }
      `
    )
      .then(({ sales }) => {
        if (sales.length > 0) {
          setHistory(sales)
        }
      })
      .catch(({ error }) => {
        console.log(error)
      })
  }, [apiUrl, variant])

  return history && history.length > 0 ? (
    <div>
      {account && (
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          {/* <ButtonChecked active={showOwed} onClick={activeOwed} isMobile={isMobile}>
            <Trans>My Purchases</Trans>
          </ButtonChecked> */}
        </div>
      )}
      <DarkCard>
        <AutoColumn gap="md">
          <RowFixed>
            <Label display="flex" style={{ marginRight: '12px' }}>
              <Trans>Last</Trans> NFTs <Trans>Minted</Trans>
            </Label>
          </RowFixed>

          <LightCard padding="12px" width="100%">
            {history && history.length > 0 ? (
              <>
                <ResponsiveGrid>
                  <ThemedText.BodySecondary textAlign="center">TX</ThemedText.BodySecondary>
                  <ThemedText.BodySecondary textAlign="center">
                    <Trans>Wallet</Trans>
                  </ThemedText.BodySecondary>
                  <ThemedText.BodySecondary textAlign="center">NFT Id</ThemedText.BodySecondary>
                </ResponsiveGrid>
                {history.map((item, index) => {
                  return (
                    <ResponsiveGrid key={index}>
                      <ThemedText.BodyPrimary
                        as={ExternalLink}
                        href={getExplorerLink(chainId ?? 56, item.id, ExplorerDataType.TRANSACTION)}
                        fontSize={isMobile ? 16 : 20}
                        textAlign="center"
                      >
                        {shortenTx(item.id, isMobile ? 1 : 5)}
                      </ThemedText.BodyPrimary>
                      <ThemedText.BodyPrimary fontSize={isMobile ? 16 : 20} textAlign="center">
                        {shortenAddress(item.buyer, isMobile ? 2 : 5)}
                      </ThemedText.BodyPrimary>
                      <ThemedText.BodyPrimary fontSize={isMobile ? 16 : 20} textAlign="center">
                        {item.ids.length > 1 ? (
                          <MouseoverTooltip
                            placement="left"
                            text={
                              <ThemedText.BodyPrimary fontSize={isMobile ? 16 : 20} textAlign="center">
                                {item.ids.join(',')}
                              </ThemedText.BodyPrimary>
                            }
                          >
                            <Row>
                              {item.ids[0]}
                              <WrapperIcon>
                                <PlusCircle size={18} />
                              </WrapperIcon>
                            </Row>
                          </MouseoverTooltip>
                        ) : (
                          item.ids
                        )}
                      </ThemedText.BodyPrimary>
                    </ResponsiveGrid>
                  )
                })}
              </>
            ) : (
              <ThemedText.BodyPrimary textAlign="center">
                <Trans>No transactions</Trans>
              </ThemedText.BodyPrimary>
            )}
          </LightCard>
        </AutoColumn>
      </DarkCard>
    </div>
  ) : null
}

export default MintHistory
