/* eslint-disable import/no-unused-modules */
import { JsonRpcProvider } from '@ethersproject/providers'

import { SupportedChainId } from './chains'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

export const MAINNET_PROVIDER = new JsonRpcProvider(`https://bsc-dataseed.binance.org`)

/**
 * These are the network URLs used by the interface when there is not another available source of chain data
 */
export const INFURA_NETWORK_URLS: { [key in SupportedChainId]: string } = {
  [SupportedChainId.BSC]: `https://bsc-dataseed.binance.org`,
  [SupportedChainId.BSC_TESTNET]: `https://rpc.ankr.com/bsc_testnet_chapel`,
  [SupportedChainId.POLYGON]: `https://polygon-rpc.com/`,
  [SupportedChainId.POLYGON_MUMBAI]: `https://rpc.ankr.com/polygon_mumbai`,
}

export function getRpcUrls(chainId: SupportedChainId): [string] {
  switch (chainId) {
    case SupportedChainId.POLYGON:
      return ['https://polygon-rpc.com/']
    case SupportedChainId.POLYGON_MUMBAI:
      return [`https://rpc.ankr.com/polygon_mumbai`]
    case SupportedChainId.BSC:
      return [`https://bsc-dataseed.binance.org`]
    case SupportedChainId.BSC_TESTNET:
      return [`https://rpc.ankr.com/bsc_testnet_chapel`]
    default:
  }
  // Our API-keyed URLs will fail security checks when used with external wallets.
  throw new Error('RPC URLs must use public endpoints')
}
