// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { ButtonEmpty, ButtonPrimary } from 'components/Button'
import Modal from 'components/Modal'
import { useCavePoolContract, useVipNFTContract } from 'hooks/useContract'
import useNFTsBalance from 'pages/Mine/hooks/useNFTsBalance'
import useVipStaked from 'pages/Mine/hooks/useVipStaked'
import { useCallback, useState } from 'react'
import { ExternalLink, Info, X } from 'react-feather'
import styled from 'styled-components/macro'
import { CustomLightSpinner, ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

interface AddBonusProps {
  chainId: number
  caveId: number
  onDismiss: () => void
  update: () => void
  isOpen: boolean
}

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_blue4};
  border-radius: 12px;
  width: 100%;
  padding: 16px;
`

const Dropdown = styled.select<{ isMobile?: boolean; account?: boolean }>`
  width: 100%;
  height: 2.5em;
  text-align: center;
  font-size: ${({ isMobile }) => (isMobile ? '10px' : '16px')};
  color: white;
  background: ${({ theme }) => theme.deprecated_primary2};
  border: none;
  border-radius: 20px;
  margin: auto;
  option {
    &:disabled {
      color: #b5b2b2;
    }
  }
`

const ButtonBuy = styled(ButtonEmpty)`
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.deprecated_secondary2};
  }
`

export default function AddBonusNFT({ chainId, caveId, onDismiss, isOpen, update }: AddBonusProps) {
  const { account } = useWeb3React()
  const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))
  const [approving, setApproving] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const [value, setValue] = useState<number | undefined>(undefined)

  const vipContract = useVipNFTContract()
  const cavePoolContract = useCavePoolContract()

  const { vipBalance, vipAllowance, vips, fetchWalletBalanceData } = useNFTsBalance(chainId)

  const NFTs = useVipStaked(chainId, vips)

  const handleApprove = useCallback(async () => {
    try {
      setApproving(true)
      const tx = await vipContract?.setApprovalForAll(cavePoolContract?.address ?? '', true)
      await tx?.wait()
      fetchWalletBalanceData()
      setApproving(false)
    } catch (e) {
      setApproving(false)
      console.error(e)
    } finally {
      setApproving(false)
    }
  }, [cavePoolContract?.address, fetchWalletBalanceData, vipContract])

  const handleStake = useCallback(async () => {
    if (value !== undefined) {
      try {
        setConfirming(true)
        const tx = await cavePoolContract?.stakeVip(caveId, value.toString())
        await tx?.wait()
        fetchWalletBalanceData()
        setConfirming(false)
        update()
        onDismiss()
      } catch (e) {
        setConfirming(false)
        console.error(e)
      } finally {
        setConfirming(false)
      }
    }
  }, [caveId, cavePoolContract, fetchWalletBalanceData, onDismiss, update, value])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <StyledClosed>
          <X size={24} onClick={onDismiss} />
        </StyledClosed>
        <ThemedText.LargeHeader textAlign="center" marginY="1rem" style={{ textTransform: 'uppercase' }}>
          <Trans>Bonus NFT Vip</Trans>
        </ThemedText.LargeHeader>
        <ModalBody>
          <Info size={28} color={darkmode ? '#5199FF' : '#2172E5'} />
          <ThemedText.DeprecatedBody textAlign="center" color="blue2" ml="4px">
            <Trans>Deposit the NFT Vip to get the bonus</Trans>
          </ThemedText.DeprecatedBody>
        </ModalBody>
        {vipBalance > 0 ? (
          <>
            <ThemedText.MediumHeader textAlign="center" marginY="1rem" fontSize="16px">
              <Trans>You have</Trans> {vipBalance} NFTs
            </ThemedText.MediumHeader>
            <Dropdown
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                setValue(event.target.value ? Number(event.target.value) : undefined)
              }
              isMobile={isMobile}
              account={Boolean(account)}
            >
              <option value="" selected>{t`Select NFT`}</option>
              {NFTs.map((attr, index) => {
                const status = attr.staked ? t`Staked` : t`Available`
                return (
                  <option key={index} value={attr.id} disabled={attr.staked}>
                    ID: {attr.id} - {t`Status`}: {status}
                  </option>
                )
              })}
            </Dropdown>
            {vipAllowance ? (
              <ButtonPrimary mt="1rem" disabled={value === undefined || confirming} onClick={handleStake}>
                {confirming ? (
                  <>
                    <Trans>Confirming</Trans>
                    <CustomLightSpinner
                      src="/images/blue-loader.svg"
                      alt="loader"
                      size={'24px'}
                      style={{ marginLeft: '0.5rem' }}
                    />
                  </>
                ) : (
                  <Trans>Confirm</Trans>
                )}
              </ButtonPrimary>
            ) : (
              <ButtonPrimary mt="1rem" disabled={approving} onClick={handleApprove}>
                {approving ? (
                  <>
                    <Trans>Enabling</Trans>
                    <CustomLightSpinner
                      src="/images/blue-loader.svg"
                      alt="loader"
                      size={'24px'}
                      style={{ marginLeft: '0.5rem' }}
                    />
                  </>
                ) : (
                  <Trans>Enable</Trans>
                )}
              </ButtonPrimary>
            )}
          </>
        ) : (
          <div>
            <ThemedText.MediumHeader textAlign="center" marginY="1rem" fontSize="16px">
              <Trans>You not have VIP NFTs</Trans>
            </ThemedText.MediumHeader>
            <ButtonBuy>
              <Trans>Buy NFTs</Trans>
              <ExternalLink width={20} style={{ marginLeft: '0.5rem' }} />
            </ButtonBuy>
          </div>
        )}
      </ModalWrapper>
    </Modal>
  )
}
