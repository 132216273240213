import { ADDRESS_ZERO } from '@uniswap/v3-sdk'
import CAVE_POOL_ABI from 'abis/GreenBlockCavePool.json'
import BigNumber from 'bignumber.js'
import { CAVE_POOL_ADDRESS } from 'constants/addresses'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import { BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'

import { CaveInfo } from '../types'

/**
 * Gets data of Cave on pool
 */
const useCaveInfo = (chainId: number, id: number): CaveInfo => {
  const cavePoolAddress = CAVE_POOL_ADDRESS[chainId]
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    owner: ADDRESS_ZERO,
    expiration: 0,
    stakedPower: BIG_ZERO,
    jamonStaked: BIG_ZERO,
    jamonUnlockDate: 0,
    hasVipStaked: false,
    vipId: 0,
    miners: 0,
    onSale: false,
    salePrice: BIG_ZERO,
    pendingRewards: BIG_ZERO,
    loading: true,
  })

  const fetchCaveData = useCallback(async () => {
    const caveCall = [
      {
        address: cavePoolAddress,
        name: 'getCaveInfo',
        params: [id],
      },
      {
        address: cavePoolAddress,
        name: 'getPendingRewards',
        params: [id],
      },
    ]

    const [_caveInfo, _pendingRewards] = await multicall(chainId, CAVE_POOL_ABI, caveCall)

    setState((prev) => ({
      ...prev,
      owner: _caveInfo[0],
      expiration: Number(_caveInfo[1]),
      stakedPower: new BigNumber(_caveInfo[2].toString()),
      jamonStaked: new BigNumber(_caveInfo[3].toString()),
      jamonUnlockDate: Number(_caveInfo[4]),
      hasVipStaked: Boolean(_caveInfo[5]),
      vipId: Number(_caveInfo[6]),
      miners: Number(_caveInfo[7]),
      onSale: Boolean(_caveInfo[8]),
      salePrice: new BigNumber(_caveInfo[9].toString()),
      pendingRewards: new BigNumber(_pendingRewards[0].toString()),
      loading: false,
    }))
  }, [cavePoolAddress, chainId, id])

  useEffect(() => {
    fetchCaveData()
  }, [fetchCaveData, id, slowRefresh])

  return { ...state, fetchCaveData }
}

export default useCaveInfo
