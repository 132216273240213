/* eslint-disable react/prop-types */
import { faCircleCheck, faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import MinerNFT from 'assets/svg/nfts/miner-nft.svg'
import { ButtonPrimary } from 'components/Button'
import { useState } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import { currentTimestamp } from '..'
import useGetPublicLockData from '../hooks/useGetPublicLockData'
import useWalletLockerData from '../hooks/useWalletLockerData'
import { WalletBalancesData } from '../types'
import Lock from './Actions/Lock'
import { useAccountDrawer } from 'components/AccountDrawer'

interface StakeProps {
  chainId: number
  walletData: WalletBalancesData
  account?: string | null
}

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const Body = styled.div`
  width: 100%;
`

const UserData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
  border-top: 1px solid ${({ theme }) => theme.deprecated_primary2};
`

const PriceContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
`

const Message = styled(ThemedText.MediumHeader)`
  color: ${({ theme }) => theme.deprecated_secondary3};
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
`

const renderer = (days: number, hours: number, minutes: number, seconds: number) => (
  <span>
    <Trans>Starts in</Trans> {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
  </span>
)

const endRenderer = (days: number, hours: number, minutes: number, seconds: number) => (
  <span>
    <Trans>Ends in</Trans> {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
  </span>
)

export default function Stake({ chainId, walletData, account }: StakeProps) {
  const [, toggleAccountDrawer] = useAccountDrawer()
  const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))

  const initTimeStamp = currentTimestamp()

  const [openModal, setOpenModal] = useState(false)
  const [currentTime, setCurrentTime] = useState<number>(initTimeStamp)

  const handleDissmis = () => {
    setOpenModal(false)
  }

  const lockerData = useGetPublicLockData(chainId)
  const lockerWalletData = useWalletLockerData(chainId)

  const { isOpen, available, lockStart, lockEnd, locksLimit, fetchLockData } = lockerData
  const { locks } = lockerWalletData

  const totalAvailable = available

  const isStarted = lockStart * 1000 < currentTime
  const isFinished = lockEnd * 1000 < currentTime

  const limitReached = locks >= locksLimit

  console.log(locks)

  const opened = isOpen && available > 0

  const updateTime = () => {
    const timeStamp = currentTimestamp()
    fetchLockData()
    setCurrentTime(timeStamp)
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ThemedText.MediumHeader fontWeight="bold" fontSize={isMobile ? '1.5rem' : '2rem'} mb="1rem">
          <Trans>Get Miner NFT</Trans>
        </ThemedText.MediumHeader>
      </div>
      <Heading>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img width={isMobile ? 150 : 200} src={MinerNFT} alt="sale-img" style={{ borderRadius: '10px' }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ThemedText.MediumHeader fontSize="1rem" color={darkmode ? '#5199FF' : '#57db48'}>
              <Trans>Available</Trans>
            </ThemedText.MediumHeader>
            <ThemedText.LargeHeader fontSize="2rem" color="#F5F5DC">
              {totalAvailable}
            </ThemedText.LargeHeader>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '0.5rem' }}>
            <ThemedText.MediumHeader fontSize="1rem" color={darkmode ? '#5199FF' : '#57db48'}>
              <Trans>Power</Trans>
            </ThemedText.MediumHeader>
            <ThemedText.MediumHeader fontStyle="italic" color="#F5F5DC">
              87 Mhs
            </ThemedText.MediumHeader>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '0.5rem' }}>
            <ThemedText.MediumHeader fontSize="1rem" color={darkmode ? '#5199FF' : '#57db48'}>
              <Trans>Duration</Trans>
            </ThemedText.MediumHeader>
            <ThemedText.MediumHeader fontStyle="italic" color="#F5F5DC">
              1 <Trans>year</Trans>
            </ThemedText.MediumHeader>
          </div>
        </div>
      </Heading>
      <PriceContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ThemedText.MediumHeader fontSize="1rem" color={darkmode ? '#5199FF' : '#57db48'}>
            <Trans>Amount of</Trans> JAMON <Trans>to lock</Trans>
          </ThemedText.MediumHeader>
          <ThemedText.MediumHeader color="#F5F5DC">400.000</ThemedText.MediumHeader>
        </div>
      </PriceContent>
      <UserData>
        <Message></Message>
      </UserData>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1rem' }}>
        {!opened && !isStarted && (
          <Countdown
            date={new Date(lockStart * 1000)}
            renderer={(props: any) => renderer(props.days, props.hours, props.minutes, props.seconds)}
            onComplete={updateTime}
          />
        )}
        {opened && isStarted && !isFinished && (
          <Countdown
            date={new Date(lockEnd * 1000)}
            renderer={(props: any) => endRenderer(props.days, props.hours, props.minutes, props.seconds)}
            onComplete={updateTime}
          />
        )}
      </div>
      {opened ? (
        <Body>
          <div>
            {account ? (
              <>
                <ButtonPrimary
                  width="80%"
                  margin="auto"
                  onClick={() => setOpenModal(true)}
                  disabled={!opened || limitReached}
                >
                  {limitReached ? <Trans>Limit reached</Trans> : <Trans>Lock</Trans>}
                </ButtonPrimary>
              </>
            ) : (
              <ButtonPrimary onClick={toggleAccountDrawer} width="80%" margin="auto" marginTop="2rem">
                <Trans>Connect Wallet</Trans>
              </ButtonPrimary>
            )}
          </div>
        </Body>
      ) : (
        <div>
          {isStarted ? (
            <Message>
              <FontAwesomeIcon icon={faCircleCheck} style={{ marginRight: '0.5rem' }} />
              <Trans>Lock closed</Trans>
            </Message>
          ) : (
            <Message>
              <FontAwesomeIcon icon={faClock} style={{ marginRight: '0.5rem' }} />
              <Trans>Awaiting start</Trans>
            </Message>
          )}
        </div>
      )}
      <Lock
        isOpen={openModal}
        onDismiss={handleDissmis}
        walletData={walletData}
        lockerData={lockerData}
        chainId={chainId}
      />
    </div>
  )
}
