/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { ButtonPrimary } from 'components/Button'
import Modal from 'components/Modal'
import { USDC } from 'constants/tokens'
import { ethers } from 'ethers'
import { useCavePoolContract, useTokenContract } from 'hooks/useContract'
import useWalletBalances from 'pages/Sale/hooks/useWalletBalances'
import { useCallback, useState } from 'react'
import { Info, X } from 'react-feather'
import styled from 'styled-components/macro'
import { CustomLightSpinner, ThemedText } from 'theme'
import { getFullDisplayBalance } from 'utils/formatBalance'

interface Props {
  chainId: number
  caveId: number
  price: BigNumber
  onDismiss: () => void
  update: () => void
  isOpen: boolean
}

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_blue4};
  border-radius: 12px;
  width: 100%;
  padding: 16px;
`

export default function BuyCaveModal({ chainId, caveId, price, onDismiss, isOpen, update }: Props) {
  
  const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))
  const [approving, setApproving] = useState(false)
  const [confirming, setConfirming] = useState(false)

  const balanceData = useWalletBalances(chainId)
  const usdtContract = useTokenContract(USDC[chainId].address)
  const cavePoolContract = useCavePoolContract()

  const { usdtBalance, usdtCaveAllowance } = balanceData

  const haveUsdtBalance = usdtBalance.gte(price)
  const isUsdtAllowed = usdtCaveAllowance.gt(0)

  const handleApprove = useCallback(async () => {
    try {
      setApproving(true)
      const tx = await usdtContract?.approve(cavePoolContract?.address ?? '', ethers.constants.MaxUint256)
      await tx?.wait()
      balanceData.fetchWalletData()
      setApproving(false)
    } catch (e) {
      setApproving(false)
      console.error(e)
    } finally {
      setApproving(false)
    }
  }, [balanceData, cavePoolContract?.address, usdtContract])

  const handleBuy = useCallback(async () => {
    try {
      setConfirming(true)
      const tx = await cavePoolContract?.buyCave(caveId)
      await tx?.wait()
      setConfirming(false)
      balanceData.fetchWalletData()
      update()
      onDismiss()
    } catch (e) {
      setConfirming(false)
      console.error(e)
    } finally {
      setConfirming(false)
    }
  }, [balanceData, caveId, cavePoolContract, onDismiss, update])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <StyledClosed>
          <X size={24} onClick={onDismiss} />
        </StyledClosed>
        <ThemedText.LargeHeader textAlign="center" marginY="1rem" style={{ textTransform: 'uppercase' }}>
          <Trans>Buy Cave</Trans>
        </ThemedText.LargeHeader>
        <ModalBody>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '20%' }}>
            <Info size={28} color={darkmode ? '#5199FF' : '#2172E5'} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%' }}>
            <ThemedText.DeprecatedBody textAlign="left" color="blue2" ml="4px">
              <Trans>If the cave have staked JAMON, the ownership of the tokens will also be transferred</Trans>.
            </ThemedText.DeprecatedBody>
            <ThemedText.DeprecatedBody textAlign="left" color="blue2" ml="4px" mt="4px">
              <Trans>The NFT Vip once the sale is made, will be free again</Trans>.
            </ThemedText.DeprecatedBody>
          </div>
        </ModalBody>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem' }}>
          <ThemedText.MediumHeader fontSize="1rem" color={darkmode ? '#5199FF' : '#25B728'}>
            <Trans>Price</Trans>
          </ThemedText.MediumHeader>
          <ThemedText.MediumHeader fontStyle="italic" color="#F5F5DC">
            {getFullDisplayBalance(price, 6, 2)} USDC
          </ThemedText.MediumHeader>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '1rem',
            marginBottom: '1rem',
            fontSize: '14px',
            marginRight: '0.6rem',
          }}
        >
          <Trans>Balance</Trans>: {getFullDisplayBalance(usdtBalance, 6, 2)} USDC
        </div>
        {isUsdtAllowed ? (
          <ButtonPrimary mt="1rem" disabled={!haveUsdtBalance || confirming} onClick={handleBuy}>
            {!haveUsdtBalance ? (
              <Trans>Insufficient Balance</Trans>
            ) : confirming ? (
              <>
                <Trans>Confirming</Trans>
                <CustomLightSpinner
                  src="/images/blue-loader.svg"
                  alt="loader"
                  size={'24px'}
                  style={{ marginLeft: '0.5rem' }}
                />
              </>
            ) : (
              <Trans>Confirm</Trans>
            )}
          </ButtonPrimary>
        ) : (
          <ButtonPrimary mt="1rem" disabled={approving} onClick={handleApprove}>
            {approving ? (
              <>
                <Trans>Enabling</Trans>
                <CustomLightSpinner
                  src="/images/blue-loader.svg"
                  alt="loader"
                  size={'24px'}
                  style={{ marginLeft: '0.5rem' }}
                />
              </>
            ) : (
              <Trans>Enable</Trans>
            )}
          </ButtonPrimary>
        )}
      </ModalWrapper>
    </Modal>
  )
}
