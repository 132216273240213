import ERC20_ABI from 'abis/erc20.json'
import BigNumber from 'bignumber.js'
import { CAVE_POOL_ADDRESS, LOCKER_ADDRESS, MINER_POOL_ADDRESS, SALES_ADDRESS } from 'constants/addresses'
import { JAMON, USDC } from 'constants/tokens'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import { BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'

import { WalletBalancesData } from '../types'
import { useWeb3React } from '@web3-react/core'

/**
 * Gets all public data of Sales contract
 */
const useWalletBalances = (chainId: number): WalletBalancesData => {
  const { account } = useWeb3React()
  const salesAddress = SALES_ADDRESS[chainId]
  const cavePoolAddress = CAVE_POOL_ADDRESS[chainId]
  const minerPoolAddress = MINER_POOL_ADDRESS[chainId]
  const lockerAddress = LOCKER_ADDRESS[chainId]
  const usdtAddress = USDC[chainId].address
  const jamonAddress = JAMON[chainId].address
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    jamonBalance: BIG_ZERO,
    usdtBalance: BIG_ZERO,
    jamonAllowance: BIG_ZERO,
    usdtAllowance: BIG_ZERO,
    jamonCaveAllowance: BIG_ZERO,
    usdtCaveAllowance: BIG_ZERO,
    usdtMinerAllowance: BIG_ZERO,
    jamonLockAllowance: BIG_ZERO,
  })

  const fetchWalletData = useCallback(async () => {
    const userCalls = [
      {
        address: usdtAddress,
        name: 'balanceOf',
        params: [account],
      },
      {
        address: jamonAddress,
        name: 'balanceOf',
        params: [account],
      },
      {
        address: usdtAddress,
        name: 'allowance',
        params: [account, salesAddress],
      },
      {
        address: jamonAddress,
        name: 'allowance',
        params: [account, salesAddress],
      },
      {
        address: jamonAddress,
        name: 'allowance',
        params: [account, cavePoolAddress],
      },
      {
        address: usdtAddress,
        name: 'allowance',
        params: [account, cavePoolAddress],
      },
      {
        address: usdtAddress,
        name: 'allowance',
        params: [account, minerPoolAddress],
      },
      {
        address: jamonAddress,
        name: 'allowance',
        params: [account, lockerAddress],
      },
    ]

    const [
      usdtBalance,
      jamonBalance,
      usdtAllowance,
      jamonAllowance,
      jamonCaveAllowance,
      usdtCaveAllowance,
      usdtMinerAllowance,
      jamonLockAllowance,
    ] = await multicall(chainId, ERC20_ABI, userCalls)

    setState((prev) => ({
      ...prev,
      jamonBalance: new BigNumber(jamonBalance[0].toString()),
      usdtBalance: new BigNumber(usdtBalance[0].toString()),
      jamonAllowance: new BigNumber(jamonAllowance[0].toString()),
      usdtAllowance: new BigNumber(usdtAllowance[0].toString()),
      jamonCaveAllowance: new BigNumber(jamonCaveAllowance[0].toString()),
      usdtCaveAllowance: new BigNumber(usdtCaveAllowance[0].toString()),
      usdtMinerAllowance: new BigNumber(usdtMinerAllowance[0].toString()),
      jamonLockAllowance: new BigNumber(jamonLockAllowance[0].toString()),
    }))
  }, [account, cavePoolAddress, chainId, jamonAddress, lockerAddress, minerPoolAddress, salesAddress, usdtAddress])

  useEffect(() => {
    if (account) {
      fetchWalletData()
    }
  }, [account, fetchWalletData, slowRefresh])

  return { ...state, fetchWalletData }
}

export default useWalletBalances
