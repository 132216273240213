import { useWeb3React } from '@web3-react/core'
import erc20Abi from 'abis/presaleNFT.json'
import BigNumber from 'bignumber.js'
import { GBT_ADDRESS, GBT_PRESALE_ADDRESS, USDT_ADDRESS } from 'constants/addresses'
import { isSupportedChain } from 'constants/chains'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import { UserDetails } from 'types/user'
import { BIG_ZERO } from 'utils/bigNumber'
import Multicall from 'utils/multicall'

const useUserNFTData = (): UserDetails => {
  const { slowRefresh } = useRefresh()
  const { account, chainId = 56 } = useWeb3React()
  const [state, setState] = useState({
    usdtBalance: BIG_ZERO,
    gbtBalance: BIG_ZERO,
    usdtAllowance: BIG_ZERO,
    gbtAllowance: BIG_ZERO,
    ownerIds: [],
  })

  const chainAllowed = isSupportedChain(chainId) ? chainId : 56

  const usdtAddress = USDT_ADDRESS[chainAllowed]
  const gbtAddress = GBT_ADDRESS[chainAllowed]
  const presaleAddress = GBT_PRESALE_ADDRESS[chainAllowed]

  const fetchData = useCallback(async () => {
    const UserCalls = [
      { address: usdtAddress, name: 'balanceOf', params: [account] },
      { address: gbtAddress, name: 'balanceOf', params: [account] },
      { address: usdtAddress, name: 'allowance', params: [account, presaleAddress] },
      { address: gbtAddress, name: 'allowance', params: [account, presaleAddress] },
      { address: presaleAddress, name: 'getIdsOfBuyer', params: [account] },
    ]

    const [getUsdtBalance, getGbtBalance, getUsdtAllowance, getGbtAllowance, getIdsOfBuyer] = await Multicall(
      chainAllowed,
      erc20Abi,
      UserCalls
    )
    console.log(getIdsOfBuyer)

    setState((prev) => ({
      ...prev,
      usdtBalance: new BigNumber(getUsdtBalance[0].toString() ?? '0'),
      gbtBalance: new BigNumber(getGbtBalance[0].toString() ?? '0'),
      usdtAllowance: new BigNumber(getUsdtAllowance[0].toString() ?? '0'),
      gbtAllowance: new BigNumber(getGbtAllowance[0].toString() ?? '0'),
      ownerIds: getIdsOfBuyer[0],
    }))
  }, [account, chainAllowed, gbtAddress, presaleAddress, usdtAddress])

  useEffect(() => {
    if (account) {
      fetchData()
    }
  }, [account, fetchData, slowRefresh])

  return { ...state, fetchData }
}

export default useUserNFTData
