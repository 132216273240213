import ERC721_ABI from 'abis/GreenBlockCave.json'
import BigNumber from 'bignumber.js'
import { GBT_NFT_ADDRESS, NFT_POOL_ADDRESS } from 'constants/addresses'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import { BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'

import { WalletNFTsBalancesData } from '../types'
import { useWeb3React } from '@web3-react/core'

/**
 * Gets all wallet data of token contract
 */
const useWalletBalances = (chainId: number): WalletNFTsBalancesData => {
  const { account } = useWeb3React()
  const poolAddress = NFT_POOL_ADDRESS[chainId]
  const gbtAddress = GBT_NFT_ADDRESS[chainId]
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    nftBalance: 0,
    nftAllowance: false,
    ids: new Array<number>(),
  })

  const fetchWalletBalanceData = useCallback(async () => {
    const userCalls = [
      {
        address: gbtAddress,
        name: 'balanceOf',
        params: [account],
      },
      {
        address: gbtAddress,
        name: 'isApprovedForAll',
        params: [account, poolAddress],
      },
    ]

    const [gbtBalance, gbtPoolAllowance] = await multicall(chainId, ERC721_ABI, userCalls)

    const nftBalance = Number(gbtBalance[0])

    const idsArray: Array<number> = []
    if (nftBalance > 0) {
      const idsMap = Array.from(Array(nftBalance).keys())
      const idCalls = idsMap.map((index) => {
        return {
          address: gbtAddress,
          name: 'tokenOfOwnerByIndex',
          params: [account, index],
        }
      })

      const tokenOf = await multicall(chainId, ERC721_ABI, idCalls)
      tokenOf.map((i: BigNumber) => idsArray.push(Number(i.toString())))
    }

    setState((prev) => ({
      ...prev,
      nftBalance: nftBalance,
      nftAllowance: Boolean(gbtPoolAllowance[0]),
      ids: idsArray
    }))
  }, [account, chainId, poolAddress, gbtAddress])

  useEffect(() => {
    if (account) {
      fetchWalletBalanceData()
    }
  }, [account, fetchWalletBalanceData, slowRefresh])

  return { ...state, fetchWalletBalanceData }
}

export default useWalletBalances
