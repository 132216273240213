import ERC20_ABI from 'abis/erc20.json'
import BigNumber from 'bignumber.js'
import { POOL_ADDRESS } from 'constants/addresses'
import { GBT } from 'constants/tokens'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import { BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'

import { WalletBalancesData } from '../types'
import { useWeb3React } from '@web3-react/core'

/**
 * Gets all wallet data of token contract
 */
const useWalletBalances = (chainId: number): WalletBalancesData => {
  const { account } = useWeb3React()
  const poolAddress = POOL_ADDRESS[chainId]
  const gbtAddress = GBT[chainId].address
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    gbtBalance: BIG_ZERO,
    gbtPoolAllowance: BIG_ZERO,
  })

  const fetchWalletData = useCallback(async () => {
    const userCalls = [
      {
        address: gbtAddress,
        name: 'balanceOf',
        params: [account],
      },
      {
        address: gbtAddress,
        name: 'allowance',
        params: [account, poolAddress],
      },
    ]

    const [gbtBalance, gbtPoolAllowance] = await multicall(chainId, ERC20_ABI, userCalls)

    setState((prev) => ({
      ...prev,
      gbtBalance: new BigNumber(gbtBalance[0].toString()),
      gbtPoolAllowance: new BigNumber(gbtPoolAllowance[0].toString()),
    }))
  }, [account, chainId, poolAddress, gbtAddress])

  useEffect(() => {
    if (account) {
      fetchWalletData()
    }
  }, [account, fetchWalletData, slowRefresh])

  return { ...state, fetchWalletData }
}

export default useWalletBalances
