import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";

const Card = styled(Box)<{
  width?: string;
  padding?: string;
  border?: string;
  $borderRadius?: string;
}>`
  width: ${({ width }) => width ?? "100%"};
  padding: ${({ padding }) => padding ?? "1rem"};
  border-radius: ${({ $borderRadius }) => $borderRadius ?? "16px"};
  border: ${({ border }) => border};
`;
export default Card;

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.backgroundInteractive};
  background-color: ${({ theme }) => theme.deprecated_bg1};
`;

export const GrayCard = styled(Card)`
  background-color: ${({ theme }) => theme.deprecated_bg3};
`;

export const DarkGrayCard = styled(Card)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
`;

export const DarkCard = styled(Card)`
  background-color: ${({ theme }) => theme.backgroundSurface};
`;

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.deprecated_bg3};
`;

export const YellowCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: ${({ theme }) => theme.deprecated_yellow3};
  font-weight: 500;
`;

export const BlueCard = styled(Card)`
  background-color: ${({ theme }) => theme.deprecated_primary5};
  color: ${({ theme }) => theme.accentAction};
  border-radius: 12px;
`;

export const RadiantPrimaryCard = styled(Card)`
  color: ${({ theme }) => theme.textPrimary};
  background: radial-gradient(
    85.06% 85.06% at 50% 14.94%,
    #2b4d8c 0%,
    rgba(23, 4, 69, 0.07) 100%
  );
  border-radius: 20px 20px 0px 0px;
`;

export const RadiantSecondaryCard = styled(Card)`
  color: ${({ theme }) => theme.textPrimary};
  background: radial-gradient(
    100% 100% at 50% 0%,
    rgba(6, 153, 126, 0.83) 0%,
    rgba(22, 6, 64, 0) 100%
  );
  border-radius: 20px 20px 0px 0px;
`;

export const BluePrimaryCard = styled(Card)`
  box-sizing: border-box;
  background: #131438;
  backdrop-filter: blur(40px);
  border-radius: 20px;
  border-top: 1px outset ${({ theme }) => theme.primary2};
  border-left: 1px outset ${({ theme }) => theme.primary2};
  border-bottom: 1px outset ${({ theme }) => theme.primary2};
  border-right: 1px outset #454866;
`;
