/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { ButtonEmpty, ButtonPrimary } from 'components/Button'
import Modal from 'components/Modal'
import { JAMON } from 'constants/tokens'
import { ethers } from 'ethers'
import { useLockerContract, useTokenContract } from 'hooks/useContract'
import { PublicLockData, WalletBalancesData } from 'pages/Sale/types'
import { useCallback, useState } from 'react'
import { CheckSquare, Square, X } from 'react-feather'
import styled from 'styled-components/macro'
import { CustomLightSpinner, ThemedText } from 'theme'
import { getFullDisplayBalance } from 'utils/formatBalance'

interface LockProps {
  chainId: number
  lockerData: PublicLockData
  walletData: WalletBalancesData
  onDismiss: () => void
  isOpen: boolean
}

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.deprecated_blue4};
  border-radius: 12px;
  width: 100%;
  padding: 16px;
`

export default function Lock({ chainId, lockerData, walletData, onDismiss, isOpen }: LockProps) {
  const [approving, setApproving] = useState(false)
  const [confirming, setConfirming] = useState(false)

  const [check, setCheck] = useState(false)
  const handleCheck = () => {
    setCheck(!check)
  }

  const { lockAmount } = lockerData
  const { jamonBalance, jamonLockAllowance } = walletData

  const jamonContract = useTokenContract(JAMON[chainId].address)
  const lockerContract = useLockerContract()

  const haveJamonBalance = jamonBalance.gte(lockAmount)
  const isJamonAllowed = jamonLockAllowance.gt(0)

  const handleApprove = useCallback(async () => {
    try {
      setApproving(true)
      const tx = await jamonContract?.approve(lockerContract?.address ?? '', ethers.constants.MaxUint256)
      await tx?.wait()
      walletData.fetchWalletData()
      setApproving(false)
    } catch (e) {
      setApproving(false)
      console.error(e)
    } finally {
      setApproving(false)
    }
  }, [walletData, lockerContract?.address, jamonContract])

  const handleAdd = useCallback(async () => {
    try {
      setConfirming(true)
      const tx = await lockerContract?.lockJamon()
      await tx?.wait()
      lockerData.fetchLockData()
      walletData.fetchWalletData()
      setConfirming(false)
      onDismiss()
    } catch (e) {
      setConfirming(false)
      console.error(e)
    } finally {
      setConfirming(false)
    }
  }, [lockerContract, lockerData, walletData, onDismiss])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <StyledClosed>
          <X size={24} onClick={onDismiss} />
        </StyledClosed>
        <ThemedText.LargeHeader textAlign="center" marginY="1rem" style={{ textTransform: 'uppercase' }}>
          <Trans>Lock</Trans> Jamon
        </ThemedText.LargeHeader>
        <ModalBody>
          <ThemedText.DeprecatedBody textAlign="left" color="blue2">
            <Trans>Lock 400.000 JAMON and win an NFT</Trans>.
            <br />
            <br />
            <Trans>At the end of the year you can withdraw your JAMON with 1% fee</Trans>.
          </ThemedText.DeprecatedBody>
        </ModalBody>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ButtonEmpty width="fit-content" onClick={handleCheck} mr="4px">
            {check ? <CheckSquare color="#5199ff" size={24} /> : <Square color="#5199ff" size={24} />}
          </ButtonEmpty>
          <ThemedText.DeprecatedBody textAlign="left" color="blue2">
            <Trans>I agree the conditions</Trans>
          </ThemedText.DeprecatedBody>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '1rem',
            marginBottom: '1rem',
            fontSize: '14px',
            marginRight: '0.6rem',
          }}
        >
          <Trans>Balance</Trans>: {getFullDisplayBalance(jamonBalance, 18)} JAMON
        </div>
        {isJamonAllowed ? (
          <ButtonPrimary mt="1rem" disabled={!haveJamonBalance || confirming || !check} onClick={handleAdd}>
            {!haveJamonBalance ? (
              <Trans>Insufficient Balance</Trans>
            ) : confirming ? (
              <>
                <Trans>Confirming</Trans>
                <CustomLightSpinner
                  src="/images/blue-loader.svg"
                  alt="loader"
                  size={'24px'}
                  style={{ marginLeft: '0.5rem' }}
                />
              </>
            ) : (
              <Trans>Confirm</Trans>
            )}
          </ButtonPrimary>
        ) : (
          <ButtonPrimary mt="1rem" disabled={approving || !check} onClick={handleApprove}>
            {approving ? (
              <>
                <Trans>Enabling</Trans>
                <CustomLightSpinner
                  src="/images/blue-loader.svg"
                  alt="loader"
                  size={'24px'}
                  style={{ marginLeft: '0.5rem' }}
                />
              </>
            ) : (
              <Trans>Enable</Trans>
            )}
          </ButtonPrimary>
        )}
      </ModalWrapper>
    </Modal>
  )
}
