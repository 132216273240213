import DISTRIBUTOR_ABI from 'abis/GreenBlockConversor.json'
import { DISTRIBUTOR_ADDRESS } from 'constants/addresses'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import multicall from 'utils/multicall'

/**
 * Gets all public data of Pool contract
 */
const useDistributorData = (chainId: number): boolean => {
  const distributorAddress = DISTRIBUTOR_ADDRESS[chainId]
  const { fastRefresh } = useRefresh()

  const [state, setState] = useState(false)

  const fetchData = useCallback(async () => {
    const userCalls = [
      {
        address: distributorAddress,
        name: 'canDistribute',
      },
    ]

    const [canDistribute] = await multicall(chainId, DISTRIBUTOR_ABI, userCalls)

    setState(Boolean(canDistribute[0]))
  }, [chainId, distributorAddress])

  useEffect(() => {
    fetchData()
  }, [fetchData, fastRefresh])

  return state
}

export default useDistributorData
