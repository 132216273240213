import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { ChainSelector } from "components/NavBar/ChainSelector";
import Web3Status from "components/Web3Status";
import { Row } from "nft/components/Flex";
import { ReactNode, useState } from "react";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

const HeaderLinks = styled(Row)`
  justify-self: center;
  width: fit-content;
  padding: 2px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2rem;
  overflow: auto;
  align-items: center;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToLarge`
    justify-self: start;  
    `};
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    justify-self: center;
  `};
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    margin: 0 auto;
    background-color: ${({ theme }) => theme.bg0};
    border: 1px solid ${({ theme }) => theme.bg3};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  `};
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 70%;
  `};
`;

const ButtonLink = styled(ThemedText.BodyPrimary)<{ activeColor: boolean }>`
  color: ${({ theme, activeColor }) =>
    activeColor ? theme.primary1 : theme.text1};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.primary1};
  }
`;

interface MenuItemProps {
  href: string;
  id?: NavLinkProps["id"];
  isActive?: boolean;
  children: ReactNode;
  dataTestId?: string;
}

const MenuItem = ({ href, dataTestId, id, children }: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      id={id}
      style={{ textDecoration: "none" }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  );
};

const PrimaryMenuRow = ({
  to,
  href,
  close,
  children,
}: {
  to?: NavLinkProps["to"];
  href?: string;
  close?: () => void;
  children: ReactNode;
}) => {
  return (
    <>
      {to ? (
        <NavLink to={to}>
          <Row onClick={close}>{children}</Row>
        </NavLink>
      ) : (
        <Row
          cursor="pointer"
          as="a"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </Row>
      )}
    </>
  );
};

export default function NavMenu() {
  const { pathname } = useLocation();
  const [active, setActive] = useState(0);
  const handleSetActive = (n: number) => {
    setActive(n);
  };

  const { chainId: connectedChainId } = useWeb3React();

  return (
    <>
      <HeaderLinks>
        <MenuItem href="/home" isActive={pathname.startsWith("/home")}>
          <ButtonLink
            onClick={() => {
              handleSetActive(0);
            }}
            activeColor={active === 0}
          >
            <Trans>Home</Trans>
          </ButtonLink>
        </MenuItem>
        {connectedChainId === 56 && (
          <MenuItem href="/stake" isActive={pathname.startsWith("/stake")}>
            <ButtonLink
              onClick={() => {
                handleSetActive(1);
              }}
              activeColor={active === 1}
            >
              <Trans>Stake</Trans>
            </ButtonLink>
          </MenuItem>
        )}
        {connectedChainId === 56 && (
          <MenuItem href="/mint" isActive={pathname.startsWith("/mint")}>
            <ButtonLink
              onClick={() => {
                handleSetActive(2);
              }}
              activeColor={active === 2}
            >
              <Trans>Mint</Trans>
            </ButtonLink>
          </MenuItem>
        )}
        {connectedChainId === 56 && (
          <MenuItem
            href="/stakeNFT"
            isActive={pathname.startsWith("/stakeNFT")}
          >
            <ButtonLink
              onClick={() => {
                handleSetActive(3);
              }}
              activeColor={active === 3}
            >
              <Trans>NFT Stake</Trans>
            </ButtonLink>
          </MenuItem>
        )}
        {connectedChainId === 137 && (
          <MenuItem href="/mine" isActive={pathname.startsWith("/mine")}>
            <ButtonLink
              onClick={() => {
                handleSetActive(4);
              }}
              activeColor={active === 4}
            >
              <Trans>Mine</Trans>
            </ButtonLink>
          </MenuItem>
        )}
        {connectedChainId === 137 && (
          <MenuItem
            href="/referrals"
            isActive={pathname.startsWith("/referrals")}
          >
            <ButtonLink
              onClick={() => {
                handleSetActive(5);
              }}
              activeColor={active === 5}
            >
              <Trans>Referrals</Trans>
            </ButtonLink>
          </MenuItem>
        )}
        <PrimaryMenuRow href="https://pancakeswap.finance/swap?outputCurrency=0xb8d5CC1462bf5a28366d58FfD4d40d5d26030F3F">
          <ButtonLink activeColor={false}>
            <Trans>Buy GBC</Trans>
          </ButtonLink>
        </PrimaryMenuRow>
        <PrimaryMenuRow href="https://greenblockcapital.io">
          <ButtonLink activeColor={false}>
            <Trans>Web</Trans>
          </ButtonLink>
        </PrimaryMenuRow>
        <PrimaryMenuRow href="https://telegram.me/GreenBlock">
          <ButtonLink activeColor={false}>
            <Trans>Telegram</Trans>
          </ButtonLink>
        </PrimaryMenuRow>
        <PrimaryMenuRow href="https://twitter.com/Green_BlockC">
          <ButtonLink activeColor={false}>
            <Trans>Twitter</Trans>
          </ButtonLink>
        </PrimaryMenuRow>
      </HeaderLinks>
      <Row gap="24">
        <ChainSelector />
        <Web3Status />
      </Row>
    </>
  );
}
