import { useCallback, useEffect, useState } from 'react'

import useRefresh from './useRefresh'

/**
 * Gets all public data of Sales contract
 */
const useBTCPrice = (): number => {
  const [state, setState] = useState(0)
  const { slowRefresh } = useRefresh()

  const apiUrl = 'https://api.coingecko.com/api/v3/simple/price?ids=Bitcoin&vs_currencies=USD'

  const getAPIData = useCallback(async () => {
    try {
      const apiResponse = await fetch(apiUrl)
      const json = await apiResponse.json()
      setState(json.bitcoin.usd)
    } catch (error) {
      console.error(error)
    }
  }, [apiUrl])

  useEffect(() => {
    getAPIData()
  }, [getAPIData, slowRefresh])

  return state
}

export default useBTCPrice
