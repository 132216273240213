import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import BarIcon from "assets/images/menu/bar.png";
import ReferralsColorIcon from "assets/images/menu/referrals-color-ico.png";
import ReferralsWhiteIcon from "assets/images/menu/referrals-white-ico.png";
import BigNumber from "bignumber.js";
import { useAccountDrawer } from "components/AccountDrawer";
import { ButtonPrimary } from "components/Button";
import { BlueCard } from "components/Card";
import { SUBGRAPH_URL } from "constants/subgraph";
import request, { gql } from "graphql-request";
import React, { useEffect, useState } from "react";
import { Copy } from "react-feather";
import { Navigate, useLocation } from "react-router-dom";
import { Text } from "rebass";
import styled from "styled-components/macro";
import { ButtonText, ThemedText } from "theme";
import { getFullDisplayBalance } from "utils/formatBalance";
import { isMobile } from "utils/userAgent";

import { SwitchLocaleLink } from "../../components/SwitchLocaleLink";
import ReferralsHistory from "./components/ReferralsHistory";

const PageWrapper = styled.div`
  max-width: 600px;
  width: 100%;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    max-width: 500px;
    margin-top: 5rem;
  `};
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
`;

const TruncatedText = styled(Text)`
  text-overflow: ellipsis;
  max-width: 220px;
  overflow: hidden;
  text-align: center;
`;

const EarnContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
`;

const allowedChains = (chainId: number) => {
  let supported = false;
  switch (chainId) {
    case 137:
    case 80001:
      supported = true;
      break;
    default:
      supported = false;
      break;
  }
  return supported;
};

interface ReferralRow {
  jamonEarned: string;
  usdtEarned: string;
  sales: [
    {
      id: string;
      amount: string;
      referralEarnings: string;
      inJamon: boolean;
    }
  ];
}

export default function Referrals() {
  const { account, chainId, connector } = useWeb3React();

  const darkmode = Boolean(window.matchMedia("(prefers-color-scheme: dark)"));
  const [, toggleAccountDrawer] = useAccountDrawer();

  const activeChain = chainId ? chainId : 137;
  const apiUrl = SUBGRAPH_URL[activeChain];

  const [history, setHistory] = useState<ReferralRow>();

  useEffect(() => {
    request(
      apiUrl,
      gql`
        query GetReferralInfo($address: String!) {
          referrals(where: { id: $address }) {
            jamonEarned
            usdtEarned
            sales(orderBy: dateTime, orderDirection: desc, first: 100) {
              id
              amount
              referralEarnings
              inJamon
            }
          }
        }
      `,
      {
        address: account?.toLowerCase(),
      }
    )
      .then(({ referrals }) => {
        if (referrals.length > 0) {
          setHistory(referrals[0]);
        }
      })
      .catch(({ error }) => {
        console.log(error);
      });
  }, [account, apiUrl]);

  const [isCopied, setIsCopied] = useState(false);
  const [link, setLink] = useState("");
  const refLink = `https://app.greenblockcapital.io?ref=${account}`;
  useEffect(() => {
    if (account) {
      setLink(refLink);
    }
  }, [account, refLink]);
  const copyTextToClipboard = (text: string | undefined) => {
    if (text !== undefined && "clipboard" in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand("copy", true, text);
  };

  const handleCopyClick = (copyText: string | undefined) => {
    try {
      copyTextToClipboard(copyText);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 5000);
    } catch (error) {
      setIsCopied(false);
    }
  };

  const bigJamonEarned = new BigNumber(history?.jamonEarned ?? 0);
  const bigUsdtEarned = new BigNumber(history?.usdtEarned ?? 0);

  const supportedChain = allowedChains(activeChain);
  const location = useLocation();
  if (!supportedChain) {
    return <Navigate to={{ ...location, pathname: "/home" }} replace />;
  }

  return (
    <>
      <PageWrapper>
        <BlueCard>
          <Header>
            <img
              src={darkmode ? ReferralsWhiteIcon : ReferralsColorIcon}
              alt=""
              width={isMobile ? 150 : 300}
            />
          </Header>
          {account ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <ThemedText.DeprecatedBody fontSize="24px">
                  <Trans>Your referral URL</Trans>
                </ThemedText.DeprecatedBody>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "0.2rem",
                }}
              >
                <ButtonText onClick={() => handleCopyClick(link)}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <ThemedText.DeprecatedBody
                      mr="0.5rem"
                      color={darkmode ? "LightBlue" : "blue"}
                    >
                      <TruncatedText>{refLink}</TruncatedText>
                    </ThemedText.DeprecatedBody>
                    <Copy size={24} color={darkmode ? "LightBlue" : "blue"} />
                  </div>
                  <ThemedText.DeprecatedBody
                    color={darkmode ? "LightBlue" : "blue"}
                  >
                    {isCopied ? <Trans>Copied!</Trans> : ""}
                  </ThemedText.DeprecatedBody>
                </ButtonText>
              </div>
              <EarnContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <ThemedText.MediumHeader fontSize="1rem">
                    <Trans>Profits</Trans> USDC
                  </ThemedText.MediumHeader>
                  <img src={BarIcon} alt="" width={isMobile ? "80%" : "50%"} />
                  <ThemedText.MediumHeader color="#F5F5DC">
                    {getFullDisplayBalance(bigUsdtEarned, 6, 0)}
                  </ThemedText.MediumHeader>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <ThemedText.MediumHeader fontSize="1rem">
                    <Trans>Profits</Trans> JAMON
                  </ThemedText.MediumHeader>
                  <img src={BarIcon} alt="" width={isMobile ? "80%" : "50%"} />
                  <ThemedText.MediumHeader color="#F5F5DC">
                    {getFullDisplayBalance(bigJamonEarned, 18, 2)}
                  </ThemedText.MediumHeader>
                </div>
              </EarnContent>
            </>
          ) : (
            <ButtonPrimary onClick={toggleAccountDrawer} mt="2rem">
              <Trans>Connect Wallet</Trans>
            </ButtonPrimary>
          )}
        </BlueCard>
        <ReferralsHistory sales={history?.sales} />
      </PageWrapper>
      <SwitchLocaleLink />
    </>
  );
}
