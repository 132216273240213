/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { ButtonRadioChecked } from "components/Button";
import { BlueCard } from "components/Card";
import Tooltip from "components/Tooltip";
import { SUBGRAPH_URL } from "constants/subgraph";
import { formatDistanceToNowStrict } from "date-fns";
import { enGB, es } from "date-fns/locale";
import request, { gql } from "graphql-request";
import { useActiveLocale } from "hooks/useActiveLocale";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { shortenAddress } from "utils";
import { isMobile } from "utils/userAgent";

import { WalletBalancesData } from "../types";

const TableWrapper = styled.div`
  flex-direction: column;
`;

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? "1fr 1fr 1fr" : "1.4fr 0.8fr 1fr 0.6fr 1fr"};
  margin-bottom: 1rem;
`;

const ButtonChecked = styled(ButtonRadioChecked)<{ isMobile?: boolean }>`
  width: 30%;
  color: ${({ theme }) => theme.deprecated_primary2};
  font-size: ${({ isMobile }) => (isMobile ? "12px" : "16px")};
  border-radius: 12px;
  border-color: ${({ theme }) => theme.deprecated_secondary3};
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;

const QuestionWrapper = styled.div`
  display: flex;
  margin-left: 0.2rem;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 18px;
  height: 18px;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.textPrimary};

  :hover,
  :focus {
    opacity: 0.7;
  }
`;

const QuestionMark = styled.span`
  font-size: 16px;
`;

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

const Arrow = styled.div`
  color: ${({ theme }) => theme.textPrimary};
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`;

const Row = styled(ThemedText.DeprecatedBody)<{ isWallet?: boolean }>`
  text-align: center;
  color: ${({ isWallet, theme }) => (isWallet ? theme.deprecated_yellow1 : "")};
`;
interface SaleRow {
  id: string;
  dateTime: string;
  nftClass: string;
  packId: string;
  buyer: {
    id: string;
  };
  value: string;
  amount: string;
  inJamon: boolean;
  tokenIds: string[];
}

interface SaleHistoryProps {
  chainId: number;
  walletData: WalletBalancesData;
}

const SaleHistory: React.FC<SaleHistoryProps> = ({ chainId, walletData }) => {
  const { account } = useWeb3React();
  const darkmode = Boolean(window.matchMedia("(prefers-color-scheme: dark)"));
  const activeLocale = useActiveLocale();

  const [page, setPage] = useState(1);

  const apiUrl = SUBGRAPH_URL[chainId];

  const [history, setHistory] = useState<SaleRow[]>([]);

  useEffect(() => {
    request(
      apiUrl,
      gql`
        query {
          stores(first: 1) {
            sales(orderBy: dateTime, orderDirection: desc, first: 100) {
              id
              dateTime
              nftClass
              packId
              buyer {
                id
              }
              value
              amount
              inJamon
              tokenIds
            }
          }
        }
      `
    )
      .then(({ stores }) => {
        if (stores.length > 0) {
          setHistory(stores[0].sales);
        }
      })
      .catch(({ error }) => {
        console.log(error);
      });
  }, [apiUrl, walletData]);

  const [showOwed, setShowOwed] = useState<boolean>(false);
  const activeOwed = () => {
    setShowOwed(!showOwed);
    setPage(1);
  };

  const ITEMS = isMobile ? 5 : 10;

  let saleMine = history;
  if (showOwed) {
    saleMine = history?.filter(
      (item) => item.buyer.id === account?.toLowerCase()
    );
  }

  const sortedHistory = useMemo(() => {
    return saleMine.length > 0
      ? saleMine.slice(ITEMS * (page - 1), page * ITEMS)
      : [];
  }, [saleMine, page]);

  const maxPage =
    sortedHistory.length > 0 ? Math.ceil(saleMine.length / ITEMS) : 0;

  const [show, setShow] = useState(999);

  const open = useCallback((index: number) => setShow(index), [setShow]);
  const close = useCallback((index: number) => setShow(index), [setShow]);

  return history && history.length > 0 ? (
    <div>
      {account && (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <ButtonChecked
            active={showOwed}
            onClick={activeOwed}
            isMobile={isMobile}
          >
            <Trans>My Purchases</Trans>
          </ButtonChecked>
        </div>
      )}
      <BlueCard mt={account ? "" : "2rem"}>
        <ThemedText.LargeHeader
          textAlign="center"
          marginY="1rem"
          color={darkmode ? "#5199FF" : "#60CF3C"}
        >
          <Trans>Sale History</Trans>
        </ThemedText.LargeHeader>
        {saleMine && saleMine.length > 0 ? (
          <TableWrapper>
            <ResponsiveGrid isMobile={isMobile}>
              {!isMobile && (
                <ThemedText.DeprecatedBody
                  fontSize={isMobile ? 12 : 16}
                  textAlign="center"
                >
                  <Trans>Date</Trans>
                </ThemedText.DeprecatedBody>
              )}
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? 12 : 16}
                textAlign="center"
              >
                <Trans>Type</Trans>
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? 12 : 16}
                textAlign="center"
              >
                <Trans>Buyer</Trans>
              </ThemedText.DeprecatedBody>
              {!isMobile && (
                <ThemedText.DeprecatedBody
                  fontSize={isMobile ? 12 : 16}
                  textAlign="center"
                >
                  <Trans>Amount</Trans>
                </ThemedText.DeprecatedBody>
              )}
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? 12 : 16}
                textAlign="center"
              >
                NFT ID
              </ThemedText.DeprecatedBody>
              {sortedHistory.map((item, index) => {
                const date = formatDistanceToNowStrict(
                  parseInt(item.dateTime, 10) * 1000,
                  {
                    addSuffix: true,
                    locale: activeLocale === "es-ES" ? es : enGB,
                  }
                );
                return (
                  <React.Fragment key={index}>
                    {!isMobile && (
                      <Row
                        fontSize={isMobile ? 12 : 16}
                        isWallet={item.buyer.id === account?.toLowerCase()}
                      >
                        {date}
                      </Row>
                    )}
                    <Row
                      fontSize={isMobile ? 12 : 16}
                      isWallet={item.buyer.id === account?.toLowerCase()}
                    >
                      {Number(item.nftClass) === 0 ? (
                        <Trans>Cave</Trans>
                      ) : (
                        <Trans>Miner</Trans>
                      )}
                    </Row>
                    <Row
                      fontSize={isMobile ? 12 : 16}
                      isWallet={item.buyer.id === account?.toLowerCase()}
                    >
                      {shortenAddress(item.buyer.id, 3)}
                    </Row>
                    {!isMobile && (
                      <Row
                        fontSize={isMobile ? 12 : 16}
                        isWallet={item.buyer.id === account?.toLowerCase()}
                      >
                        {item.amount}
                      </Row>
                    )}
                    <Row
                      fontSize={isMobile ? 12 : 16}
                      isWallet={item.buyer.id === account?.toLowerCase()}
                    >
                      {item.tokenIds.length > 1 ? (
                        <Tooltip
                          text={item.tokenIds?.toString()}
                          show={index === show}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {item.tokenIds[0]}
                            <QuestionWrapper
                              onClick={() => open(index)}
                              onMouseEnter={() => open(index)}
                              onMouseLeave={() => close(999)}
                            >
                              <QuestionMark>+</QuestionMark>
                            </QuestionWrapper>
                          </div>
                        </Tooltip>
                      ) : (
                        item.tokenIds?.toString()
                      )}
                    </Row>
                  </React.Fragment>
                );
              })}
            </ResponsiveGrid>
            <PageButtons>
              <Arrow
                onClick={() => {
                  setPage(page === 1 ? page : page - 1);
                }}
              >
                <ThemedText.DeprecatedBody
                  color={page === 1 ? "text" : "primary"}
                >
                  {" "}
                  {"<"}{" "}
                </ThemedText.DeprecatedBody>
              </Arrow>

              <ThemedText.DeprecatedBody>
                <Trans>Page</Trans> {page} <Trans>of</Trans> {maxPage}
              </ThemedText.DeprecatedBody>
              <Arrow
                onClick={() => {
                  setPage(page === maxPage ? page : page + 1);
                }}
              >
                <ThemedText.DeprecatedBody
                  color={page === maxPage ? "textDisabled" : "primary"}
                >
                  {" "}
                  {">"}{" "}
                </ThemedText.DeprecatedBody>
              </Arrow>
            </PageButtons>
          </TableWrapper>
        ) : (
          <ThemedText.DeprecatedYellow
            fontSize={isMobile ? "18px" : "20px"}
            textAlign="center"
          >
            <Trans>No history</Trans>!
          </ThemedText.DeprecatedYellow>
        )}
      </BlueCard>
    </div>
  ) : null;
};

export default SaleHistory;
