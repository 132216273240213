import "@reach/dialog/styles.css";
import "inter-ui";
import "polyfills";
import "tracing";

import { ApolloProvider } from "@apollo/client";
import { RefreshContextProvider } from "contexts/RefreshContext";
import { FeatureFlagsProvider } from "featureFlags";
import { apolloClient } from "graphql/data/apollo";
import { BlockNumberProvider } from "lib/hooks/useBlockNumber";
import { MulticallUpdater } from "lib/state/multicall";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import BackgroundTheme from "theme/components/BackgroundTheme";
import { SystemThemeUpdater } from "theme/components/ThemeToggle";

import Web3Provider from "./components/Web3Provider";
import { LanguageProvider } from "./i18n";
import App from "./pages/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import store from "./state";
import ApplicationUpdater from "./state/application/updater";
import ListsUpdater from "./state/lists/updater";
import LogsUpdater from "./state/logs/updater";
import OrderUpdater from "./state/signatures/updater";
import TransactionUpdater from "./state/transactions/updater";
import ThemeProvider /* , { ThemedGlobalStyle } */ from "./theme";

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

function Updaters() {
  return (
    <>
      {/* <RadialGradientByChainUpdater /> */}
      <BackgroundTheme />
      <ListsUpdater />
      <SystemThemeUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <OrderUpdater />
      <MulticallUpdater />
      <LogsUpdater />
    </>
  );
}

const queryClient = new QueryClient();

const container = document.getElementById("root") as HTMLElement;

createRoot(container).render(
  <StrictMode>
    <Provider store={store}>
      <FeatureFlagsProvider>
        <QueryClientProvider client={queryClient}>
          <HashRouter>
            <LanguageProvider>
              <Web3Provider>
                <ApolloProvider client={apolloClient}>
                  <BlockNumberProvider>
                    <Updaters />
                    <ThemeProvider>
                      {/* <ThemedGlobalStyle /> */}
                      <RefreshContextProvider>
                        <App />
                      </RefreshContextProvider>
                    </ThemeProvider>
                  </BlockNumberProvider>
                </ApolloProvider>
              </Web3Provider>
            </LanguageProvider>
          </HashRouter>
        </QueryClientProvider>
      </FeatureFlagsProvider>
    </Provider>
  </StrictMode>
);

if (process.env.REACT_APP_SERVICE_WORKER !== "false") {
  serviceWorkerRegistration.register();
}
