import ERC721_ABI from 'abis/GreenBlockCave.json'
import { BigNumber } from 'bignumber.js'
import {
  CAVE_POOL_ADDRESS,
  MINER_POOL_ADDRESS,
  NFT_CAVE_ADDRESS,
  NFT_MINER_ADDRESS,
  NFT_VIP_ADDRESS,
} from 'constants/addresses'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import multicall from 'utils/multicall'

import { WalletNFTsBalancesData } from '../types'
import { useWeb3React } from '@web3-react/core'

/**
 * Gets all public data of Sales contract
 */
const useNFTsBalance = (chainId: number): WalletNFTsBalancesData => {
  const { account } = useWeb3React()
  const caveAddress = NFT_CAVE_ADDRESS[chainId]
  const minerAddress = NFT_MINER_ADDRESS[chainId]
  const vipAddress = NFT_VIP_ADDRESS[chainId]
  const cavePoolAddress = CAVE_POOL_ADDRESS[chainId]
  const minerPoolAddress = MINER_POOL_ADDRESS[chainId]
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    caveBalance: 0,
    minerBalance: 0,
    vipBalance: 0,
    caveAllowance: false,
    minerAllowance: false,
    vipAllowance: false,
    caves: new Array<number>(),
    miners: new Array<number>(),
    vips: new Array<number>(),
  })

  const fetchWalletBalanceData = useCallback(async () => {
    const userCalls = [
      {
        address: caveAddress,
        name: 'balanceOf',
        params: [account],
      },
      {
        address: minerAddress,
        name: 'balanceOf',
        params: [account],
      },
      {
        address: vipAddress,
        name: 'balanceOf',
        params: [account],
      },
      {
        address: caveAddress,
        name: 'isApprovedForAll',
        params: [account, cavePoolAddress],
      },
      {
        address: minerAddress,
        name: 'isApprovedForAll',
        params: [account, minerPoolAddress],
      },
      {
        address: vipAddress,
        name: 'isApprovedForAll',
        params: [account, cavePoolAddress],
      },
    ]

    const [caveBalance, minerBalance, vipBalance, caveAllowance, minerAllowance, vipAllowance] = await multicall(
      chainId,
      ERC721_ABI,
      userCalls
    )

    const cavesBalance = Number(caveBalance[0])
    const minersBalance = Number(minerBalance[0])
    const vipsBalance = Number(vipBalance[0])

    const cavesArray: Array<number> = []
    const minersArray: Array<number> = []
    const vipsArray: Array<number> = []

    if (cavesBalance > 0) {
      const cavesMap = Array.from(Array(cavesBalance).keys())
      const cavesIdCalls = cavesMap.map((index) => {
        return {
          address: caveAddress,
          name: 'tokenOfOwnerByIndex',
          params: [account, index],
        }
      })

      const tokenOf = await multicall(chainId, ERC721_ABI, cavesIdCalls)
      tokenOf.map((i: BigNumber) => cavesArray.push(Number(i.toString())))
    }

    if (minersBalance > 0) {
      const minersMap = Array.from(Array(minersBalance).keys())
      const minersIdCalls = minersMap.map((index) => {
        return {
          address: minerAddress,
          name: 'tokenOfOwnerByIndex',
          params: [account, index],
        }
      })

      const tokenOf = await multicall(chainId, ERC721_ABI, minersIdCalls)
      tokenOf.map((i: BigNumber) => minersArray.push(Number(i.toString())))
    }

    if (vipsBalance > 0) {
      const vipsMap = Array.from(Array(vipsBalance).keys())
      const vipsIdCalls = vipsMap.map((index) => {
        return {
          address: vipAddress,
          name: 'tokenOfOwnerByIndex',
          params: [account, index],
        }
      })

      const tokenOf = await multicall(chainId, ERC721_ABI, vipsIdCalls)
      tokenOf.map((i: BigNumber) => vipsArray.push(Number(i.toString())))
    }

    setState((prev) => ({
      ...prev,
      caveBalance: cavesBalance,
      minerBalance: minersBalance,
      vipBalance: vipsBalance,
      caveAllowance: Boolean(caveAllowance[0]),
      minerAllowance: Boolean(minerAllowance[0]),
      vipAllowance: Boolean(vipAllowance[0]),
      caves: cavesArray,
      miners: minersArray,
      vips: vipsArray,
    }))
  }, [account, caveAddress, cavePoolAddress, chainId, minerAddress, minerPoolAddress, vipAddress])

  useEffect(() => {
    if (account) {
      fetchWalletBalanceData()
    }
  }, [account, fetchWalletBalanceData, slowRefresh])

  return { ...state, fetchWalletBalanceData }
}

export default useNFTsBalance
