import LOCKER_ABI from 'abis/GreenBlockJamonStake.json'
import BigNumber from 'bignumber.js'
import { LOCKER_ADDRESS } from 'constants/addresses'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
//import { BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'

import { PublicLockData } from '../types'

/**
 * Gets all public data of Sales contract
 */
const useGetPublicLockData = (chainId: number): PublicLockData => {
  const address = LOCKER_ADDRESS[chainId]
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    isOpen: false,
    available: 20,
    lockStart: 1664208000,
    lockEnd: 1665072000,
    locksLimit: 10,
    lockAmount: new BigNumber(400000),
    lockPeriod: 31556926,
    unlockFee: 100,
    loading: true,
  })

  const fetchLockData = useCallback(async () => {
    const Calls = [
      {
        address,
        name: 'lockOpen',
      },
      {
        address,
        name: 'minersAvailable',
      },
      {
        address,
        name: 'lockStart',
      },
      {
        address,
        name: 'lockEnd',
      },
      {
        address,
        name: 'locksLimit',
      },
      {
        address,
        name: 'lockAmount',
      },
      {
        address,
        name: 'lockPeriod',
      },
      {
        address,
        name: 'unlockFee',
      },
    ]

    const [lockOpen, minersAvailable, lockStart, lockEnd, locksLimit, lockAmount, lockPeriod, unlockFee] =
      await multicall(chainId, LOCKER_ABI, Calls)

    setState((prev) => ({
      ...prev,
      isOpen: Boolean(lockOpen[0]),
      available: Number(minersAvailable[0]),
      lockStart: Number(lockStart[0]),
      lockEnd: Number(lockEnd[0]),
      locksLimit: Number(locksLimit[0]),
      lockAmount: new BigNumber(lockAmount[0].toString()),
      lockPeriod: Number(lockPeriod[0]),
      unlockFee: Number(unlockFee[0]),
      loading: false,
    }))
  }, [address, chainId])

  useEffect(() => {
    fetchLockData()
  }, [fetchLockData, slowRefresh])

  return { ...state, fetchLockData }
}

export default useGetPublicLockData
