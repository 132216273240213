import LP_ABI from "abis/lpToken.json";
import BigNumber from "bignumber.js";
import { GBC_PAIR_ADDRESS } from "constants/addresses";
import { BTC, GBC, USDC, USDT_ } from "constants/tokens";
import useRefresh from "hooks/useRefresh";
import { useCallback, useEffect, useState } from "react";
import { BIG_ZERO } from "utils/bigNumber";
import multicall from "utils/multicall";

import { PricesData } from "../types";

const BIG_DECIMALS = new BigNumber(10).pow(18);

/**
 * Gets all public data of Pool contract
 */
const useGbcPrice = (chainId: number): PricesData => {
  const bnbPoolAddress = GBC_PAIR_ADDRESS[chainId];
  const busdAddress = USDC[chainId].address;
  const usdtAddress = USDT_[chainId].address;
  const gbtAddress = GBC[chainId].address;
  const btcAddress = BTC[chainId].address;
  const { slowRefresh } = useRefresh();

  const [state, setState] = useState({
    gbtPrice: BIG_ZERO,
    gbtPriceUsd: BIG_ZERO,
    bnbPrice: BIG_ZERO,
    btcPrice: BIG_ZERO,
    treasuryUSD: BIG_ZERO,
  });

  const treasuryWallet = "0x6E9a9104649848eB45B5200c323B9e46C0515902";

  const fetchPriceData = useCallback(async () => {
    const userCalls = [
      {
        address: bnbPoolAddress,
        name: "getReserves",
      },
      {
        address: busdAddress,
        name: "balanceOf",
        params: [treasuryWallet],
      },
      {
        address: usdtAddress,
        name: "balanceOf",
        params: [treasuryWallet],
      },
      {
        address: gbtAddress,
        name: "balanceOf",
        params: [treasuryWallet],
      },
      {
        address: btcAddress,
        name: "balanceOf",
        params: [treasuryWallet],
      },
    ];
    const [reserves, balanceBUSD, balanceUSDT, balanceGBT, balanceBTC] =
      await multicall(chainId, LP_ABI, userCalls);

    const responseBNB = await fetch(
      "https://www.binance.com/api/v3/ticker/price?symbol=BNBUSDT"
    );
    const dataBNB = await responseBNB.json();
    const bnbPrice = dataBNB.price.split(".").join("");
    const BNBPrice = new BigNumber(bnbPrice).times(new BigNumber(10).pow(10));

    const responseBTC = await fetch(
      "https://www.binance.com/api/v3/ticker/price?symbol=BTCUSDT"
    );
    const dataBTC = await responseBTC.json();
    const btcPrice = dataBTC.price.split(".").join("");
    const BTCPrice = new BigNumber(btcPrice).times(new BigNumber(10).pow(10));

    const reserve0 = new BigNumber(reserves[1].toString());
    const reserve1 = new BigNumber(reserves[0].toString());

    const price = reserve0.times(BIG_DECIMALS).div(reserve1);

    const gbtPriceUsd = price.times(BNBPrice).div(BIG_DECIMALS);

    const treasuryBalanceBUSD = new BigNumber(balanceBUSD[0].toString());
    const treasuryBalanceUSDT = new BigNumber(balanceUSDT[0].toString());
    const treasuryBalanceGBT = new BigNumber(balanceGBT[0].toString());
    const treasuryBalanceBTC = new BigNumber(balanceBTC[0].toString());
    const treasuryBalanceGBT_USD = treasuryBalanceGBT
      .times(gbtPriceUsd)
      .div(BIG_DECIMALS);
    const treasuryBalanceBTC_USD = treasuryBalanceBTC
      .times(BTCPrice)
      .div(BIG_DECIMALS);
    const treasuryUSD = treasuryBalanceBUSD
      .plus(treasuryBalanceUSDT)
      .plus(treasuryBalanceGBT_USD)
      .plus(treasuryBalanceBTC_USD);

    setState((prev) => ({
      ...prev,
      gbtPrice: price,
      gbtPriceUsd,
      bnbPrice: BNBPrice,
      btcPrice: BTCPrice,
      treasuryUSD,
    }));
  }, [
    bnbPoolAddress,
    btcAddress,
    busdAddress,
    chainId,
    gbtAddress,
    usdtAddress,
  ]);

  useEffect(() => {
    fetchPriceData();
  }, [fetchPriceData, slowRefresh]);

  return { ...state, fetchPriceData };
};

export default useGbcPrice;
