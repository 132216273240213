import ERC20_ABI from 'abis/erc20.json'
import BigNumber from 'bignumber.js'
import { GBC_PAIR_ADDRESS, GBC_POOL_ADDRESS } from 'constants/addresses'
import { GBC } from 'constants/tokens'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import { BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'

import { GbcSupplyData } from '../types'

/**
 * Gets all public data of holders contract
 */
const useGbcSupply = (chainId: number): GbcSupplyData => {
  const poolAddress = GBC_POOL_ADDRESS[chainId]
  const bnbPoolAddress = GBC_PAIR_ADDRESS[chainId]
  const gbtAddress = GBC[chainId].address
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    totalSupply: BIG_ZERO,
    liquidity: BIG_ZERO,
    staked: BIG_ZERO,
    other: BIG_ZERO,
  })

  const fetchSupplyData = useCallback(async () => {
    const userCalls = [
      {
        address: gbtAddress,
        name: 'totalSupply',
      },
      {
        address: gbtAddress,
        name: 'balanceOf',
        params: [bnbPoolAddress],
      },
      {
        address: gbtAddress,
        name: 'balanceOf',
        params: [poolAddress],
      },
    ]

    const [totalSupply, bnbPool, pool] = await multicall(chainId, ERC20_ABI, userCalls)

    const bigTotalSupply = new BigNumber(totalSupply[0].toString())
    const bigBNB = new BigNumber(bnbPool[0].toString())
    const bigStaked = new BigNumber(pool[0].toString())
    const bigOther = bigTotalSupply.minus(bigBNB.plus(bigStaked))

    setState((prev) => ({
      ...prev,
      totalSupply: bigTotalSupply,
      liquidity: bigBNB,
      staked: bigStaked,
      other: bigOther,
    }))
  }, [bnbPoolAddress, chainId, poolAddress, gbtAddress])

  useEffect(() => {
    fetchSupplyData()
  }, [fetchSupplyData, slowRefresh])

  return { ...state, fetchSupplyData }
}

export default useGbcSupply
