import LOCKER_ABI from 'abis/GreenBlockJamonStake.json'
import { LOCKER_ADDRESS } from 'constants/addresses'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import multicall from 'utils/multicall'

import { WalletLockerData } from '../types'
import { useWeb3React } from '@web3-react/core'

/**
 * Gets all public data of Sales contract
 */
const useWalletLockerData = (chainId: number): WalletLockerData => {
  const { account } = useWeb3React()
  const lockerAddress = LOCKER_ADDRESS[chainId]
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    locks: 0,
  })

  const fetchWalletData = useCallback(async () => {
    const userCalls = [
      {
        address: lockerAddress,
        name: 'userLocks',
        params: [account],
      },
    ]

    const [userLocks] = await multicall(chainId, LOCKER_ABI, userCalls)

    setState((prev) => ({
      ...prev,
      locks: Number(userLocks[0]),
    }))
  }, [account, chainId, lockerAddress])

  useEffect(() => {
    if (account) {
      fetchWalletData()
    }
  }, [account, fetchWalletData, slowRefresh])

  return { ...state, fetchWalletData }
}

export default useWalletLockerData
