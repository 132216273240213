/* eslint-disable import/no-unused-modules */
import { ChainId } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";

// const initialStyles = {
// width: "200vw",
// height: "200vh",
// backgroundSize: "cover",
// };
// const backgroundResetStyles = {
// width: "100vw",
// height: "100vh",
// backgroundSize: "cover",
// };
//
// type TargetBackgroundStyles =
// | typeof initialStyles
// | typeof backgroundResetStyles;

const htmlElement = document.querySelector("html");

// const setBackground = (newValues: TargetBackgroundStyles) =>
// Object.entries(newValues).forEach(([key, value]) => {
//   if (htmlElement) {
//     htmlElement.style[
//       key as keyof typeof backgroundResetStyles
//     ] = value;
//   }
// });

export default function BackgroundTheme(): null {
  const { chainId } = useWeb3React();

  useEffect(() => {
    if (!htmlElement) {
      return;
    }
    switch (chainId) {
      case ChainId.POLYGON:
      case ChainId.POLYGON_MUMBAI: {
        htmlElement.style.backgroundImage = `url(${process.env.PUBLIC_URL}/images/bg-polygon.svg)`;
        htmlElement.style.backgroundSize = "cover";
        htmlElement.style.height = "100hv";
        break;
      }
      case ChainId.BNB: {
        htmlElement.style.backgroundImage = `url(${process.env.PUBLIC_URL}/images/bg-bnb.svg)`;
        htmlElement.style.backgroundSize = "cover";
        htmlElement.style.height = "100hv";
        break;
      }
      default: {
        htmlElement.style.backgroundImage = `url(${process.env.PUBLIC_URL}/images/bg-bnb.svg)`;
        htmlElement.style.backgroundSize = "cover";
        htmlElement.style.height = "100hv";
      }
    }
  }, [chainId]);

  return null;
}
