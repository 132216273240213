import CAVE_POOL_ABI from 'abis/GreenBlockCavePool.json'
import { CAVE_POOL_ADDRESS } from 'constants/addresses'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import multicall from 'utils/multicall'

import { VipAttributes } from '../types'
import { useWeb3React } from '@web3-react/core'

/**
 * Gets all public data of Sales contract
 */
const useVipStaked = (chainId: number, ids: number[]): VipAttributes[] => {
  const { account } = useWeb3React()
  const address = CAVE_POOL_ADDRESS[chainId]
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState<VipAttributes[]>([])

  const fetchData = useCallback(async () => {
    const idCalls = ids.map((index) => {
      return {
        address,
        name: 'isVipStaked',
        params: [index],
      }
    })

    const attributesOf = await multicall(chainId, CAVE_POOL_ABI, idCalls)
    const attributes = ids.map((id, index) => {
      const staked = Boolean(attributesOf[index][0])
      return {
        id,
        staked,
      }
    })

    setState(attributes)
  }, [address, chainId, ids])

  useEffect(() => {
    if (ids.length > 0) {
      fetchData()
    }
  }, [account, fetchData, ids.length, slowRefresh])

  return state
}

export default useVipStaked
