/* eslint-disable react/prop-types */
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import CaveNFT from 'assets/svg/nfts/cave-nft.svg'
import { ButtonPrimary } from 'components/Button'
import { useState } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import { Info } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { isMobile } from 'utils/userAgent'

import { currentTimestamp } from '..'
import { saleStart, whitelistEnd } from '../config'
import useGetPublicCaveSaleData from '../hooks/useGetPublicCaveSale'
import { WalletBalancesData } from '../types'
import BuyCave from './Actions/BuyCave'
import { useAccountDrawer } from 'components/AccountDrawer'

interface CaveProps {
  chainId: number
  walletData: WalletBalancesData
  account?: string | null
}

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const Body = styled.div`
  width: 100%;
`

const UserData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
  border-top: 1px solid ${({ theme }) => theme.deprecated_primary2};
`
const PriceContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
`

const Message = styled(ThemedText.MediumHeader)`
  color: ${({ theme }) => theme.deprecated_secondary3};
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
`

const StyledAlert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_yellow1};
  border-radius: 12px;
  width: 100%;
  padding: 12px;
  margin-bottom: 1rem;
`

const renderer = (days: number, hours: number, minutes: number, seconds: number) => (
  <span>
    <Trans>Starts in</Trans> {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
  </span>
)

export default function Cave({ chainId, walletData, account }: CaveProps) {
  const [, toggleAccountDrawer] = useAccountDrawer()
  const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))

  const [openModal, setOpenModal] = useState(false)
  const handleDissmis = () => {
    setOpenModal(false)
  }

  const caveSaleData = useGetPublicCaveSaleData(chainId)

  const { total4Sale, usdPrice, power, duration, sold, total4Jamon, soldInJamon, jamonPrice } = caveSaleData

  const oneDay = 86400

  const durationInDays = duration / oneDay

  const totalAvailable = total4Sale - sold
  const jamonAvailable = total4Jamon - soldInJamon

  const isStarted = saleStart < currentTimestamp()
  const [started, setStarted] = useState(isStarted)

  const whitelistActive = whitelistEnd > currentTimestamp()

  return (
    <div>
      <Heading>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img width={isMobile ? 150 : 200} src={CaveNFT} alt="sale-img" style={{ borderRadius: '10px' }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ThemedText.MediumHeader fontSize="1rem" color={darkmode ? '#5199FF' : '#57db48'}>
              <Trans>Available</Trans>
            </ThemedText.MediumHeader>
            <ThemedText.LargeHeader fontSize="2rem" color="#F5F5DC">
              {totalAvailable}
            </ThemedText.LargeHeader>
            <ThemedText.MediumHeader fontStyle="italic" fontSize="0.8rem" color={darkmode ? '#5199FF' : '#57db48'}>
              <Trans>of</Trans> {total4Sale} <Trans>on sale</Trans>
            </ThemedText.MediumHeader>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '0.5rem' }}>
            <ThemedText.MediumHeader fontSize="1rem" color={darkmode ? '#5199FF' : '#57db48'}>
              <Trans>Available in</Trans> JAMON
            </ThemedText.MediumHeader>
            <ThemedText.LargeHeader fontSize="2rem" color="#F5F5DC">
              {jamonAvailable}
            </ThemedText.LargeHeader>
            <ThemedText.MediumHeader fontStyle="italic" fontSize="0.8rem" color={darkmode ? '#5199FF' : '#57db48'}>
              <Trans>of</Trans> {total4Jamon} <Trans>on sale</Trans>
            </ThemedText.MediumHeader>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '0.5rem' }}>
            <ThemedText.MediumHeader fontSize="1rem" color={darkmode ? '#5199FF' : '#57db48'}>
              <Trans>Power</Trans>
            </ThemedText.MediumHeader>
            <ThemedText.MediumHeader fontStyle="italic" color="#F5F5DC">
              {getFullDisplayBalance(power, 6, 0)} Mhs
            </ThemedText.MediumHeader>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '0.5rem' }}>
            <ThemedText.MediumHeader fontSize="1rem" color={darkmode ? '#5199FF' : '#57db48'}>
              <Trans>Duration</Trans>
            </ThemedText.MediumHeader>
            <ThemedText.MediumHeader fontStyle="italic" color="#F5F5DC">
              {durationInDays.toFixed(0)} <Trans>days</Trans>
            </ThemedText.MediumHeader>
          </div>
        </div>
      </Heading>
      <PriceContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ThemedText.MediumHeader fontSize="1rem" color={darkmode ? '#5199FF' : '#57db48'}>
            <Trans>Price in</Trans> USDC
          </ThemedText.MediumHeader>
          <ThemedText.MediumHeader color="#F5F5DC">{getFullDisplayBalance(usdPrice, 6, 0)}</ThemedText.MediumHeader>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ThemedText.MediumHeader fontSize="1rem" color={darkmode ? '#5199FF' : '#57db48'}>
            <Trans>Price in</Trans> JAMON
          </ThemedText.MediumHeader>
          <ThemedText.MediumHeader color="#F5F5DC">{getFullDisplayBalance(jamonPrice, 18, 2)}</ThemedText.MediumHeader>
        </div>
      </PriceContent>
      {account ? (
        <Body>
          <div>
            <UserData>
              <Message></Message>
            </UserData>
            {sold < total4Sale ? (
              <>
                {started && whitelistActive && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '80%',
                      margin: 'auto',
                    }}
                  >
                    <StyledAlert>
                      <Info size={28} color="#E3A507" />
                      <ThemedText.DeprecatedBody textAlign="left" color="#E3A507" ml="4px">
                        <Trans>Whitelist active</Trans>
                      </ThemedText.DeprecatedBody>
                    </StyledAlert>
                  </div>
                )}
                <ButtonPrimary width="80%" margin="auto" onClick={() => setOpenModal(true)} disabled={!started}>
                  {started ? (
                    <Trans>Buy</Trans>
                  ) : (
                    <Countdown
                      date={new Date(saleStart)}
                      renderer={(props: any) => renderer(props.days, props.hours, props.minutes, props.seconds)}
                      onComplete={() => setStarted(true)}
                    />
                  )}
                </ButtonPrimary>
              </>
            ) : (
              <Message>
                <FontAwesomeIcon icon={faCircleCheck} style={{ marginRight: '0.5rem' }} />
                <Trans>Sale Finished</Trans>
              </Message>
            )}
          </div>
        </Body>
      ) : (
        <div>
          {sold >= total4Sale && (
            <Message>
              <FontAwesomeIcon icon={faCircleCheck} style={{ marginRight: '0.5rem' }} />
              <Trans>Sale Finished</Trans>
            </Message>
          )}
          <ButtonPrimary onClick={toggleAccountDrawer} width="80%" margin="auto" marginTop="2rem">
            <Trans>Connect Wallet</Trans>
          </ButtonPrimary>
        </div>
      )}
      <BuyCave
        isOpen={openModal}
        onDismiss={handleDissmis}
        saleData={caveSaleData}
        walletData={walletData}
        chainId={chainId}
      />
    </div>
  )
}
