// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { ButtonPrimary } from 'components/Button'
import Modal from 'components/Modal'
import { Input as NumericalInput } from 'components/NumericalInput'
import { useCavePoolContract } from 'hooks/useContract'
import { useCallback, useState } from 'react'
import { Info, X } from 'react-feather'
import styled from 'styled-components/macro'
import { CustomLightSpinner, ThemedText } from 'theme'
import { USDT_DECIMALS } from 'utils/bigNumber'

interface AddBonusProps {
  chainId: number
  caveId: number
  onDismiss: () => void
  update: () => void
  isOpen: boolean
}

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_blue4};
  border-radius: 12px;
  width: 100%;
  padding: 16px;
`
const StyleDivInput = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.deprecated_primary2};
  border-radius: 8px;
  flex-direction: row;
  width: 100%;
  padding: 8px;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const StyledInput = styled(NumericalInput)`
  background-color: transparent;
  text-align: center;
  width: 100%;
  padding: 0 10px;
  font-size: 18px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    font-size: 12px;
  `};
`

export default function SellCave({ chainId, caveId, onDismiss, isOpen, update }: AddBonusProps) {
  
  const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))
  const [confirming, setConfirming] = useState(false)
  const [price, setprice] = useState('')

  const salePrice = new BigNumber(price ?? 0).times(USDT_DECIMALS)
  const isValid = salePrice.gt(0)

  const cavePoolContract = useCavePoolContract()

  const handleSell = useCallback(async () => {
    try {
      setConfirming(true)
      const tx = await cavePoolContract?.sellCave(caveId, salePrice.toFixed())
      await tx?.wait()
      setConfirming(false)
      update()
      onDismiss()
    } catch (e) {
      setConfirming(false)
      console.error(e)
    } finally {
      setConfirming(false)
    }
  }, [caveId, cavePoolContract, onDismiss, salePrice, update])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <StyledClosed>
          <X size={24} onClick={onDismiss} />
        </StyledClosed>
        <ThemedText.LargeHeader textAlign="center" marginY="1rem" style={{ textTransform: 'uppercase' }}>
          <Trans>Sell Cave</Trans>
        </ThemedText.LargeHeader>
        <ModalBody>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '20%' }}>
            <Info size={28} color={darkmode ? '#5199FF' : '#2172E5'} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%' }}>
            <ThemedText.DeprecatedBody textAlign="left" color="blue2" ml="4px">
              <Trans>
                If you have staked JAMON, when selling the cave the ownership of the tokens will also be transferred
              </Trans>
              .
            </ThemedText.DeprecatedBody>
            <ThemedText.DeprecatedBody textAlign="left" color="blue2" ml="4px" mt="4px">
              <Trans>The NFT Vip once the sale is made, will be free again</Trans>.
            </ThemedText.DeprecatedBody>
          </div>
        </ModalBody>
        <StyleDivInput>
          <StyledInput
            type="number"
            step={0.0}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder={t`Enter USDC Amount`}
            value={price}
            onUserInput={(val) => {
              setprice(val)
            }}
          />
        </StyleDivInput>
        <ButtonPrimary mt="1rem" disabled={!isValid || confirming} onClick={handleSell}>
          {confirming ? (
            <>
              <Trans>Confirming</Trans>
              <CustomLightSpinner
                src="/images/blue-loader.svg"
                alt="loader"
                size={'18px'}
                style={{ marginLeft: '0.5em', textDecoration: 'bold' }}
              />
            </>
          ) : (
            <Trans>Confirm</Trans>
          )}
        </ButtonPrimary>
      </ModalWrapper>
    </Modal>
  )
}
