import ERC721_ABI from 'abis/GreenBlockCave.json'
import BigNumber from 'bignumber.js'
import { NFT_CAVE_ADDRESS } from 'constants/addresses'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import multicall from 'utils/multicall'

import { CaveAttributes } from '../types'
import { useWeb3React } from '@web3-react/core'

/**
 * Gets all public data of Sales contract
 */
const useCaveAttributes = (chainId: number, ids: number[]): CaveAttributes[] => {
  const { account } = useWeb3React()
  const caveAddress = NFT_CAVE_ADDRESS[chainId]
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState<CaveAttributes[]>([])

  const fetchData = useCallback(async () => {
    const idCalls = ids.map((index) => {
      return {
        address: caveAddress,
        name: 'attributesOf',
        params: [index],
      }
    })

    const attributesOf = await multicall(chainId, ERC721_ABI, idCalls)
    const attributes = ids.map((id, index) => {
      const power = new BigNumber(attributesOf[index][0].toString())
      const expiration = Number(attributesOf[index][1])
      return {
        id,
        power,
        expiration,
      }
    })

    setState(attributes)
  }, [caveAddress, chainId, ids])

  useEffect(() => {
    if (ids.length > 0) {
      fetchData()
    }
  }, [account, fetchData, ids.length, slowRefresh])

  return state
}

export default useCaveAttributes
