import React, { useEffect, useState } from 'react'
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts'

import { GbcSupplyData } from '../types'

const COLORS = ['#056150', '#005F73', '#0a9396', '#94d2bd']

const renderCustomizedLabel = ({ name }: { name: string }) => {
  return name
}

export type ChartProps = {
  supplyData: GbcSupplyData
} & React.HTMLAttributes<HTMLDivElement>

const Charts = ({ supplyData }: ChartProps) => {
  const [isInitialRender, setIsInitialRender] = useState(false)
  const [data, setData] = useState([
    { name: 'Liquidity', value: 0 },
    { name: 'Staked', value: 0 },
    { name: 'Holders', value: 0 },
  ])

  useEffect(() => {
    const { totalSupply, liquidity, staked, other } = supplyData
    const liquidityPercent = liquidity.div(totalSupply).times(100)
    const stakedPercent = staked.div(totalSupply).times(100)
    const otherPercent = other.div(totalSupply).times(100)
    if (totalSupply.gt(0)) {
      setIsInitialRender(true)
      setData([
        { name: 'Liquidity', value: liquidityPercent.toNumber() },
        { name: 'Staked', value: stakedPercent.toNumber() },
        { name: 'Holders', value: otherPercent.toNumber() },
      ])
    }
  }, [supplyData])

  if (!isInitialRender) return null
  return (
    <PieChart width={350} height={300}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        innerRadius={50}
        outerRadius={80}
        fill="#82ca9d"
        label={renderCustomizedLabel}
      >
        {data.map((entry, index) => (
          <Cell key="cell2" fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Legend />
      <Tooltip />
    </PieChart>
  )
}

export default Charts
