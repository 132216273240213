// eslint-disable-next-line no-restricted-imports
import { Trans } from "@lingui/macro";
import { ADDRESS_ZERO } from "@uniswap/v3-sdk";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import Badge from "components/Badge";
import { ButtonEmpty, ButtonError, ButtonPrimary } from "components/Button";
import { DarkCard, LightCard } from "components/Card";
import { AutoColumn } from "components/Column";
import { RowBetween, RowFixed } from "components/Row";
import { SwitchLocaleLink } from "components/SwitchLocaleLink";
import Tooltip from "components/Tooltip";
import { SUBGRAPH_URL } from "constants/subgraph";
import request, { gql } from "graphql-request";
import useBTCPrice from "hooks/useBTCPrice";
import { useCavePoolContract } from "hooks/useContract";
import useRefresh from "hooks/useRefresh";
import React, { useCallback, useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { Clock, HelpCircle, Info } from "react-feather";
import { RibbonContainer } from "react-ribbons";
import { Link, useParams /* , useHistory */ } from "react-router-dom";
import styled from "styled-components/macro";
import { CustomLightSpinner, ThemedText } from "theme";
import { shortenAddress } from "utils";
import { getBalanceNumber, getFullDisplayBalance } from "utils/formatBalance";
import { isMobile } from "utils/userAgent";

import useCaveInfo from "../../hooks/useCaveInfo";
import AddBonusJamon from "../Actions/AddBonusJamon";
import AddBonusNFT from "../Actions/AddBonusNFT";
import AddMinerModal from "../Actions/AddMinerModal";
import BuyCaveModal from "../Actions/BuyCaveModal";
import SellCave from "../Actions/SellCave";

const PageWrapper = styled.div`
  min-width: 800px;
  max-width: 960px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    min-width: 680px;
    max-width: 680px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    min-width: 600px;
    max-width: 600px;
  `};

  @media only screen and (max-width: 620px) {
    min-width: 500px;
    max-width: 500px;
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    min-width: 340px;
    max-width: 340px;
  `};
`;

const BadgeText = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

// responsive text
// disable the warning because we don't use the end prop, we just want to filter it out
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Label = styled(({ end, ...props }) => (
  <ThemedText.DeprecatedLabel {...props} />
))<{ end?: boolean }>`
  display: flex;
  font-size: 16px;
  justify-content: ${({ end }) => (end ? "flex-end" : "flex-start")};
  align-items: center;
`;

const HoverText = styled(ThemedText.DeprecatedMain)`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  :hover {
    color: ${({ theme }) => theme.deprecated_text4};
    text-decoration: none;
  }
`;

const ResponsiveRow = styled(RowBetween)`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    width: 100%:
  `};
`;

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  border-radius: 12px;
  padding: 6px 8px;
  width: fit-content;
`;

const ResponsiveGridHeader = styled.div<{ isMobile?: boolean }>`
  display: grid;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr 1fr"};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ResponsiveGridRows = styled.div<{ isMobile?: boolean }>`
  display: grid;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr 1fr"};
  border: 1px solid ${({ theme }) => theme.deprecated_bg3};
  border-radius: 16px;
  background-color: ${({ theme }) => theme.deprecated_bg1};
`;

const BodyMessageInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_blue4};
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 16px;
`;

const StyledRibbonContainer = styled(RibbonContainer)`
  margin-right: 1rem;
  height: 100%;
  width: 100%;
`;

interface MinersRow {
  id: string;
  owner: string;
  stakedPower: string;
  expiration: string;
}

const renderer = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number
) => (
  <span>
    <Trans>Withdrawable in</Trans> {zeroPad(days)}:{zeroPad(hours)}:
    {zeroPad(minutes)}:{zeroPad(seconds)}
  </span>
);

const currentDate = () => new Date();

export default function CaveManage() {
  const { id } = useParams<{ id?: string }>();
  const { account, chainId, connector } = useWeb3React();
  /* const history = useHistory() */
  const darkmode = Boolean(window.matchMedia("(prefers-color-scheme: dark)"));
  const { slowRefresh } = useRefresh();
  const [showTooltip, setShowTooltip] = useState<number>(0);
  const [openModal, setOpenModal] = useState<number>(0);
  const [claiming, setClaiming] = useState(false);
  const [unStaking, setUnStaking] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const [minersCave, setMinersCave] = useState<MinersRow[]>([]);
  /* const [unstaking, setUnstaking] = useState(false) */
  const activeChain = chainId ? chainId : 137;
  const apiUrl = SUBGRAPH_URL[activeChain];

  const cavePoolContract = useCavePoolContract();
  const caveData = useCaveInfo(activeChain, Number(id));

  const {
    owner,
    expiration,
    stakedPower,
    jamonStaked,
    jamonUnlockDate,
    hasVipStaked,
    vipId,
    miners,
    onSale,
    salePrice,
    pendingRewards,
    loading,
    fetchCaveData,
  } = caveData;

  const btcPrice = useBTCPrice();

  const handleOpenTooltip = (n: number) => {
    setShowTooltip(n);
  };

  const handleOpenModal = (n: number) => {
    setOpenModal(n);
  };

  useEffect(() => {
    request(
      apiUrl,
      gql`
        query GetMiners($id: String!) {
          miners(where: { cave: $id }) {
            id
            owner
            stakedPower
            expiration
          }
        }
      `,
      {
        id,
      }
    )
      .then(({ miners }) => {
        if (miners.length > 0) {
          setMinersCave(miners);
        }
      })
      .catch(({ error }) => {
        console.log(error);
      });
  }, [miners, apiUrl, id, slowRefresh]);

  const handleWithdrawn = useCallback(async () => {
    try {
      setClaiming(true);
      const tx = await cavePoolContract?.harvest(id?.toString() ?? 9999999);
      await tx?.wait();
      caveData.fetchCaveData();
      setClaiming(false);
    } catch (e) {
      setClaiming(false);
      console.error(e);
    } finally {
      setClaiming(false);
    }
  }, [caveData, cavePoolContract, id]);

  const handleUnStakeJamon = useCallback(async () => {
    try {
      setUnStaking(true);
      const tx = await cavePoolContract?.unStakeJamon(
        id?.toString() ?? 9999999
      );
      await tx?.wait();
      caveData.fetchCaveData();
      setUnStaking(false);
    } catch (e) {
      setUnStaking(false);
      console.error(e);
    } finally {
      setUnStaking(false);
    }
  }, [caveData, cavePoolContract, id]);

  const handleCancelSale = useCallback(async () => {
    try {
      setCanceling(true);
      const tx = await cavePoolContract?.sellCave(id ?? 0, 0);
      await tx?.wait();
      setCanceling(false);
      caveData.fetchCaveData();
    } catch (e) {
      setCanceling(false);
      console.error(e);
    } finally {
      setCanceling(false);
    }
  }, [caveData, cavePoolContract, id]);

  // const handleUnStake = useCallback(async () => {
  // try {
  //     setUnstaking(true)
  //     const tx = await cavePoolContract?.unStakeCave(id ?? 0)
  //     await tx?.wait()
  //     setUnstaking(false)
  //     history.push('/mine')
  // } catch (e) {
  //     setUnstaking(false)
  //     console.error(e)
  // } finally {
  //     setUnstaking(false)
  // }
  // }, [cavePoolContract, id])

  const dateFished = new Date(expiration * 1000);
  const isOwner = owner === account;
  const isJamonStaked = jamonStaked.gt(0);
  const isStakedBonus = isJamonStaked || hasVipStaked;
  const rewardsUSD = getBalanceNumber(pendingRewards, 8) * btcPrice;
  const jamonFinish = new Date(jamonUnlockDate * 1000);
  const canClaimJamon = jamonFinish < new Date();

  const totalBonus = miners + (isJamonStaked ? 1.5 : hasVipStaked ? 1.5 : 0);

  /* const canUnStakes = isOwner && !isJamonStaked && minersCave.length === 0 */

  if (loading) {
    return (
      <>
        <PageWrapper>
          <AutoColumn gap="md">
            <Link
              style={{
                textDecoration: "none",
                width: "fit-content",
                marginBottom: "0.5rem",
              }}
              to="/mine"
            >
              <HoverText>
                ← <Trans>Back to mine</Trans>
              </HoverText>
            </Link>
            <Trans>Loading</Trans>...
          </AutoColumn>
        </PageWrapper>
        <SwitchLocaleLink />
      </>
    );
  }
  if (owner === ADDRESS_ZERO) {
    return (
      <>
        <PageWrapper>
          <AutoColumn gap="md">
            <Link
              style={{
                textDecoration: "none",
                width: "fit-content",
                marginBottom: "0.5rem",
              }}
              to="/mine"
            >
              <HoverText>
                ← <Trans>Back to mine</Trans>
              </HoverText>
            </Link>
            <Trans>Invalid Cave</Trans>
          </AutoColumn>
        </PageWrapper>
        <SwitchLocaleLink />
      </>
    );
  }
  return (
    <>
      <PageWrapper>
        <AutoColumn gap="md">
          <AutoColumn gap="sm">
            <Link
              style={{
                textDecoration: "none",
                width: "fit-content",
                marginBottom: "0.5rem",
              }}
              to="/mine"
            >
              <HoverText>
                ← <Trans>Back to mine</Trans>
              </HoverText>
            </Link>
            <ResponsiveRow>
              <RowFixed>
                <ThemedText.LargeHeader mr={2}>
                  <Trans>Cave</Trans>
                </ThemedText.LargeHeader>
                <Badge style={{ marginRight: "8px" }}>
                  <BadgeText>{id}</BadgeText>
                </Badge>
              </RowFixed>
              <RowFixed>
                <Clock />
                <ThemedText.LargeHeader ml="0.5rem">
                  <Countdown date={dateFished}>
                    <Trans>Expired</Trans>
                  </Countdown>
                </ThemedText.LargeHeader>
              </RowFixed>
            </ResponsiveRow>
          </AutoColumn>
          <ResponsiveRow align="flex-start">
            <StyledRibbonContainer>
              <DarkCard
                width="100%"
                height="100%"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginRight: "12px",
                }}
              >
                <div style={{ marginRight: 12, marginBottom: "0.5rem" }}>
                  <img
                    src="/images/nfts/cave-nft.png"
                    width={290}
                    alt="cave-nft"
                    style={{ borderRadius: "10px" }}
                  />
                </div>
                {isOwner ? (
                  onSale ? (
                    <>
                      <div style={{ marginTop: 12, marginBottom: 12 }}>
                        <Trans>Price</Trans>:{" "}
                        {getFullDisplayBalance(salePrice, 6, 2)} USDC
                      </div>
                      <ButtonError
                        error
                        onClick={handleCancelSale}
                        disabled={canceling}
                      >
                        {canceling ? (
                          <>
                            <Trans>Canceling</Trans>
                            <CustomLightSpinner
                              src="/images/blue-loader.svg"
                              alt="loader"
                              size="18px"
                              style={{
                                marginLeft: "0.5em",
                                textDecoration: "bold",
                              }}
                            />
                          </>
                        ) : (
                          <Trans>Cancel sale</Trans>
                        )}
                      </ButtonError>
                    </>
                  ) : (
                    <ButtonPrimary
                      onClick={() => handleOpenModal(1)}
                      disabled={dateFished < currentDate()}
                      mb="0.5rem"
                    >
                      <Trans>Sell Cave</Trans>
                    </ButtonPrimary>
                  )
                ) : (
                  ""
                )}
                {!isOwner && onSale && (
                  <>
                    <div style={{ marginTop: 12, marginBottom: 12 }}>
                      <Trans>Price</Trans>:{" "}
                      {getFullDisplayBalance(salePrice, 6, 2)} USDC
                    </div>
                    <ButtonPrimary
                      onClick={() => handleOpenModal(5)}
                      disabled={dateFished < currentDate()}
                    >
                      <Trans>Buy Cave</Trans>
                    </ButtonPrimary>
                  </>
                )}
              </DarkCard>
              {/* {onSale && (
                <LeftLargeRibbon backgroundColor={darkmode ? '#2172E5' : '#25B728'} color="#FFFFFF" fontFamily="Poppins">
                  <Trans>On Sale</Trans>
                </LeftLargeRibbon>
              )} */}
            </StyledRibbonContainer>
            <AutoColumn gap="sm" style={{ width: "100%", height: "100%" }}>
              <DarkCard>
                <AutoColumn gap="md" style={{ width: "100%" }}>
                  <RowBetween style={{ alignItems: "flex-start" }}>
                    <AutoColumn gap="md">
                      <Label>
                        <Trans>Power</Trans>
                      </Label>
                    </AutoColumn>
                    <AutoColumn gap="md">
                      <Label>
                        {getFullDisplayBalance(stakedPower, 6, 2)} MHs
                      </Label>
                    </AutoColumn>
                  </RowBetween>
                  <LightCard padding="12px 16px">
                    <AutoColumn gap="md">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ThemedText.BodyPrimary>
                          <Trans>Rewards</Trans>
                        </ThemedText.BodyPrimary>
                      </div>
                    </AutoColumn>
                    <AutoColumn gap="md">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <ThemedText.BodyPrimary>
                          {getFullDisplayBalance(pendingRewards, 8, 6)} WBTC
                        </ThemedText.BodyPrimary>
                        <img
                          src="/images/tokens/polygon-ico.png"
                          alt=""
                          width="20px"
                          style={{ marginLeft: "5px" }}
                        />
                      </div>
                    </AutoColumn>
                    <AutoColumn gap="md">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <ThemedText.DeprecatedItalic>
                          {rewardsUSD.toFixed(2)} USD
                        </ThemedText.DeprecatedItalic>
                      </div>
                    </AutoColumn>
                  </LightCard>
                  {pendingRewards.gt(0) && (
                    <ButtonPrimary
                      mt="1rem"
                      disabled={!isOwner || pendingRewards.eq(0) || claiming}
                      onClick={handleWithdrawn}
                    >
                      {claiming ? (
                        <>
                          <Trans>Withdrawing</Trans>
                          <CustomLightSpinner
                            src="/images/blue-loader.svg"
                            alt="loader"
                            size="24px"
                            style={{ marginLeft: "0.5rem" }}
                          />
                        </>
                      ) : (
                        <Trans>Withdraw</Trans>
                      )}
                    </ButtonPrimary>
                  )}
                  {/* {isOwner && (
                    <ButtonError error mt="1rem" disabled={minersCave.length > 0 || unstaking} onClick={handleUnStake}>
                      {unstaking ? (
                        <>
                          <Trans>Removing</Trans>
                          <CustomLightSpinner
                            src="/images/blue-loader.svg"
                            alt="loader"
                            size={'24px'}
                            style={{ marginLeft: '0.5rem' }}
                          />
                        </>
                      ) : (
                        <Trans>Remove stake</Trans>
                      )}
                    </ButtonError>
                  )} */}
                </AutoColumn>
              </DarkCard>
              <DarkCard>
                <AutoColumn gap="md" style={{ width: "100%" }}>
                  <AutoColumn gap="md">
                    <RowBetween style={{ alignItems: "flex-start" }}>
                      <AutoColumn gap="md">
                        <Label>
                          <Trans>Bonus</Trans>
                        </Label>
                      </AutoColumn>
                      <AutoColumn gap="md">
                        <Label>{totalBonus}%</Label>
                      </AutoColumn>
                    </RowBetween>
                  </AutoColumn>
                  <LightCard padding="12px 16px">
                    <AutoColumn gap="md">
                      <RowBetween>
                        <RowFixed>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "4.5rem",
                              margin: "auto",
                            }}
                          >
                            <ThemedText.DeprecatedMain>
                              <Trans>Miners</Trans>
                            </ThemedText.DeprecatedMain>
                          </div>
                          <Tooltip
                            text={
                              <ThemedText.DeprecatedBody textAlign="center">
                                <Trans>Amount miners working</Trans>
                              </ThemedText.DeprecatedBody>
                            }
                            show={showTooltip === 1}
                          >
                            <HelpCircle
                              onClick={() => handleOpenTooltip(1)}
                              onMouseEnter={() => handleOpenTooltip(1)}
                              onMouseLeave={() => handleOpenTooltip(0)}
                              size={20}
                              color={darkmode ? "#C3C5CB" : "#565A69"}
                              style={{ marginTop: "4px" }}
                            />
                          </Tooltip>
                        </RowFixed>
                        <RowFixed>
                          <ThemedText.DeprecatedMain>
                            {miners}%
                          </ThemedText.DeprecatedMain>
                        </RowFixed>
                      </RowBetween>
                      <RowBetween>
                        <RowFixed>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "4.5rem",
                              margin: "auto",
                            }}
                          >
                            <ThemedText.DeprecatedMain>
                              JAMON
                            </ThemedText.DeprecatedMain>
                          </div>
                          <Tooltip
                            text={
                              <ThemedText.DeprecatedBody textAlign="center">
                                <Trans>Staked JAMON</Trans>
                              </ThemedText.DeprecatedBody>
                            }
                            show={showTooltip === 2}
                          >
                            <HelpCircle
                              onClick={() => handleOpenTooltip(2)}
                              onMouseEnter={() => handleOpenTooltip(2)}
                              onMouseLeave={() => handleOpenTooltip(0)}
                              color={darkmode ? "#C3C5CB" : "#565A69"}
                              size={20}
                              style={{ marginTop: "4px" }}
                            />
                          </Tooltip>
                        </RowFixed>
                        <RowFixed>
                          <ThemedText.DeprecatedMain>
                            {isJamonStaked ? "1.5" : "0"}%
                          </ThemedText.DeprecatedMain>
                        </RowFixed>
                      </RowBetween>
                      <RowBetween>
                        <RowFixed>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "4.5rem",
                              margin: "auto",
                            }}
                          >
                            <ThemedText.DeprecatedMain>
                              NFT Vip
                            </ThemedText.DeprecatedMain>
                          </div>
                          <Tooltip
                            text={
                              <ThemedText.DeprecatedBody textAlign="center">
                                <Trans>Deposited NFT Vip of JAMON</Trans>
                              </ThemedText.DeprecatedBody>
                            }
                            show={showTooltip === 3}
                          >
                            <HelpCircle
                              onClick={() => handleOpenTooltip(3)}
                              onMouseEnter={() => handleOpenTooltip(3)}
                              onMouseLeave={() => handleOpenTooltip(0)}
                              color={darkmode ? "#C3C5CB" : "#565A69"}
                              size={20}
                              style={{ marginTop: "4px" }}
                            />
                          </Tooltip>
                        </RowFixed>
                        <RowFixed>
                          <ThemedText.DeprecatedMain>
                            {hasVipStaked ? "1.5" : "0"}%
                          </ThemedText.DeprecatedMain>
                        </RowFixed>
                      </RowBetween>
                    </AutoColumn>
                  </LightCard>
                  {isOwner ? (
                    isStakedBonus ? (
                      isJamonStaked ? (
                        <>
                          <ThemedText.DeprecatedBody
                            textAlign="center"
                            mt="0.5rem"
                          >
                            {canClaimJamon ? (
                              <ButtonPrimary
                                mt="1rem"
                                disabled={!isOwner || unStaking}
                                onClick={handleUnStakeJamon}
                              >
                                {unStaking ? (
                                  <>
                                    <Trans>Confirming</Trans>
                                    <CustomLightSpinner
                                      src="/images/blue-loader.svg"
                                      alt="loader"
                                      size="24px"
                                      style={{ marginLeft: "0.5rem" }}
                                    />
                                  </>
                                ) : (
                                  <Trans>Claim JAMON</Trans>
                                )}
                              </ButtonPrimary>
                            ) : (
                              <Countdown
                                date={jamonFinish}
                                renderer={(props: any) =>
                                  renderer(
                                    props.days,
                                    props.hours,
                                    props.minutes,
                                    props.seconds
                                  )
                                }
                              />
                            )}
                          </ThemedText.DeprecatedBody>
                        </>
                      ) : (
                        <ThemedText.DeprecatedBody
                          textAlign="center"
                          mt="0.5rem"
                        >
                          <Trans>NFT Vip staked with id</Trans>: {vipId}
                        </ThemedText.DeprecatedBody>
                      )
                    ) : (
                      <>
                        <ThemedText.DeprecatedBody
                          textAlign="center"
                          mt="0.5rem"
                        >
                          <Trans>You can only choose one option</Trans>
                        </ThemedText.DeprecatedBody>
                        <ButtonPrimary
                          onClick={() => handleOpenModal(2)}
                          disabled={
                            isStakedBonus ||
                            !isOwner ||
                            dateFished < currentDate()
                          }
                        >
                          <Trans>Add Bonus JAMON</Trans>
                        </ButtonPrimary>
                        <ButtonPrimary
                          onClick={() => handleOpenModal(3)}
                          disabled={
                            isStakedBonus ||
                            !isOwner ||
                            dateFished < currentDate()
                          }
                        >
                          <Trans>Add Bonus NFT Vip</Trans>
                        </ButtonPrimary>
                      </>
                    )
                  ) : (
                    ""
                  )}
                </AutoColumn>
              </DarkCard>
            </AutoColumn>
          </ResponsiveRow>
          <DarkCard>
            <AutoColumn gap="md">
              <RowBetween style={{ alignItems: "flex-start" }}>
                <AutoColumn gap="md">
                  <Label>
                    <Trans>Miners</Trans> {miners}/5
                  </Label>
                </AutoColumn>
                <AutoColumn gap="md">
                  <ResponsiveButtonPrimary
                    disabled={
                      minersCave.length === 5 || dateFished < currentDate()
                    }
                    width="fit-content"
                    padding="6px 8px"
                    $borderRadius="12px"
                    onClick={() => handleOpenModal(4)}
                  >
                    + <Trans>Add Miner</Trans>
                  </ResponsiveButtonPrimary>
                </AutoColumn>
              </RowBetween>
              {minersCave && minersCave.length > 0 ? (
                <>
                  <ResponsiveGridHeader isMobile={isMobile}>
                    <ThemedText.DeprecatedBody textAlign="center">
                      <Trans>ID</Trans>
                    </ThemedText.DeprecatedBody>
                    {!isMobile && (
                      <ThemedText.DeprecatedBody textAlign="center">
                        <Trans>Owner</Trans>
                      </ThemedText.DeprecatedBody>
                    )}
                    <ThemedText.DeprecatedBody textAlign="center">
                      <Trans>Power</Trans>
                    </ThemedText.DeprecatedBody>
                    {!isMobile && (
                      <ThemedText.DeprecatedBody textAlign="center">
                        <Trans>Expiration</Trans>
                      </ThemedText.DeprecatedBody>
                    )}
                  </ResponsiveGridHeader>
                  {minersCave.map((item, index) => {
                    const dateExpiration = new Date(
                      Number(item.expiration) * 1000
                    );
                    return (
                      <React.Fragment key={index}>
                        <ResponsiveGridRows isMobile={isMobile}>
                          <ThemedText.DeprecatedBody textAlign="center">
                            {item.id}
                          </ThemedText.DeprecatedBody>
                          {!isMobile && (
                            <ThemedText.DeprecatedBody textAlign="center">
                              {shortenAddress(item.owner)}
                            </ThemedText.DeprecatedBody>
                          )}
                          <ThemedText.DeprecatedBody textAlign="center">
                            {getFullDisplayBalance(
                              new BigNumber(item.stakedPower),
                              6,
                              2
                            )}{" "}
                            MHs
                          </ThemedText.DeprecatedBody>
                          {!isMobile && (
                            <ThemedText.DeprecatedBody textAlign="center">
                              {dateExpiration.toLocaleDateString()}
                            </ThemedText.DeprecatedBody>
                          )}
                          <ButtonEmpty
                            as={Link}
                            to={`/manage-miner/${item.id}`}
                            width="100%"
                          >
                            <Trans>Manage</Trans>
                          </ButtonEmpty>
                        </ResponsiveGridRows>
                        {/* <MinerManage
                        id={item.id}
                        owner={item.owner}
                        power={item.power}
                        apr={item.apr}
                        isOpen={openModal === 6}
                        onDismiss={() => setOpenModal(0)}
                      /> */}
                      </React.Fragment>
                    );
                  })}
                </>
              ) : (
                <BodyMessageInfo>
                  <Info color={darkmode ? "#5199FF" : "#2172E5"} />
                  <ThemedText.DeprecatedBlue textAlign="center" ml="0.5rem">
                    <Trans>This cave is empty</Trans>
                  </ThemedText.DeprecatedBlue>
                </BodyMessageInfo>
              )}
            </AutoColumn>
          </DarkCard>
        </AutoColumn>
        <SellCave
          isOpen={openModal === 1}
          onDismiss={() => setOpenModal(0)}
          chainId={activeChain}
          caveId={Number(id) ?? 9999999}
          update={fetchCaveData}
        />
        <AddBonusJamon
          isOpen={openModal === 2}
          onDismiss={() => setOpenModal(0)}
          chainId={activeChain}
          caveId={Number(id) ?? 9999999}
          update={fetchCaveData}
        />
        <AddBonusNFT
          isOpen={openModal === 3}
          onDismiss={() => setOpenModal(0)}
          chainId={activeChain}
          caveId={Number(id) ?? 9999999}
          update={fetchCaveData}
        />
        <AddMinerModal
          isOpen={openModal === 4}
          onDismiss={() => setOpenModal(0)}
          chainId={activeChain}
          caveId={Number(id) ?? 9999999}
          update={fetchCaveData}
        />
        <BuyCaveModal
          isOpen={openModal === 5}
          onDismiss={() => setOpenModal(0)}
          chainId={activeChain}
          price={salePrice}
          caveId={Number(id) ?? 9999999}
          update={fetchCaveData}
        />
      </PageWrapper>
      <SwitchLocaleLink />
    </>
  );
}
