import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { Row } from "nft/components/Flex";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
// faHome,
// faBullseye,
// faHandHoldingDollar,
// faLeaf,
// faHandshake,
// faArrowsSpin,
// faServer,
// faCalendarTimes,
// faCalendarXmark
// } from "@fortawesome/free-solid-svg-icons";
import { ReactNode, useState } from "react";
import { stack as StackMenu } from "react-burger-menu";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

const Container = styled.div`
  padding-top: 6rem;
`;

const Column = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const ButtonLink = styled(ThemedText.BodyPrimary)<{ activeColor: boolean }>`
  color: ${({ theme, activeColor }) =>
    activeColor ? theme.primary1 : theme.text1};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.primary1};
  }
`;

interface MenuItemProps {
  href: string;
  id?: NavLinkProps["id"];
  isActive?: boolean;
  children: ReactNode;
  dataTestId?: string;
}

const MenuItem = ({ href, dataTestId, id, children }: MenuItemProps) => {
  return (
    <Column>
      <NavLink
        to={href}
        id={id}
        style={{ textDecoration: "none" }}
        data-testid={dataTestId}
      >
        {children}
      </NavLink>
    </Column>
  );
};

const PrimaryMenuRow = ({
  to,
  href,
  close,
  children,
}: {
  to?: NavLinkProps["to"];
  href?: string;
  close?: () => void;
  children: ReactNode;
}) => {
  return (
    <>
      {to ? (
        <NavLink to={to}>
          <Row onClick={close}>{children}</Row>
        </NavLink>
      ) : (
        <Column>
          <Row
            cursor="pointer"
            as="a"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </Row>
        </Column>
      )}
    </>
  );
};

export default function MenuDropdown() {
  const { pathname } = useLocation();
  const { chainId: connectedChainId } = useWeb3React();
  const styles = {
    bmBurgerButton: {
      position: "relative",
      width: "30px",
      height: "20px",
    },
    bmBurgerBars: {
      background: "#FFF",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
      marginTop: "4px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      top: 0,
      left: 0,
    },
    bmMenu: {
      background:
        connectedChainId === 137
          ? "radial-gradient(circle, rgba(49,5,98,1) 25%, rgba(7,7,28,1) 95%)"
          : "radial-gradient(circle, rgba(32,52,85,1) 25%, rgba(7,7,28,1) 95%)",
      fontSize: "1.15em",
      zIndex: 1,
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "FFFFFF",
    },
    bmOverlay: {
      background: "transparent",
      top: 0,
    },
  };

  const [open, setOpen] = useState(false);
  const handleSetOpen = () => {
    setOpen(true);
  };
  const handleSetClose = () => {
    setOpen(false);
  };

  const [active, setActive] = useState(0);
  const handleSetActive = (n: number) => {
    setActive(n);
  };

  return (
    <StackMenu
      styles={styles}
      isOpen={open}
      onOpen={handleSetOpen}
      onClose={handleSetClose}
    >
      <Container>
        <MenuItem href="/home" isActive={pathname.startsWith("/home")}>
          <ButtonLink
            onClick={() => {
              handleSetActive(0);
              handleSetClose();
            }}
            activeColor={active === 0}
          >
            <Trans>Home</Trans>
          </ButtonLink>
        </MenuItem>
        {connectedChainId === 56 && (
          <MenuItem href="/stake" isActive={pathname.startsWith("/stake")}>
            <ButtonLink
              onClick={() => {
                handleSetActive(1);
                handleSetClose();
              }}
              activeColor={active === 1}
            >
              <Trans>Stake</Trans>
            </ButtonLink>
          </MenuItem>
        )}
        {connectedChainId === 56 && (
          <MenuItem href="/mint" isActive={pathname.startsWith("/mint")}>
            <ButtonLink
              onClick={() => {
                handleSetActive(2);
                handleSetClose();
              }}
              activeColor={active === 2}
            >
              <Trans>Mint</Trans>
            </ButtonLink>
          </MenuItem>
        )}
        {connectedChainId === 56 && (
          <MenuItem
            href="/stakeNFT"
            isActive={pathname.startsWith("/stakeNFT")}
          >
            <ButtonLink
              onClick={() => {
                handleSetActive(3);
                handleSetClose();
              }}
              activeColor={active === 3}
            >
              <Trans>NFT Stake</Trans>
            </ButtonLink>
          </MenuItem>
        )}
        {connectedChainId === 137 && (
          <MenuItem href="/mine" isActive={pathname.startsWith("/mine")}>
            <ButtonLink
              onClick={() => {
                handleSetActive(4);
                handleSetClose();
              }}
              activeColor={active === 4}
            >
              <Trans>Mine</Trans>
            </ButtonLink>
          </MenuItem>
        )}
        {connectedChainId === 137 && (
          <MenuItem
            href="/referrals"
            isActive={pathname.startsWith("/referrals")}
          >
            <ButtonLink
              onClick={() => {
                handleSetActive(5);
                handleSetClose();
              }}
              activeColor={active === 5}
            >
              <Trans>Referrals</Trans>
            </ButtonLink>
          </MenuItem>
        )}
        <PrimaryMenuRow href="https://pancakeswap.finance/swap?outputCurrency=0xb8d5CC1462bf5a28366d58FfD4d40d5d26030F3F">
          <ButtonLink activeColor={false} onClick={() => handleSetClose()}>
            <Trans>Buy GBC</Trans>
          </ButtonLink>
        </PrimaryMenuRow>
        <PrimaryMenuRow href="https://greenblockcapital.io">
          <ButtonLink activeColor={false} onClick={() => handleSetClose()}>
            <Trans>Web</Trans>
          </ButtonLink>
        </PrimaryMenuRow>
        <PrimaryMenuRow href="https://telegram.me/GreenBlock">
          <ButtonLink activeColor={false} onClick={() => handleSetClose()}>
            <Trans>Telegram</Trans>
          </ButtonLink>
        </PrimaryMenuRow>
        <PrimaryMenuRow href="https://twitter.com/Green_BlockC">
          <ButtonLink activeColor={false} onClick={() => handleSetClose()}>
            <Trans>Twitter</Trans>
          </ButtonLink>
        </PrimaryMenuRow>
      </Container>
    </StackMenu>
  );
}
