export const currentCaveSalePack = 1
export const currentMinerSalePack = 1

export const saleStart = 1663264800000 // timestamp with miliseconds
export const whitelistEnd = saleStart + 172800000 // + 2 Days in miliseconds

export const whitelist = [
  '0xDC7B139e4e24aF55cC0F1124493460E57Be855Ab', // JM
  '0xcD6ec3574E0345184d2cD78846BDC81d246Eb633', // Tico
  '0x2a06BD881Ee3eb291Bfe42B50a0c66aF8A2a0E7E', // Nacho
  '0x097C0963F75Fb30c07a3a7Df6905095C2B29935c', // Paul
  '0x3a359239fAd0eEE4f5d410258A1cd9Eb0Ead72c7',
  '0x72A315C8eE1ef74344B97db9Ea3a4e63732FeD33',
  '0x11cded46d8ad384537b7d036a0c3c3e0cf7e41df',
  '0x3dec937e496157247887748ef65491568b832682',
  '0x7ca0a8a0eedb02947bea0489fd64bd51f1adafd0',
  '0x352e4ac684bc28924facae59b958c6693a5075c5',
  '0x7b20cbc8b483e2cdb9c3e41e98b22365c5884793',
  '0x087a6938b0a9da76f43ea9ab17818168d7902622',
  '0x6a54d8a0084326819b0341a6cf5e93f216e105b3',
  '0xc83c7ed528a8c20d76db3379d6e79587c9e190d3',
  '0xf82637742a34b6f5aba9980013ee56b7617eeb1e',
  '0xc990d1c44119501dcef3315f013b3b1764389a4b',
  '0xd79f40c4551e7b4850c635ef1c46e9a4aba166d4',
  '0x769da5facb912a407d5448a647a2b8616a30fa66',
  '0x54ad44c0fd98c229fdd72199afd36859731646dc',
  '0x4833eb8085c3487e7e053135646239153ab2deb0',
  '0xd676b7cd8c427746eb18e827fc1f5bedb7616a55',
  '0xc19964c0694a70f5d2d173556fd5a38dbdcda2f2',
  '0x47f167ee1c7d5877402d91aea7b27043c65ab74c',
  '0x85c1b84f5e72530bb49f65c46f3c19c6a24d9ed0',
  '0x1381f7267fa11cdd1c88290b6f069a59a2e89f89',
  '0xfa673e1a91e33cf302534a6e5df0206e38abf02d',
  '0x51fd8d9cdc9b0f9f280f7d405df0a63df2fed72b',
  '0xe84ef434a6869fd71978f817a33ed09cc30a8bff',
  '0x257e6109ea1f1f9d528fc066297cb25b196331db',
  '0x31822bd6393ddd7729688f8309c3327d31a36746',
  '0xc697ce3e3a054198e8dd455d50b16e0748d6b73f',
  '0xe989efd8f2222b1edfbee1f62e10e218b3fcc456',
  '0x96acd3fd7a5b06745ae92029f50ef0d2f96b3219',
  '0x3aabc83c57a164dfecf5aca9eccfac2c4b8b8169',
  '0x01741d58a97cb300787e0627fdeb92fe8cc41a0b',
  '0x83a045d3eb5ebf0826ad5863bc9b30394e8dfa6e',
  '0x130551e3d1dea8b9201ad66c6f5cae919998d44c',
  '0x53b24062347ca0f3bab0c311522a5ec2584397a9',
  '0x729961bc48dd8f3e88f12e431387bd5796eaef57',
  '0x8aded8658b7361982110054ac06e54f590dd75a5',
  '0xb93c7e668f74352066e297e4e3184d088da22b01',
  '0x6bf6526886b88b21edd598e3ff81e0e947f44f2d',
  '0xed2da41c4ab569fe896d1cd9c2ba8e880a82c3b8',
  '0x22dbd5fa227134f3b1f049d8bb1cea46d71b2174',
  '0x57e417c1eba61a784746262e79741e09400481f3',
  '0x140adb09cd495c97dcc37425de55c05b74b9690e',
  '0x483dda8a8bea57f70f8fa7f40ef7cbcae1dd4521',
  '0x834399eefd3fdb7ddb465de3d961b3a1194bf8dd',
  '0xd42e58e52c3b56c822b5d8e005581acbcffdb534',
  '0xb41136904391719b0f17e2d281449d3f62139cf7',
  '0x90daca4c5e666be6eb2b52a8e883ce3ced947508',
  '0x4e13a681ea9e77546f7c162e774ab159e1a2fd37',
  '0x2b15d65afa0262e3b51de3787c735eb0bdcbc610',
  '0xf64f9aaf5dd8d0dd8155f76b56f014b63da9072d',
  '0xa017015572151d9ea7dd49b4d981994240e6812b',
  '0x39d76b14b331af9a9caffded933bcd9c2b20e20f',
  '0xa8c0fe19707d2e4e8950ab2cf048c8b98a44baae',
  '0xf3df4104477bebf98e1eb7e20b30292fc17b89a3',
  '0x04026e5db5970a3e45422da9a64262c46a6aced4',
  '0xde5e9ade0461440eec0a3ca104a13d093046384b',
  '0xadc329b6971c91d157df0ea7db987b34c6093b75',
  '0xa96900d752a28c619416fd6251a4bba0963cfbe8',
  '0x50c5bd51a1022d4591818d9ecfa098a09d36bc83',
  '0xf5eaf569d15ebb97667f0304a57a949b763c3aa2',
  '0xdd49df93f4072b99065b8f0a7d9d6a30ca8baca8',
  '0x325dc20a636f0fa649385214970a563772368912',
  '0x639d3d212a01aa15947e402a4e803146d9165500',
  '0xc8bc3a3821ec2b209a0276be1ad559fa7b869afa',
  '0x890b88f7ef2a40f58dee284c7407021bfc6804fa',
  '0xf77be9fad4b3cfb20d08754acd123284901421da',
  '0xd434cAE9C972BE713d7E98cF5967d8601e985e22',
  '0x60E8FBC9c0aEd825bc2c8f2e65947eFE29B0Cf47',
]
