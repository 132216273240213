import POOL_ABI from 'abis/GreenBlockNFTPool.json'
import BigNumber from 'bignumber.js'
import { NFT_POOL_ADDRESS } from 'constants/addresses'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import { BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'

import { WalletPoolData } from '../types'
import { useWeb3React } from '@web3-react/core'

/**
 * Gets all wallet data of token contract
 */
const useWalletPoolData = (chainId: number): WalletPoolData => {
  const { account } = useWeb3React()
  const poolAddress = NFT_POOL_ADDRESS[chainId]
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    isInPool: false,
    stakedBal: 0,
    startTime: 0,
    pendingBal: BIG_ZERO,
    ids: []
  })

  const fetchWalletPoolData = useCallback(async () => {
    const userCalls = [
      {
        address: poolAddress,
        name: 'isInPool',
        params: [account],
      },
      {
        address: poolAddress,
        name: 'getWalletInfo',
        params: [account],
      },
    ]

    const [isInPool, walletInfo] = await multicall(chainId, POOL_ABI, userCalls)

    const ids = walletInfo[3].map((id: any) => Number(id.toString()))

    setState((prev) => ({
      ...prev,
      isInPool: Boolean(isInPool[0]),
      stakedBal: Number(walletInfo[0]),
      startTime: Number(walletInfo[1]),
      pendingBal: new BigNumber(walletInfo[2].toString()),
      ids: ids,
    }))
  }, [account, chainId, poolAddress])

  useEffect(() => {
    if (account) {
      fetchWalletPoolData()
    }
  }, [account, fetchWalletPoolData, slowRefresh])

  return { ...state, fetchWalletPoolData }
}

export default useWalletPoolData
