import { Trans } from "@lingui/macro";
import Rewards from "assets/images/home/rewards.png";
/* import Slide, { Roll } from "react-reveal"; */
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { isMobile } from "utils/userAgent";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    flex-direction: column;
    gap: 3rem;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    gap: 3rem;
  `};
`;

export function Header() {
  return (
    <Container>
      <ThemedText.TitlePage
        color="text1"
        textAlign={isMobile ? "center" : "left"}
      >
        <Trans>Welcome to the</Trans>
        <ThemedText.TitlePage
          color="primary1"
          textAlign={isMobile ? "center" : "left"}
        >
          <Trans>Greenblock-Capital</Trans>
        </ThemedText.TitlePage>
        App
      </ThemedText.TitlePage>
      <img
        src={Rewards}
        alt="rewards-img"
        width={isMobile ? 172 : 268}
        height={isMobile ? 207 : 343}
      />
    </Container>
  );
}
