import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import JamonIcon from "assets/images/tokens/jamon-ico.png";
import UsdcIcon from "assets/images/tokens/usdc-ico.png";
import BigNumber from "bignumber.js";
import { BlueCard } from "components/Card";
import React, { useMemo, useState } from "react";
import { ExternalLink as LinkIcon } from "react-feather";
import { Text } from "rebass";
import styled from "styled-components/macro";
import { ExternalLink, ThemedText } from "theme";
import { getFullDisplayBalance } from "utils/formatBalance";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";
import { isMobile } from "utils/userAgent";

const TruncatedText = styled(Text)`
  text-overflow: ellipsis;
  max-width: 220px;
  overflow: hidden;
  text-align: center;
`;

const TableWrapper = styled.div`
  flex-direction: column;
`;

const ResponsiveGrid = styled.div`
  display: grid;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 1rem;
`;

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

const Arrow = styled.div`
  color: ${({ theme }) => theme.deprecated_text4};
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`;

const AddressLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.deprecated_text4};
  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.deprecated_secondary2};
  }

  :focus {
    outline: none;
    text-decoration: none;
  }

  :active {
    text-decoration: none;
  }
`;

interface Row {
  sales?: [
    {
      id: string;
      amount: string;
      referralEarnings: string;
      inJamon: boolean;
    }
  ];
}

export default function ReferralsHistory({ sales }: Row) {
  const { account, chainId } = useWeb3React();
  const darkmode = Boolean(window.matchMedia("(prefers-color-scheme: dark)"));

  const [page, setPage] = useState(1);
  const ITEMS = isMobile ? 5 : 10;

  const sortedHistory = useMemo(() => {
    if (sales) {
      return sales.length > 0
        ? sales.slice(ITEMS * (page - 1), page * ITEMS)
        : [];
    }
    return;
  }, [sales, ITEMS, page]);

  const maxPage =
    sales && sales.length > 0 ? Math.ceil(sales.length / ITEMS) : 0;

  return account && sales ? (
    <div>
      <BlueCard mt="2rem">
        <ThemedText.LargeHeader
          textAlign="center"
          marginY="1rem"
          color={darkmode ? "#5199FF" : "#60CF3C"}
        >
          <Trans>Referrals History</Trans>
        </ThemedText.LargeHeader>
        {sortedHistory && sortedHistory.length > 0 ? (
          <TableWrapper>
            <ResponsiveGrid>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? "12px" : "16px"}
                textAlign="center"
              >
                ID
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? "12px" : "16px"}
                textAlign="center"
              >
                <Trans>Amount</Trans>
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? "12px" : "16px"}
                textAlign="center"
              >
                <Trans>Coin</Trans>
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? "12px" : "16px"}
                textAlign="center"
              >
                <Trans>Profits</Trans>
              </ThemedText.DeprecatedBody>
              {sortedHistory.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <ThemedText.DeprecatedBody
                      fontSize={isMobile ? "12px" : "16px"}
                      textAlign="center"
                    >
                      {chainId && (
                        <AddressLink
                          href={getExplorerLink(
                            chainId,
                            item.id,
                            ExplorerDataType.TRANSACTION
                          )}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <LinkIcon width={128} />
                            <TruncatedText ml="12px">{item.id}</TruncatedText>
                          </div>
                        </AddressLink>
                      )}
                    </ThemedText.DeprecatedBody>
                    <ThemedText.DeprecatedBody
                      fontSize={isMobile ? "12px" : "16px"}
                      textAlign="center"
                    >
                      {item.amount}
                    </ThemedText.DeprecatedBody>
                    <ThemedText.DeprecatedBody
                      fontSize={isMobile ? "12px" : "16px"}
                      textAlign="center"
                    >
                      {item.inJamon ? (
                        <img src={JamonIcon} alt="" width={24} />
                      ) : (
                        <img src={UsdcIcon} alt="" width={24} />
                      )}
                    </ThemedText.DeprecatedBody>
                    <ThemedText.DeprecatedBody
                      fontSize={isMobile ? "12px" : "16px"}
                      textAlign="center"
                    >
                      {getFullDisplayBalance(
                        new BigNumber(item.referralEarnings ?? 0),
                        item.inJamon ? 18 : 6,
                        item.inJamon ? 2 : 0
                      )}{" "}
                    </ThemedText.DeprecatedBody>
                  </React.Fragment>
                );
              })}
            </ResponsiveGrid>
            <PageButtons>
              <Arrow
                onClick={() => {
                  setPage(page === 1 ? page : page - 1);
                }}
              >
                <ThemedText.DeprecatedBody
                  color={page === 1 ? "text" : "primary"}
                >
                  {" "}
                  {"<"}{" "}
                </ThemedText.DeprecatedBody>
              </Arrow>

              <ThemedText.DeprecatedBody>
                <Trans>Page</Trans> {page} <Trans>of</Trans> {maxPage}
              </ThemedText.DeprecatedBody>
              <Arrow
                onClick={() => {
                  setPage(page === maxPage ? page : page + 1);
                }}
              >
                <ThemedText.DeprecatedBody
                  color={page === maxPage ? "textDisabled" : "primary"}
                >
                  {" "}
                  {">"}{" "}
                </ThemedText.DeprecatedBody>
              </Arrow>
            </PageButtons>
          </TableWrapper>
        ) : (
          <ThemedText.DeprecatedYellow
            fontSize={isMobile ? "18px" : "20px"}
            textAlign="center"
            mt="2rem"
          >
            <Trans>No history</Trans>!
          </ThemedText.DeprecatedYellow>
        )}
      </BlueCard>
    </div>
  ) : null;
}
