import { ADDRESS_ZERO } from '@uniswap/v3-sdk'
import MINER_POOL_ABI from 'abis/GreenBlockMinerPool.json'
import BigNumber from 'bignumber.js'
import { MINER_POOL_ADDRESS } from 'constants/addresses'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import { BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'

import { MinerInfo } from '../types'

/**
 * Gets data of Cave on pool
 */
const useMinerInfo = (chainId: number, id: number): MinerInfo => {
  const address = MINER_POOL_ADDRESS[chainId]
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    owner: ADDRESS_ZERO,
    caveId: 0,
    expiration: 0,
    stakedPower: BIG_ZERO,
    onSale: false,
    salePrice: BIG_ZERO,
    pendingRewards: BIG_ZERO,
    loading: true,
  })

  const fetchMinerData = useCallback(async () => {
    const Call = [
      {
        address,
        name: 'getMinerInfo',
        params: [id],
      },
      {
        address,
        name: 'getPendingRewards',
        params: [id],
      },
    ]

    const [_minerInfo, _pendingRewards] = await multicall(chainId, MINER_POOL_ABI, Call)

    setState((prev) => ({
      ...prev,
      owner: _minerInfo[0],
      caveId: Number(_minerInfo[1]),
      expiration: Number(_minerInfo[2]),
      stakedPower: new BigNumber(_minerInfo[3].toString()),
      onSale: Boolean(_minerInfo[4]),
      salePrice: new BigNumber(_minerInfo[5].toString()),
      pendingRewards: new BigNumber(_pendingRewards[0].toString()),
      loading: false,
    }))
  }, [address, chainId, id])

  useEffect(() => {
    fetchMinerData()
  }, [fetchMinerData, id, slowRefresh])

  return { ...state, fetchMinerData }
}

export default useMinerInfo
