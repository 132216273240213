/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import ProgressBar from "@ramonak/react-progress-bar";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { BlueCard } from "components/Card";
import { AutoRow } from "components/Row";
import request, { gql } from "graphql-request";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { ExternalLink, ThemedText } from "theme";
import { ellipseAddressAdd0x, shortenAddress } from "utils";
import { getFullDisplayBalance } from "utils/formatBalance";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";
import { isMobile } from "utils/userAgent";

const TableWrapper = styled.div`
  flex-direction: column;
`;

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 1rem;
`;

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

const Arrow = styled.div`
  color: ${({ theme }) => theme.textPrimary};
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`;
const HoverText = styled(ThemedText.DeprecatedMain)`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  :hover {
    color: ${({ theme }) => theme.deprecated_text4};
    text-decoration: none;
  }
`;

const Row = styled(ThemedText.DeprecatedBody)<{ isWallet?: boolean }>`
  text-align: center;
  color: ${({ isWallet, theme }) => (isWallet ? theme.deprecated_yellow1 : "")};
`;
interface HolderRow {
  id: string;
  tokens: string;
}
interface BuyRow {
  id: string;
  account: string;
  tokens: string;
  timestamp: string;
}

const Score: React.FC = () => {
  const { account } = useWeb3React();
  const darkmode = Boolean(window.matchMedia("(prefers-color-scheme: dark)"));

  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);

  const apiUrl = "https://api.thegraph.com/subgraphs/name/jamonswap/gbt-sales";

  const [totalSold, setTotalSold] = useState<BigNumber>(new BigNumber(0));
  const [sales, setSales] = useState<BuyRow[]>([]);
  const [buyers, setBuyers] = useState<HolderRow[]>([]);

  const hardcap = new BigNumber(14000000).times(1e18);
  const percent = totalSold.times(100).div(hardcap);

  useEffect(() => {
    request(
      apiUrl,
      gql`
        query {
          generals(first: 1) {
            id
            totalSold
          }
          buyers(first: 1000, orderBy: tokens, orderDirection: desc) {
            id
            tokens
          }
          purchases(first: 100, orderBy: timestamp, orderDirection: desc) {
            id
            account
            tokens
            timestamp
          }
        }
      `
    )
      .then(({ generals, buyers, purchases }) => {
        if (generals.length > 0) {
          setTotalSold(new BigNumber(generals[0].totalSold));
        }
        if (buyers.length > 0) {
          setBuyers(buyers);
        }
        if (purchases.length > 0) {
          setSales(purchases);
        }
      })
      .catch(({ error }) => {
        console.log(error);
      });
  }, [apiUrl]);

  const ITEMS = isMobile ? 5 : 10;

  const sortedSales = useMemo(() => {
    return sales.length > 0
      ? sales.slice(ITEMS * (page - 1), page * ITEMS)
      : [];
  }, [sales, page]);

  const maxPage = sortedSales.length > 0 ? Math.ceil(sales.length / ITEMS) : 0;

  const sortedBuyers = useMemo(() => {
    return buyers.length > 0
      ? buyers.slice(ITEMS * (page2 - 1), page2 * ITEMS)
      : [];
  }, [buyers, page2]);

  const maxPage2 =
    sortedBuyers.length > 0 ? Math.ceil(buyers.length / ITEMS) : 0;

  return (
    <div>
      <Link
        style={{
          textDecoration: "none",
          width: "fit-content",
          marginBottom: "2.5rem",
        }}
        to="/stake"
      >
        <HoverText>
          ← <Trans>Back to stake</Trans>
        </HoverText>
      </Link>
      <BlueCard mt="2rem">
        <ThemedText.MediumHeader
          textAlign="center"
          marginY="0.5rem"
          color={darkmode ? "#5199FF" : "#60CF3C"}
        >
          <Trans>Mined $DATA</Trans>
        </ThemedText.MediumHeader>
        <ProgressBar
          completed={parseFloat(percent.toNumber().toFixed(2))}
          maxCompleted={100}
          bgColor="#5199FF"
          transitionDuration="3s"
        />
        <ThemedText.SubHeader textAlign="center" marginTop="1rem">
          {getFullDisplayBalance(totalSold, 18, 2)} / 14000000.00
        </ThemedText.SubHeader>
        <ThemedText.SubHeader textAlign="center" marginBottom="1rem">
          DATA
        </ThemedText.SubHeader>
        <AutoRow justify={isMobile ? "center" : "left"}>
          <FontAwesomeIcon icon={faStar} width={18} height={18} />
          <ThemedText.LargeHeader
            fontSize={isMobile ? 14 : 16}
            textAlign="center"
            color="#fff"
            ml="4px"
          >
            Get the DATA token purchasing GBT with a 1:1 ratio, purchases
            allowed from 12/27/23 to 02/10/24. There are only 14M, don&apos;t
            miss out!
          </ThemedText.LargeHeader>
        </AutoRow>
      </BlueCard>
      <BlueCard mt="2rem">
        <ThemedText.MediumHeader
          textAlign="center"
          marginY="1rem"
          color={darkmode ? "#5199FF" : "#60CF3C"}
        >
          <Trans>Buyers</Trans>
        </ThemedText.MediumHeader>
        {buyers && buyers.length > 0 ? (
          <TableWrapper>
            <ResponsiveGrid isMobile={isMobile}>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? 12 : 16}
                textAlign="center"
                color="secondary3"
              >
                <Trans>Rank</Trans>
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? 12 : 16}
                textAlign="center"
                color="secondary3"
              >
                <Trans>Wallet</Trans>
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? 12 : 16}
                textAlign="center"
                color="secondary3"
              >
                Tokens
              </ThemedText.DeprecatedBody>
              {sortedBuyers.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Row
                      fontSize={isMobile ? 12 : 16}
                      isWallet={item.id === account?.toLowerCase()}
                    >
                      {index + 1 + (page2 - 1) * 10}
                    </Row>
                    <Row
                      fontSize={isMobile ? 12 : 16}
                      isWallet={item.id === account?.toLowerCase()}
                    >
                      {shortenAddress(item.id, isMobile ? 3 : 10)}
                    </Row>
                    <Row
                      fontSize={isMobile ? 12 : 16}
                      isWallet={item.id === account?.toLowerCase()}
                    >
                      {getFullDisplayBalance(new BigNumber(item.tokens), 18, 2)}{" "}
                      GBT
                    </Row>
                  </React.Fragment>
                );
              })}
            </ResponsiveGrid>
            <PageButtons>
              <Arrow
                onClick={() => {
                  setPage2(page2 === 1 ? page2 : page2 - 1);
                }}
              >
                <ThemedText.DeprecatedBody
                  color={page2 === 1 ? "text" : "primary"}
                >
                  {" "}
                  {"<"}{" "}
                </ThemedText.DeprecatedBody>
              </Arrow>

              <ThemedText.DeprecatedBody>
                <Trans>Page</Trans> {page2} <Trans>of</Trans> {maxPage2}
              </ThemedText.DeprecatedBody>
              <Arrow
                onClick={() => {
                  setPage2(page2 === maxPage2 ? page2 : page2 + 1);
                }}
              >
                <ThemedText.DeprecatedBody
                  color={page2 === maxPage2 ? "textDisabled" : "primary"}
                >
                  {" "}
                  {">"}{" "}
                </ThemedText.DeprecatedBody>
              </Arrow>
            </PageButtons>
          </TableWrapper>
        ) : (
          <ThemedText.DeprecatedYellow
            fontSize={isMobile ? "18px" : "20px"}
            textAlign="center"
          >
            <Trans>Loading</Trans>...
          </ThemedText.DeprecatedYellow>
        )}
      </BlueCard>
      <BlueCard mt="2rem">
        <ThemedText.MediumHeader
          textAlign="center"
          marginY="1rem"
          color={darkmode ? "#5199FF" : "#60CF3C"}
        >
          <Trans>Purchases</Trans>
        </ThemedText.MediumHeader>
        {sales && sales.length > 0 ? (
          <TableWrapper>
            <ResponsiveGrid isMobile={isMobile}>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? 12 : 16}
                textAlign="center"
                color="secondary3"
              >
                <Trans>Tx</Trans>
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? 12 : 16}
                textAlign="center"
                color="secondary3"
              >
                <Trans>Wallet</Trans>
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? 12 : 16}
                textAlign="center"
                color="secondary3"
              >
                Amount
              </ThemedText.DeprecatedBody>
              {sortedSales.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Row
                      fontSize={isMobile ? 12 : 16}
                      isWallet={item.account === account?.toLowerCase()}
                    >
                      <ExternalLink
                        href={getExplorerLink(
                          56,
                          item.id,
                          ExplorerDataType.TRANSACTION
                        )}
                      >
                        {ellipseAddressAdd0x(item.id, isMobile ? 3 : 3)}
                      </ExternalLink>
                    </Row>
                    <Row
                      fontSize={isMobile ? 12 : 16}
                      isWallet={item.account === account?.toLowerCase()}
                    >
                      {shortenAddress(item.account, isMobile ? 3 : 10)}
                    </Row>
                    <Row
                      fontSize={isMobile ? 12 : 16}
                      isWallet={item.account === account?.toLowerCase()}
                    >
                      {getFullDisplayBalance(new BigNumber(item.tokens), 18, 2)}{" "}
                      GBT
                    </Row>
                  </React.Fragment>
                );
              })}
            </ResponsiveGrid>
            <PageButtons>
              <Arrow
                onClick={() => {
                  setPage(page === 1 ? page : page - 1);
                }}
              >
                <ThemedText.DeprecatedBody
                  color={page === 1 ? "text" : "primary"}
                >
                  {" "}
                  {"<"}{" "}
                </ThemedText.DeprecatedBody>
              </Arrow>

              <ThemedText.DeprecatedBody>
                <Trans>Page</Trans> {page} <Trans>of</Trans> {maxPage}
              </ThemedText.DeprecatedBody>
              <Arrow
                onClick={() => {
                  setPage(page === maxPage ? page : page + 1);
                }}
              >
                <ThemedText.DeprecatedBody
                  color={page === maxPage ? "textDisabled" : "primary"}
                >
                  {" "}
                  {">"}{" "}
                </ThemedText.DeprecatedBody>
              </Arrow>
            </PageButtons>
          </TableWrapper>
        ) : (
          <ThemedText.DeprecatedYellow
            fontSize={isMobile ? "18px" : "20px"}
            textAlign="center"
          >
            <Trans>Loading</Trans>...
          </ThemedText.DeprecatedYellow>
        )}
      </BlueCard>
    </div>
  );
};

export default Score;
