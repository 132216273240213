import { colors } from "./colors";
import { opacify } from "./utils";

function getDeprecatedTheme(darkMode: boolean) {
  return {
    // text
    deprecated_text4: darkMode ? colors.gray200 : colors.gray300,

    // backgrounds / grays

    // we could move this to `background`, but gray50 is a bit different from #FAFAFA
    deprecated_bg1: darkMode ? colors.gray800 : colors.gray50,

    deprecated_bg3: darkMode ? colors.gray600 : colors.gray200,
    deprecated_bg4: darkMode ? colors.gray500 : colors.gray300,
    deprecated_bg5: darkMode ? colors.gray400 : colors.gray400,

    //specialty colors
    deprecated_advancedBG: darkMode
      ? opacify(10, colors.black)
      : opacify(60, colors.white),

    //primary colors
    deprecated_primary2: darkMode ? colors.blue400 : colors.pink300,
    deprecated_primary3: darkMode ? colors.blue300 : colors.pink200,
    deprecated_primary4: darkMode ? "#376bad70" : "#F6DDE8",
    deprecated_primary5: darkMode ? "#153d6f70" : "#FDEAF1",

    // secondary colors
    deprecated_secondary2: darkMode ? opacify(25, colors.gray900) : "#F6DDE8",
    deprecated_secondary3: darkMode ? opacify(25, colors.gray900) : "#FDEAF1",

    // other
    deprecated_yellow1: colors.yellow400,
    deprecated_yellow2: colors.yellow500,
    deprecated_yellow3: colors.yellow600,

    // dont wanna forget these blue yet
    deprecated_blue4: darkMode ? "#153d6f70" : "#C4D9F8",

    // text
    text1: darkMode ? "#FFFFFF" : "#FFFFFF",
    text2: darkMode ? "#C3C5CB" : "#565A69",
    text3: darkMode ? "#8F96AC" : "#6E727D",
    text4: darkMode ? "#B2B9D2" : "#C3C5CB",
    text5: darkMode ? "#2C2F36" : "#EDEEF2",
    // backgrounds / greys
    bgPage: darkMode
      ? "radial-gradient(60.67% 61.22% at 54.53% 42.49%, #1A0351 0%, #07071C 84.73%)"
      : "radial-gradient(60.67% 61.22% at 54.53% 42.49%, #1A0351 0%, #07071C 84.73%)",
    bg0: darkMode ? "#030315" : "#030315",
    bg1: darkMode ? "#07051D" : "#07051D",
    bg2: darkMode ? "#030315" : "#030315",
    bg3: darkMode ? "#40444F" : "#CED0D9",
    bg4: darkMode ? "#565A69" : "#888D9B",
    bg5: darkMode ? "#6C7284" : "#888D9B",
    bg6: darkMode ? "#1A2028" : "#6C7284",
    //primary colors
    primary1: darkMode ? "#39AF45" : "#39AF45",
    primary2: darkMode ? "#A5EFFF" : "#A5EFFF",
    primary3: darkMode ? "#4D8F67" : "#4D8F67",
    primary4: darkMode ? "#131438" : "#131438",
    primary5: darkMode ? "#13231F" : "#13231F",
    // secondary colors
    secondary1: darkMode ? "#1F381D" : "#1F381D",
    secondary2: darkMode ? "#174541" : "#174541",
    secondary3: darkMode ? "#1F381D" : "#1F381D",
  };
}

export const lightDeprecatedTheme = getDeprecatedTheme(false);
export const darkDeprecatedTheme = getDeprecatedTheme(true);
