/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { BlueCard } from "components/Card";
import request, { gql } from "graphql-request";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { shortenAddress } from "utils";
import { isMobile } from "utils/userAgent";

const TableWrapper = styled.div`
  flex-direction: column;
`;

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 1rem;
`;

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

const Arrow = styled.div`
  color: ${({ theme }) => theme.textPrimary};
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`;
const HoverText = styled(ThemedText.DeprecatedMain)`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  :hover {
    color: ${({ theme }) => theme.deprecated_text4};
    text-decoration: none;
  }
`;

const Row = styled(ThemedText.DeprecatedBody)<{ isWallet?: boolean }>`
  text-align: center;
  color: ${({ isWallet, theme }) => (isWallet ? theme.deprecated_yellow1 : "")};
`;
interface HolderRow {
  id: string;
  points: number;
}

const PointsList: React.FC = () => {
  const { account } = useWeb3React();
  const darkmode = Boolean(window.matchMedia("(prefers-color-scheme: dark)"));

  const [page, setPage] = useState(1);

  const apiUrl = "https://api.thegraph.com/subgraphs/name/jamonswap/gbt-sales";

  const [history, setHistory] = useState<HolderRow[]>([]);

  useEffect(() => {
    request(
      apiUrl,
      gql`
        query {
          holders(first: 20, orderBy: points, orderDirection: desc) {
            id
            points
          }
        }
      `
    )
      .then(({ holders }) => {
        if (holders.length > 0) {
          setHistory(holders);
        }
      })
      .catch(({ error }) => {
        console.log(error);
      });
  }, [apiUrl]);

  const ITEMS = isMobile ? 5 : 10;

  const sortedHistory = useMemo(() => {
    return history.length > 0
      ? history.slice(ITEMS * (page - 1), page * ITEMS)
      : [];
  }, [history, page]);

  const maxPage =
    sortedHistory.length > 0 ? Math.ceil(history.length / ITEMS) : 0;

  return history && history.length > 0 ? (
    <div>
      <Link
        style={{
          textDecoration: "none",
          width: "fit-content",
          marginBottom: "2.5rem",
        }}
        to="/stake"
      >
        <HoverText>
          ← <Trans>Back to stake</Trans>
        </HoverText>
      </Link>
      <BlueCard mt="2rem">
        <ThemedText.LargeHeader
          textAlign="center"
          marginY="1rem"
          color={darkmode ? "#5199FF" : "#60CF3C"}
        >
          <Trans>Top Holders</Trans>
        </ThemedText.LargeHeader>
        {history && history.length > 0 ? (
          <TableWrapper>
            <ResponsiveGrid isMobile={isMobile}>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? 12 : 16}
                textAlign="center"
              >
                <Trans>Rank</Trans>
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? 12 : 16}
                textAlign="center"
              >
                <Trans>Wallet</Trans>
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody
                fontSize={isMobile ? 12 : 16}
                textAlign="center"
              >
                Points
              </ThemedText.DeprecatedBody>
              {history.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Row
                      fontSize={isMobile ? 12 : 16}
                      isWallet={item.id === account?.toLowerCase()}
                    >
                      {index + 1}
                    </Row>
                    <Row
                      fontSize={isMobile ? 12 : 16}
                      isWallet={item.id === account?.toLowerCase()}
                    >
                      {shortenAddress(item.id, isMobile ? 3 : 10)}
                    </Row>
                    <Row
                      fontSize={isMobile ? 12 : 16}
                      isWallet={item.id === account?.toLowerCase()}
                    >
                      {item.points}
                    </Row>
                  </React.Fragment>
                );
              })}
            </ResponsiveGrid>
            {/* <PageButtons>
              <Arrow
                onClick={() => {
                  setPage(page === 1 ? page : page - 1)
                }}
              >
                <ThemedText.DeprecatedBody color={page === 1 ? 'text' : 'primary'}> {'<'} </ThemedText.DeprecatedBody>
              </Arrow>

              <ThemedText.DeprecatedBody>
                <Trans>Page</Trans> {page} <Trans>of</Trans> {maxPage}
              </ThemedText.DeprecatedBody>
              <Arrow
                onClick={() => {
                  setPage(page === maxPage ? page : page + 1)
                }}
              >
                <ThemedText.DeprecatedBody color={page === maxPage ? 'textDisabled' : 'primary'}>
                  {' '}
                  {'>'}{' '}
                </ThemedText.DeprecatedBody>
              </Arrow>
            </PageButtons> */}
          </TableWrapper>
        ) : (
          <ThemedText.DeprecatedYellow
            fontSize={isMobile ? "18px" : "20px"}
            textAlign="center"
          >
            <Trans>No history</Trans>!
          </ThemedText.DeprecatedYellow>
        )}
      </BlueCard>
    </div>
  ) : (
    <>
      <Link
        style={{
          textDecoration: "none",
          width: "fit-content",
          marginBottom: "2.5rem",
        }}
        to="/stake"
      >
        <HoverText>
          ← <Trans>Back to stake</Trans>
        </HoverText>
      </Link>
      <ThemedText.DeprecatedYellow
        fontSize={isMobile ? "18px" : "20px"}
        textAlign="center"
      >
        <Trans>No history</Trans>!
      </ThemedText.DeprecatedYellow>
    </>
  );
};

export default PointsList;
