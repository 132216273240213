import { useWeb3React } from '@web3-react/core'
import erc20Abi from 'abis/presaleNFT.json'
import { GBT_PRESALE_ADDRESS } from 'constants/addresses'
import { isSupportedChain } from 'constants/chains'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import Multicall from 'utils/multicall'

const usePublicNFTData = (): number => {
  const { fastRefresh } = useRefresh()
  const { chainId = 56 } = useWeb3React()
  const [state, setState] = useState(0)

  const chainAllowed = isSupportedChain(chainId) ? chainId : 56

  const presaleAddress = GBT_PRESALE_ADDRESS[chainAllowed]

  const fetchData = useCallback(async () => {
    const UserCalls = [{ address: presaleAddress, name: 'totalSold', params: [] }]

    const [data] = await Multicall(chainAllowed, erc20Abi, UserCalls)

    setState(data[0])
  }, [chainAllowed, presaleAddress])

  useEffect(() => {
      fetchData()
  }, [fetchData, fastRefresh])

  return state
}

export default usePublicNFTData
