/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { ButtonPrimary } from 'components/Button'
import Modal from 'components/Modal'
import { USDC } from 'constants/tokens'
import { ethers } from 'ethers'
import { useMinerPoolContract, useTokenContract } from 'hooks/useContract'
import useWalletBalances from 'pages/Sale/hooks/useWalletBalances'
import { useCallback, useState } from 'react'
import { X } from 'react-feather'
import styled from 'styled-components/macro'
import { CustomLightSpinner, ThemedText } from 'theme'
import { getFullDisplayBalance } from 'utils/formatBalance'

interface Props {
  chainId: number
  minerId: number
  price: BigNumber
  onDismiss: () => void
  update: () => void
  isOpen: boolean
}

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

export default function BuyMinerModal({ chainId, minerId, price, onDismiss, isOpen, update }: Props) {  
  const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))
  const [approving, setApproving] = useState(false)
  const [confirming, setConfirming] = useState(false)

  const balanceData = useWalletBalances(chainId)
  const usdtContract = useTokenContract(USDC[chainId].address)
  const minerPoolContract = useMinerPoolContract()

  const { usdtBalance, usdtMinerAllowance } = balanceData

  const haveUsdtBalance = usdtBalance.gte(price)
  const isUsdtAllowed = usdtMinerAllowance.gt(0)

  const handleApprove = useCallback(async () => {
    try {
      setApproving(true)
      const tx = await usdtContract?.approve(minerPoolContract?.address ?? '', ethers.constants.MaxUint256)
      await tx?.wait()
      balanceData.fetchWalletData()
      setApproving(false)
    } catch (e) {
      setApproving(false)
      console.error(e)
    } finally {
      setApproving(false)
    }
  }, [balanceData, minerPoolContract?.address, usdtContract])

  const handleBuy = useCallback(async () => {
    try {
      setConfirming(true)
      const tx = await minerPoolContract?.buyMiner(minerId)
      await tx?.wait()
      setConfirming(false)
      balanceData.fetchWalletData()
      update()
      onDismiss()
    } catch (e) {
      setConfirming(false)
      console.error(e)
    } finally {
      setConfirming(false)
    }
  }, [balanceData, minerId, minerPoolContract, onDismiss, update])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <StyledClosed>
          <X size={24} onClick={onDismiss} />
        </StyledClosed>
        <ThemedText.LargeHeader textAlign="center" marginY="1rem" style={{ textTransform: 'uppercase' }}>
          <Trans>Buy Miner</Trans>
        </ThemedText.LargeHeader>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem' }}>
          <ThemedText.MediumHeader fontSize="1rem" color={darkmode ? '#5199FF' : '#25B728'}>
            <Trans>Price</Trans>
          </ThemedText.MediumHeader>
          <ThemedText.MediumHeader fontStyle="italic" color="#F5F5DC">
            {getFullDisplayBalance(price, 6)} USDC
          </ThemedText.MediumHeader>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '1rem',
            marginBottom: '1rem',
            fontSize: '14px',
            marginRight: '0.6rem',
          }}
        >
          <Trans>Balance</Trans>: {getFullDisplayBalance(usdtBalance, 6, 2)} USDC
        </div>
        {isUsdtAllowed ? (
          <ButtonPrimary mt="1rem" disabled={!haveUsdtBalance || confirming} onClick={handleBuy}>
            {!haveUsdtBalance ? (
              <Trans>Insufficient Balance</Trans>
            ) : confirming ? (
              <>
                <Trans>Confirming</Trans>
                <CustomLightSpinner
                  src="/images/blue-loader.svg"
                  alt="loader"
                  size={'24px'}
                  style={{ marginLeft: '0.5rem' }}
                />
              </>
            ) : (
              <Trans>Confirm</Trans>
            )}
          </ButtonPrimary>
        ) : (
          <ButtonPrimary mt="1rem" disabled={approving} onClick={handleApprove}>
            {approving ? (
              <>
                <Trans>Enabling</Trans>
                <CustomLightSpinner
                  src="/images/blue-loader.svg"
                  alt="loader"
                  size={'24px'}
                  style={{ marginLeft: '0.5rem' }}
                />
              </>
            ) : (
              <Trans>Enable</Trans>
            )}
          </ButtonPrimary>
        )}
      </ModalWrapper>
    </Modal>
  )
}
