import BigNumber from 'bignumber.js'
// eslint-disable-next-line no-restricted-imports
import { ethers } from 'ethers'

export const BIG_ZERO = new BigNumber(0)
export const BIG_ONE = new BigNumber(1)
export const BIG_NINE = new BigNumber(9)
export const BIG_TEN = new BigNumber(10)

export const DEFAULT_TOKEN_DECIMALS = BIG_TEN.pow(18)
export const USDT_DECIMALS = BIG_TEN.pow(6)
export const WBTC_DECIMALS = BIG_TEN.pow(8)

export const ethersToSerializedBigNumber = (ethersBn: ethers.BigNumber) => ethersToBigNumber(ethersBn).toJSON()

export const ethersToBigNumber = (ethersBn: ethers.BigNumber): BigNumber => new BigNumber(ethersBn.toString())
