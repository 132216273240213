/* eslint-disable @typescript-eslint/no-restricted-imports */
import { t, Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { useToggleAccountDrawer } from "components/AccountDrawer";
import { ButtonLight, ButtonPrimary, ButtonTap } from "components/Button";
import { DarkCard, LightCard } from "components/Card";
import { AutoColumn } from "components/Column";
import NumericalInput from "components/NumericalInput";
import { RowBetween, RowFixed } from "components/Row";
import {
  GBT_ADDRESS,
  GBT_NFT_ADDRESS,
  USDT_ADDRESS,
} from "constants/addresses";
import { ethers } from "ethers";
import { usePresaleNFTContract, useTokenContract } from "hooks/useContract";
import { currentTimestamp } from "pages/Sale";
import { useCallback, useMemo, useState } from "react";
import { zeroPad } from "react-countdown";
import Countdown from "react-countdown";
import { ExternalLink as ExternalLinkIcon } from "react-feather";
import styled from "styled-components/macro";
import { ExternalLink, ThemedText } from "theme";
import { getFullDisplayBalance } from "utils/formatBalance";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";
import { isMobile } from "utils/userAgent";

import usePublicNFTData from "../hooks/usePublicNFTData";
import useUserNFTData from "../hooks/useUserNFTData";
import MintModal from "./MintModal";

const RowCenter = styled.div`
  display: grid;
  place-items: center;
`;

const FilterContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.background};
  border-radius: 20px;
  margin: auto;
`;

const Label = styled(({ ...props }) => (
  <ThemedText.DeprecatedLabel {...props} />
))`
  display: flex;
  font-size: 20px;
  justify-content: flex-start;
  align-items: center;
`;

const NftBox = styled.div`
  display: grid;
  margin-top: 1rem;
  place-items: center;
`;

const NftImg = styled.img`
  width: 80%;
`;

const ResponsiveRow = styled(RowBetween)`
  gap: 1rem;
  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    width: 100%;
  }
`;

const StyledInput = styled(NumericalInput)`
  background-color: transparent;
  text-align: right;
  width: 100%;
  font-size: 24px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    font-size: 12px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 12px;
  `};
`;

const rendererStart = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number
) => (
  <span>
    <Trans>Starts in</Trans> {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}
    :{zeroPad(seconds)}
  </span>
);

const rendererEnd = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number
) => (
  <span>
    <Trans>Ends in</Trans> {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:
    {zeroPad(seconds)}
  </span>
);

export default function MintPage() {
  const { chainId = 56, account } = useWeb3React();
  const [useGBT, setUseGBT] = useState(false);
  const toggleWalletDrawer = useToggleAccountDrawer();
  const [openModal, setOpenModal] = useState(false);
  const [enabling, setEnabling] = useState(false);
  const [value, setValue] = useState(0);

  const presaleNFT = usePresaleNFTContract();
  const totalSold = usePublicNFTData();
  const GBTprice = new BigNumber("32000000000000000000000");
  const USDTprice = new BigNumber("175000000000000000000");
  const totalSupply = 200;
  const price = useGBT ? 32000 : 175;
  const saleStart = 1691683200 * 1000; // timestamp with miliseconds
  const saleEnd = 1693526399 * 1000; // timestamp with miliseconds

  const userData = useUserNFTData();
  const { usdtBalance, gbtBalance, usdtAllowance, gbtAllowance, ownerIds } =
    userData;

  const bigPrice = useGBT ? GBTprice : USDTprice;
  const available = totalSupply - totalSold;
  const payout = bigPrice.times(value);

  const isAllowed = useGBT
    ? gbtAllowance.gte(payout)
    : usdtAllowance.gte(payout);

  const limit = isMobile ? 8 : 25;

  const rowsIds: number[][] = useMemo(() => {
    const array = [];
    if (ownerIds && ownerIds.length > 0) {
      for (let i = 0; i < ownerIds.length; i += limit) {
        const row = ownerIds.slice(i, i + limit);
        array.push(row);
      }
    }
    return array;
  }, [limit, ownerIds]);

  const isStarted = saleStart < currentTimestamp();
  const isFinised = saleEnd < currentTimestamp();
  const [started, setStarted] = useState(isStarted);
  const [finised, setFinised] = useState(isFinised);

  const gbtContract = useTokenContract(GBT_ADDRESS[chainId]);
  const usdtContract = useTokenContract(USDT_ADDRESS[chainId]);

  const handleApproveGBT = useCallback(async () => {
    try {
      setEnabling(true);
      const tx = await gbtContract?.approve(
        presaleNFT?.address ?? "",
        ethers.constants.MaxUint256
      );
      await tx?.wait();
      userData.fetchData();
      setEnabling(false);
    } catch (e) {
      setEnabling(false);
      console.error(e);
    } finally {
      setEnabling(false);
    }
  }, [gbtContract, presaleNFT?.address, userData]);

  const handleApproveUSDT = useCallback(async () => {
    try {
      setEnabling(true);
      const tx = await usdtContract?.approve(
        presaleNFT?.address ?? "",
        ethers.constants.MaxUint256
      );
      await tx?.wait();
      userData.fetchData();
      setEnabling(false);
    } catch (e) {
      setEnabling(false);
      console.error(e);
    } finally {
      setEnabling(false);
    }
  }, [usdtContract, presaleNFT?.address, userData]);

  const isPrimaryEnabled = () => {
    if (!started) {
      return false;
    }
    if (finised) {
      return false;
    }
    if (enabling) {
      return false;
    }
    if (available === 0) {
      return false;
    }

    if (value === 0) {
      return false;
    }

    if (value > available) {
      return false;
    }

    if (useGBT && gbtBalance.lt(payout)) {
      return false;
    }
    if (!useGBT && usdtBalance.lt(payout)) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (!started) {
      return (
        <Countdown
          date={new Date(saleStart)}
          // eslint-disable-next-line react/prop-types
          renderer={(props: any) =>
            rendererStart(props.days, props.hours, props.minutes, props.seconds)
          }
          onComplete={() => setStarted(true)}
        />
      );
    }

    if (finised) {
      return t`Finished`;
    }
    if (enabling) {
      return t`Enabling`;
    }
    if (available === 0) {
      return t`Not enough NFTs`;
    }

    if (value === 0) {
      return t`Enter amount`;
    }

    if (value > available) {
      return t`Not enough NFTs`;
    }

    if (useGBT && gbtBalance.lt(payout)) {
      return t`Insufficient balance`;
    }
    if (!useGBT && usdtBalance.lt(payout)) {
      return t`Insufficient balance`;
    }

    if (!isAllowed) {
      return t`Enable`;
    }

    return t`Mint`;
  };

  const getPrimaryAction = () => {
    if (!isAllowed && useGBT) {
      return handleApproveGBT();
    }

    if (!isAllowed && !useGBT) {
      return handleApproveUSDT();
    }

    return setOpenModal(true);
  };

  return (
    <>
      <ResponsiveRow align="flex-start">
        <DarkCard width="100%" height="100%">
          <ThemedText.BodyPrimary>GBT NFT</ThemedText.BodyPrimary>
          <NftBox>
            <NftImg
              src={`${process.env.PUBLIC_URL}/nft/image.png`}
              style={{ borderRadius: "16px" }}
            />
          </NftBox>
          <RowCenter>
            <RowFixed>
              <ExternalLink
                id="pool-link"
                href={getExplorerLink(
                  chainId,
                  GBT_NFT_ADDRESS[chainId],
                  ExplorerDataType.ADDRESS
                )}
                target="_blank"
              >
                <ThemedText.DeprecatedMain mt="1rem">
                  <Trans>NFT Contract</Trans>
                  <ExternalLinkIcon
                    opacity={0.6}
                    size={16}
                    style={{ marginLeft: 5, marginBottom: -2 }}
                  />
                </ThemedText.DeprecatedMain>
              </ExternalLink>
            </RowFixed>
            {/* <RowFixed>
              <ExternalLink id="pool-link" href="https://opensea.io/collection/capo-nft" target="_blank">
                <ThemedText.DeprecatedMain mt="1rem">
                  <Trans>Opensea</Trans>
                  <ExternalLinkIcon opacity={0.6} size={16} style={{ marginLeft: 5, marginBottom: -2 }} />
                </ThemedText.DeprecatedMain>
              </ExternalLink>
            </RowFixed> */}
          </RowCenter>
        </DarkCard>
        <AutoColumn gap="sm" style={{ width: "100%", height: "100%" }}>
          <DarkCard width="100%" height="100%">
            <AutoColumn gap="md" style={{ width: "100%" }}>
              <AutoColumn gap="md">
                <Label>
                  <Trans>Info</Trans>
                </Label>
              </AutoColumn>
              <LightCard padding="12px 16px">
                <AutoColumn gap="md">
                  <RowBetween>
                    <RowFixed>
                      <ThemedText.DeprecatedMain>
                        <Trans>Total Supply</Trans>
                      </ThemedText.DeprecatedMain>
                    </RowFixed>
                    <RowFixed>
                      <ThemedText.BodyPrimary>
                        {totalSupply.toString()} NFTs
                      </ThemedText.BodyPrimary>
                    </RowFixed>
                  </RowBetween>
                  <RowBetween>
                    <RowFixed>
                      <ThemedText.DeprecatedMain>
                        <Trans>Available</Trans>
                      </ThemedText.DeprecatedMain>
                    </RowFixed>
                    <RowFixed>
                      <ThemedText.BodyPrimary>
                        {available.toString()} NFTs
                      </ThemedText.BodyPrimary>
                    </RowFixed>
                  </RowBetween>
                  <RowBetween>
                    <RowFixed>
                      <ThemedText.DeprecatedMain>
                        <Trans>Minted</Trans>
                      </ThemedText.DeprecatedMain>
                    </RowFixed>
                    <RowFixed>
                      <ThemedText.BodyPrimary>
                        {totalSold.toString()} NFTs
                      </ThemedText.BodyPrimary>
                    </RowFixed>
                  </RowBetween>
                  <RowBetween>
                    <RowFixed>
                      <ThemedText.DeprecatedMain>
                        USDT <Trans>Price</Trans>
                      </ThemedText.DeprecatedMain>
                    </RowFixed>
                    <RowFixed>
                      <ThemedText.BodyPrimary>175 USDT</ThemedText.BodyPrimary>
                    </RowFixed>
                  </RowBetween>
                  <RowBetween>
                    <RowFixed>
                      <ThemedText.DeprecatedMain>
                        GBT <Trans>Price</Trans>
                      </ThemedText.DeprecatedMain>
                    </RowFixed>
                    <RowFixed>
                      <ThemedText.BodyPrimary>
                        32,000 GBT
                      </ThemedText.BodyPrimary>
                    </RowFixed>
                  </RowBetween>
                </AutoColumn>
              </LightCard>
              {account ? (
                <AutoColumn gap="0.5rem">
                  <ThemedText.DeprecatedMain mt="1rem">
                    <Trans>NFT to buy</Trans>
                  </ThemedText.DeprecatedMain>
                  <FilterContainer>
                    <ButtonTap
                      onClick={() => setUseGBT(false)}
                      active={!useGBT}
                      width="fit-content"
                    >
                      <ThemedText.BodyPrimary
                        fontSize={isMobile ? "16px" : "20px"}
                      >
                        USDT
                      </ThemedText.BodyPrimary>
                    </ButtonTap>
                    <ButtonTap
                      onClick={() => setUseGBT(true)}
                      active={useGBT}
                      width="fit-content"
                    >
                      <ThemedText.BodyPrimary
                        fontSize={isMobile ? "16px" : "20px"}
                      >
                        GBT
                      </ThemedText.BodyPrimary>
                    </ButtonTap>
                  </FilterContainer>
                  <LightCard>
                    <StyledInput
                      className="amount"
                      type="number"
                      step={0}
                      max={50}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      value={value}
                      onUserInput={(val) => {
                        let value = Number(val);
                        if (value > 50) {
                          value = 50;
                        }
                        setValue(value);
                      }}
                    />
                    <ThemedText.BodySecondary textAlign="right">
                      <Trans>Balance</Trans>:{" "}
                      {getFullDisplayBalance(useGBT ? gbtBalance : usdtBalance)}{" "}
                      {useGBT ? "GBT" : "USDT"}
                    </ThemedText.BodySecondary>
                  </LightCard>
                  {value > 0 && (
                    <ThemedText.BodySecondary textAlign="center">
                      <Trans>You pay</Trans> {getFullDisplayBalance(payout)}{" "}
                      {useGBT ? "GBT" : "USDT"}
                    </ThemedText.BodySecondary>
                  )}
                  <RowCenter>
                    <ButtonPrimary
                      onClick={getPrimaryAction}
                      disabled={!isPrimaryEnabled()}
                    >
                      {getPrimaryText()}
                    </ButtonPrimary>
                  </RowCenter>
                  <RowCenter>
                    {started && !finised && (
                      <Countdown
                        date={new Date(saleEnd)}
                        // eslint-disable-next-line react/prop-types
                        renderer={(props: any) =>
                          rendererEnd(
                            props.days,
                            props.hours,
                            props.minutes,
                            props.seconds
                          )
                        }
                        onComplete={() => setFinised(true)}
                      />
                    )}
                  </RowCenter>
                </AutoColumn>
              ) : (
                <RowCenter>
                  <ButtonLight mt="25%" onClick={toggleWalletDrawer}>
                    <Trans>Connect Wallet</Trans>
                  </ButtonLight>
                </RowCenter>
              )}
              <RowCenter>
                {!started && (
                  <Countdown
                    date={new Date(saleStart)}
                    // eslint-disable-next-line react/prop-types
                    renderer={(props: any) =>
                      rendererStart(
                        props.days,
                        props.hours,
                        props.minutes,
                        props.seconds
                      )
                    }
                    onComplete={() => setStarted(true)}
                  />
                )}
              </RowCenter>
              <LightCard padding="12px 16px">
                <AutoColumn gap="md">
                  <ThemedText.LargeHeader
                    fontSize={isMobile ? 10 : 12}
                    textAlign="left"
                    ml="4px"
                  >
                    -
                    <Trans>
                      Earn a fixed apy 80% (175$ Usdt) paid in WETH monthly
                    </Trans>
                  </ThemedText.LargeHeader>
                  <ThemedText.LargeHeader
                    fontSize={isMobile ? 10 : 12}
                    textAlign="left"
                    ml="4px"
                  >
                    -
                    <Trans>
                      Earn a fixed apy 40% (350$ gbt) paid in WETH monthly
                    </Trans>
                  </ThemedText.LargeHeader>
                  <ThemedText.LargeHeader
                    fontSize={isMobile ? 10 : 12}
                    textAlign="left"
                    ml="4px"
                  >
                    -
                    <Trans>
                      Payments start September 2023 until September 2028
                    </Trans>
                  </ThemedText.LargeHeader>
                  <ThemedText.LargeHeader
                    fontSize={isMobile ? 10 : 12}
                    textAlign="left"
                    ml="4px"
                  >
                    -
                    <Trans>
                      Rewards come from our data Center in Al Ain (1 Mw)
                    </Trans>
                  </ThemedText.LargeHeader>
                </AutoColumn>
              </LightCard>
            </AutoColumn>
          </DarkCard>
        </AutoColumn>
      </ResponsiveRow>
      {ownerIds && ownerIds.length > 0 && (
        <DarkCard>
          <AutoColumn gap="md">
            <RowFixed>
              <Label display="flex" style={{ marginRight: "12px" }}>
                <Trans>Your ids</Trans>
              </Label>
            </RowFixed>
            <LightCard padding="12px" width="100%">
              {rowsIds.map((row, index) => (
                <div key={index}>{row.join(",")}</div>
              ))}
            </LightCard>
          </AutoColumn>
        </DarkCard>
      )}
      <MintModal
        price={payout}
        amount={value}
        isOpen={openModal}
        onDismiss={() => setOpenModal(false)}
        useGBT={useGBT}
      />
    </>
  );
}
