/* eslint-disable @typescript-eslint/no-restricted-imports */
/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { ButtonLight, ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import Modal from 'components/Modal'
import { Input as NumericalInput } from 'components/NumericalInput'
import { RowBetween } from 'components/Row'
import { GBT } from 'constants/tokens'
import { ethers } from 'ethers'
import { usePoolContract, useTokenContract } from 'hooks/useContract'
import { useCallback, useMemo, useState } from 'react'
import { X } from 'react-feather'
import styled from 'styled-components/macro'
import { CustomLightSpinner, ThemedText } from 'theme'
import { BIG_TEN } from 'utils/bigNumber'
import { getBalanceAmount, getFullDisplayBalance } from 'utils/formatBalance'

import { PublicPoolData, WalletBalancesData, WalletPoolData } from '../types'

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const StyledInput = styled(NumericalInput)`
  background-color: transparent;
  text-align: left;
  width: 100%;
  font-size: 24px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    font-size: 12px;
  `};
`

interface StakeModalProps {
  walletData: WalletBalancesData
  poolData: PublicPoolData
  walletPoolData: WalletPoolData
  chainId: number
  onDismiss: () => void
  isOpen: boolean
}

export function StakeModal({ walletData, chainId, poolData, walletPoolData, onDismiss, isOpen }: StakeModalProps) {
  const [val, setVal] = useState('')
  const [enabling, setEnabling] = useState(false)
  const [confirming, setConfirming] = useState(false)

  const gbcContract = useTokenContract(GBT[chainId].address)
  const poolContract = usePoolContract()

  const { gbtBalance, gbtPoolAllowance } = walletData

  const bigAmount = useMemo(() => {
    return new BigNumber(val)
  }, [val])

  const valueWithTokenDecimals = bigAmount.times(BIG_TEN.pow(18))

  const isGbtAllowed = useMemo(() => {
    return gbtPoolAllowance.gte(valueWithTokenDecimals)
  }, [gbtPoolAllowance, valueWithTokenDecimals])

  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(gbtBalance)
  }, [gbtBalance])

  const handleChange = useCallback(
    (value: string) => {
      setVal(value.replace(/,/g, '.'))
    },
    [setVal]
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
  }, [fullBalance, setVal])

  const handleApprove = useCallback(async () => {
    try {
      setEnabling(true)
      const tx = await gbcContract?.approve(poolContract?.address ?? '', ethers.constants.MaxUint256)
      await tx?.wait()
      walletData.fetchWalletData()
      setEnabling(false)
    } catch (e) {
      setEnabling(false)
      console.error(e)
    } finally {
      setEnabling(false)
    }
  }, [gbcContract, poolContract?.address, walletData])

  const handleStake = useCallback(async () => {
    try {
      setConfirming(true)
      const tx = await poolContract?.stake(valueWithTokenDecimals.toFixed(0))
      await tx?.wait()
      walletData.fetchWalletData()
      poolData.fetchPublicPoolData()
      walletPoolData.fetchWalletPoolData()
      setConfirming(false)
      onDismiss()
    } catch (e) {
      setConfirming(false)
      console.error(e)
    } finally {
      setConfirming(false)
    }
  }, [onDismiss, poolContract, poolData, valueWithTokenDecimals, walletData, walletPoolData])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <RowBetween>
          <ThemedText.MediumHeader>Stake GBT</ThemedText.MediumHeader>
          <StyledClosed>
            <X size={24} onClick={onDismiss} />
          </StyledClosed>
        </RowBetween>
        <LightCard $borderRadius="8px" mt="2rem">
          <RowBetween>
            <StyledInput
              className="amount"
              type="number"
              step={0.0}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              value={val}
              onUserInput={handleChange}
            />
            <ButtonLight width="20%" height="10px" disabled={gbtBalance.eq(0)} onClick={handleSelectMax}>
              Max
            </ButtonLight>
          </RowBetween>
        </LightCard>
        <ThemedText.DeprecatedBody textAlign="right" mt="1rem">
          <Trans>Balance</Trans>: {getBalanceAmount(gbtBalance).toString()} GBT
        </ThemedText.DeprecatedBody>
        {isGbtAllowed ? (
          <ButtonPrimary
            mt="1rem"
            disabled={
              !isGbtAllowed ||
              valueWithTokenDecimals.gt(gbtBalance) ||
              valueWithTokenDecimals.eq(0) ||
              confirming ||
              !val
            }
            onClick={handleStake}
          >
            {confirming ? (
              <>
                <Trans>Confirming</Trans>
                <CustomLightSpinner
                  src="/images/blue-loader.svg"
                  alt="loader"
                  size="24px"
                  style={{ marginLeft: '0.5rem' }}
                />
              </>
            ) : (
              <Trans>Confirm</Trans>
            )}
          </ButtonPrimary>
        ) : (
          <ButtonPrimary mt="1rem" disabled={enabling} onClick={handleApprove}>
            {enabling ? (
              <>
                <Trans>Enabling</Trans>
                <CustomLightSpinner
                  src="/images/blue-loader.svg"
                  alt="loader"
                  size="24px"
                  style={{ marginLeft: '0.5rem' }}
                />
              </>
            ) : (
              <Trans>Enable</Trans>
            )}
          </ButtonPrimary>
        )}
      </ModalWrapper>
    </Modal>
  )
}
