import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import CaveIcon from 'assets/images/sale/cave-ico.png'
import MinerIcon from 'assets/images/sale/miner-ico.png'
import PigBankIcon from 'assets/images/sale/pig-bank-ico-circle.png'
import { ButtonEmpty } from 'components/Button'
import { BlueCard } from 'components/Card'
import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import Cave from './components/Cave'
import LockHistory from './components/LockHistory'
import Miner from './components/Miner'
import SaleHistory from './components/SaleHistory'
import Stake from './components/Stake'
import useWalletBalances from './hooks/useWalletBalances'

const PageWrapper = styled.div`
  max-width: 600px;
  width: 100%;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    max-width: 500px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    max-width: 400px;
    margin-top: 5rem;
  `};
`

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Image = styled.img<{ isMobile?: boolean; isActive?: boolean; isDark?: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '80px' : '100px')};
  border: 3px solid
    ${({ isActive, isDark }) => (isDark ? (isActive ? '#C4D9F8' : '#2172E5') : isActive ? '#1F381D' : '#57db48')};
  border-radius: 50px;
`

const TitleSection = styled(ThemedText.MediumHeader)<{ isMobile?: boolean; isActive?: boolean; isDark?: boolean }>`
  color: ${({ isActive, isDark }) => (isDark ? (isActive ? '#C4D9F8' : '#2172E5') : isActive ? '#1F381D' : '#57db48')};
`

export const currentTimestamp = () => new Date().getTime()

const allowedChains = (chainId: number) => {
  let supported = false
  switch (chainId) {
    case 137:
    case 80001:
      supported = true
      break
    default:
      supported = false
      break
  }
  return supported
}

export default function Sale() {
  const { account, chainId, connector } = useWeb3React()
  const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))

  const [sale, setSale] = useState(0)

  const activeChain = chainId ? chainId : 137

  const walletData = useWalletBalances(activeChain)

  const supportedChain = allowedChains(activeChain)
  const location = useLocation()
  if (!supportedChain) {
    return <Navigate to={{ ...location, pathname: '/home' }} replace />
  }

  return (
    <>
      <PageWrapper>
        <Heading>
          <ButtonEmpty onClick={() => setSale(0)} style={{ textDecoration: 'none' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Image isDark={darkmode} isActive={sale === 0} src={CaveIcon} alt="cave-img" />
              <TitleSection isDark={darkmode} isActive={sale === 0}>
                <Trans>Cave</Trans>
              </TitleSection>
            </div>
          </ButtonEmpty>
          <ButtonEmpty onClick={() => setSale(1)} style={{ textDecoration: 'none' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Image isDark={darkmode} isActive={sale === 1} src={MinerIcon} alt="miner-img" />
              <TitleSection isDark={darkmode} isActive={sale === 1}>
                <Trans>Miner</Trans>
              </TitleSection>
            </div>
          </ButtonEmpty>
          <ButtonEmpty onClick={() => setSale(2)} style={{ textDecoration: 'none' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Image
                isDark={darkmode}
                isActive={sale === 2}
                src={PigBankIcon}
                alt="stake-img"
                style={{ background: darkmode ? '#1A4767' : '#698974' }}
              />
              <TitleSection isDark={darkmode} isActive={sale === 2}>
                <Trans>Lock</Trans>
              </TitleSection>
            </div>
          </ButtonEmpty>
        </Heading>
        <BlueCard>
          {sale === 0 && <Cave account={account} walletData={walletData} chainId={activeChain} />}
          {sale === 1 && <Miner account={account} walletData={walletData} chainId={activeChain} />}
          {sale === 2 && <Stake account={account} walletData={walletData} chainId={activeChain} />}
        </BlueCard>
        {(sale === 0 || sale === 1) && <SaleHistory walletData={walletData} chainId={activeChain} />}
        {sale === 2 && <LockHistory walletData={walletData} chainId={activeChain} />}
      </PageWrapper>
      <SwitchLocaleLink />
    </>
  )
}
