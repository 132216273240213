import POOL_ABI from 'abis/GreenBlockNFTPool.json'
import BigNumber from 'bignumber.js'
import { NFT_POOL_ADDRESS } from 'constants/addresses'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import { BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'

import { PublicPoolData } from '../types'

/**
 * Gets all public data of Pool contract
 */
const usePublicPoolData = (chainId: number): PublicPoolData => {
  const poolAddress = NFT_POOL_ADDRESS[chainId]
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    totalHolders: 0,
    totalDistributed: BIG_ZERO,
    totalStaked: 0,
    rewardsXblock: BIG_ZERO,
  })

  const fetchPublicPoolData = useCallback(async () => {
    const userCalls = [
      {
        address: poolAddress,
        name: 'totalHolders',
      },
      {
        address: poolAddress,
        name: 'totalDistributed',
      },
      {
        address: poolAddress,
        name: 'totalStaked',
      },
      {
        address: poolAddress,
        name: 'getRewardsXblock',
      },
    ]

    const [totalHolders, totalDistributed, totalStaked, rewardsXblock] = await multicall(chainId, POOL_ABI, userCalls)

    setState((prev) => ({
      ...prev,
      totalHolders: Number(totalHolders[0]),
      totalDistributed: new BigNumber(totalDistributed[0].toString()),
      totalStaked: Number(totalStaked[0]),
      rewardsXblock: new BigNumber(rewardsXblock[0].toString()),
    }))
  }, [chainId, poolAddress])

  useEffect(() => {
    fetchPublicPoolData()
  }, [fetchPublicPoolData, slowRefresh])

  return { ...state, fetchPublicPoolData }
}

export default usePublicPoolData
