/* eslint-disable import/no-unused-modules */
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.POLYGON_MUMBAI]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.BSC]: '0xF031DD0E71b7A83E375804F80101a2824F3234bb',
  [SupportedChainId.BSC_TESTNET]: '0x6A8CD69eb658Bfe55340588dDa298F7ec4299985',
}

export const MULTICALLV2_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x406837df92C4Fe8e824Ab558F7292B31D955A611',
  [SupportedChainId.POLYGON_MUMBAI]: '0xF2e8cE0B41aee041Cc4C7C1Cf7194aBdFF5BD601',
  [SupportedChainId.BSC]: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
  [SupportedChainId.BSC_TESTNET]: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
}

/**
 * GreenBlock contracts
 */
export const NFT_VIP_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x15a728e5d93371Be6437428ce65c2720F346EdfD',
  [SupportedChainId.POLYGON_MUMBAI]: '0x443845DB39f1e32487Ed3413125cb93F90a7223a',
  [SupportedChainId.BSC]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.BSC_TESTNET]: '0x1F98415757620B543A52E61c46B32eB19261F984',
}

export const NFT_CAVE_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x1D7Efbe79E91aBaEBBF480ba90bdb52c1cCcb8e2',
  [SupportedChainId.POLYGON_MUMBAI]: '0xaaBb2c9e80ec39B0eBe1E8e590082CE1242CaBb2',
  [SupportedChainId.BSC]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.BSC_TESTNET]: '0x1F98415757620B543A52E61c46B32eB19261F984',
}

export const NFT_MINER_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x91Fc51299EdF529a14724AFd42540C35e434926b',
  [SupportedChainId.POLYGON_MUMBAI]: '0x0D5f09dcDf35dbbbb7AE84496e23157507E3E8Bb',
  [SupportedChainId.BSC]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.BSC_TESTNET]: '0x1F98415757620B543A52E61c46B32eB19261F984',
}

export const SALES_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x5652cC87F0e8DE64adf590CAFEA7Ad278DAb457F',
  [SupportedChainId.POLYGON_MUMBAI]: '0x83aF4e13575A100de650334F804f016596A69F5E',
  [SupportedChainId.BSC]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.BSC_TESTNET]: '0x1F98415757620B543A52E61c46B32eB19261F984',
}

export const MINER_POOL_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x0466033C44fF5432984aD6aFB1F25b380F7081c5',
  [SupportedChainId.POLYGON_MUMBAI]: '0x4ac1526283350A92E43404a0197eD5377023811b',
  [SupportedChainId.BSC]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.BSC_TESTNET]: '0x1F98415757620B543A52E61c46B32eB19261F984',
}

export const CAVE_POOL_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0xD290398733B80f5D24f72EcC84dE4125857D96c6',
  [SupportedChainId.POLYGON_MUMBAI]: '0x3824261475EfCBa6a061d60480673065bA3d3373',
  [SupportedChainId.BSC]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.BSC_TESTNET]: '0x1F98415757620B543A52E61c46B32eB19261F984',
}

export const DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0xa1c1BB2A187817934f8ddA95E4a61E894320b39C',
  [SupportedChainId.POLYGON_MUMBAI]: '0x843B293d7883F9Bb03bFb7B3B21598Af1311261b',
  [SupportedChainId.BSC]: '0xde0ebCf60A1BB589411D63037301BE12aF9e13EF',
  [SupportedChainId.BSC_TESTNET]: '0xD2D122E77303c9672d47A0A3F605a0FE66a39670',
}

export const LOCKER_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x9Deb501714aeCc5a50E15f69C2866eBAC8947015',
  [SupportedChainId.POLYGON_MUMBAI]: '0x7CD1967006eB0Efe8e0498a51a13eC97ED268B16',
  [SupportedChainId.BSC]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.BSC_TESTNET]: '0x1F98415757620B543A52E61c46B32eB19261F984',
}

/**
 * Green Block Token holders
 */

export const POOL_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x9Deb501714aeCc5a50E15f69C2866eBAC8947015',
  [SupportedChainId.POLYGON_MUMBAI]: '0x7CD1967006eB0Efe8e0498a51a13eC97ED268B16',
  [SupportedChainId.BSC]: '0xE322eA8cBC9d0a5B12110c64f07F489E8BFe1788',
  [SupportedChainId.BSC_TESTNET]: '0x6943BFb8d6133C61F8D3A0E4a67F72d0B15e29cE',
}

export const BNB_POOL_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x9Deb501714aeCc5a50E15f69C2866eBAC8947015',
  [SupportedChainId.POLYGON_MUMBAI]: '0x7CD1967006eB0Efe8e0498a51a13eC97ED268B16',
  [SupportedChainId.BSC]: '0xe7B72F674ac94c4587CA8D9BEc9A76Eefa51c4F5',
  [SupportedChainId.BSC_TESTNET]: '0x1c9e5CD7449B2ceB05BB0E2A677bc7018179e819',
}

/**
 * Green Block NFT presale
 */

export const GBT_NFT_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x9Deb501714aeCc5a50E15f69C2866eBAC8947015',
  [SupportedChainId.POLYGON_MUMBAI]: '0x7CD1967006eB0Efe8e0498a51a13eC97ED268B16',
  [SupportedChainId.BSC]: '0x96fe025c777d06F4c8231A8d6Ca6b61Fc694C240',
  [SupportedChainId.BSC_TESTNET]: '0xF48F8469A1622096cfF49e5094e4a653718E8Ae1',
}

export const GBT_PRESALE_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x9Deb501714aeCc5a50E15f69C2866eBAC8947015',
  [SupportedChainId.POLYGON_MUMBAI]: '0x7CD1967006eB0Efe8e0498a51a13eC97ED268B16',
  [SupportedChainId.BSC]: '0x503B5FA720CaB72250525f3622Ea35a3b9BC6160',
  [SupportedChainId.BSC_TESTNET]: '0x4C6e00bc67FF5fDa70dFbD937C68073C7A829120',
}

export const USDT_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x9Deb501714aeCc5a50E15f69C2866eBAC8947015',
  [SupportedChainId.POLYGON_MUMBAI]: '0x7CD1967006eB0Efe8e0498a51a13eC97ED268B16',
  [SupportedChainId.BSC]: '0x55d398326f99059fF775485246999027B3197955',
  [SupportedChainId.BSC_TESTNET]: '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7',
}

export const GBT_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x9Deb501714aeCc5a50E15f69C2866eBAC8947015',
  [SupportedChainId.POLYGON_MUMBAI]: '0x7CD1967006eB0Efe8e0498a51a13eC97ED268B16',
  [SupportedChainId.BSC]: '0xd7D5d7BCD0680CD8AA5aFC34bC7037c3040f8210',
  [SupportedChainId.BSC_TESTNET]: '0x7a7949D18cf1C4CA02BA12d71b4ECBbd834Fdd8C',
}

/**
 * Green Block NFT stake
 */

export const NFT_POOL_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x9E21a94346318923Ccb1d98F724C01b67c127e05',
  [SupportedChainId.POLYGON_MUMBAI]: '0x9E21a94346318923Ccb1d98F724C01b67c127e05',
  [SupportedChainId.BSC]: '0x4507B6dF501C7dC9870436746e60D865F5A43639',
  [SupportedChainId.BSC_TESTNET]: '0xeAD9bBEb7777d6a7E8a865DC75e3DD397e382E6e',
}

export const NFT_POOL_CONVERSOR_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x2862D37f413e4935d0E4E97b0e0c35c1Cae6c62a',
  [SupportedChainId.POLYGON_MUMBAI]: '0x2862D37f413e4935d0E4E97b0e0c35c1Cae6c62a',
  [SupportedChainId.BSC]: '0x6758df1174B1d456210D7ee756E79B51759529Bb',
  [SupportedChainId.BSC_TESTNET]: '0x4B417DA61De638e81aB11e2261B462633EDDB5C5',
}

export const WETH_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0xD5a513E85ADB5545d8aCf600fd2BacF84E861A1d',
  [SupportedChainId.POLYGON_MUMBAI]: '0xD5a513E85ADB5545d8aCf600fd2BacF84E861A1d',
  [SupportedChainId.BSC]: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  [SupportedChainId.BSC_TESTNET]: '0xD5a513E85ADB5545d8aCf600fd2BacF84E861A1d',
}


/**
 * GBC Contracts
 */

export const GBC_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x9Deb501714aeCc5a50E15f69C2866eBAC8947015',
  [SupportedChainId.POLYGON_MUMBAI]: '0x7CD1967006eB0Efe8e0498a51a13eC97ED268B16',
  [SupportedChainId.BSC]: '0xb8d5CC1462bf5a28366d58FfD4d40d5d26030F3F',
  [SupportedChainId.BSC_TESTNET]: '0x13e037cdA07b254A369174dab7c6b11f1ebCf340',
}

export const GBC_PAIR_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x9Deb501714aeCc5a50E15f69C2866eBAC8947015',
  [SupportedChainId.POLYGON_MUMBAI]: '0x7CD1967006eB0Efe8e0498a51a13eC97ED268B16',
  [SupportedChainId.BSC]: '0x7a7A0A5932350082398D9B508074E94676F668b2',
  [SupportedChainId.BSC_TESTNET]: '0xa23A9ebE979db254F44F2707dd2899752a12e295',
}


export const GBC_POOL_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x9Deb501714aeCc5a50E15f69C2866eBAC8947015',
  [SupportedChainId.POLYGON_MUMBAI]: '0x7CD1967006eB0Efe8e0498a51a13eC97ED268B16',
  [SupportedChainId.BSC]: '0xD4B9B816e9d62158cC0e8e1814C315910942f2F2',
  [SupportedChainId.BSC_TESTNET]: '0xfB63AA0A5864F2f1926a332eEa03E9a8cC4bA033',
}

export const GBC_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0xa1c1BB2A187817934f8ddA95E4a61E894320b39C',
  [SupportedChainId.POLYGON_MUMBAI]: '0x843B293d7883F9Bb03bFb7B3B21598Af1311261b',
  [SupportedChainId.BSC]: '0x5cb0510a54dFA3D7A4DF85474362A34A6E65C9c1',
  [SupportedChainId.BSC_TESTNET]: '0xe451DDb98E544A0247a02A37771A3cAB4098A24d',
}