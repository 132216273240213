import { useWeb3React } from "@web3-react/core";
import POOL_ABI from "abis/GreenBlockCapitalPool.json";
import BigNumber from "bignumber.js";
import { GBC_POOL_ADDRESS } from "constants/addresses";
import useRefresh from "hooks/useRefresh";
import { useCallback, useEffect, useState } from "react";
import { BIG_ZERO } from "utils/bigNumber";
import multicall from "utils/multicall";

import { WalletPoolData } from "../types";

/**
 * Gets all wallet data of token contract
 */
const useWalletPoolData = (chainId: number): WalletPoolData => {
  const { account } = useWeb3React();
  const poolAddress = GBC_POOL_ADDRESS[chainId];
  const { slowRefresh } = useRefresh();

  const [state, setState] = useState({
    isInPool: false,
    canStake: true,
    stakedBal: BIG_ZERO,
    startTime: 0,
    unlockTime: 0,
    pendingBal: BIG_ZERO,
  });

  const fetchWalletPoolData = useCallback(async () => {
    const userCalls = [
      {
        address: poolAddress,
        name: "canStake",
        params: [account],
      },
      {
        address: poolAddress,
        name: "isInPool",
        params: [account],
      },
      {
        address: poolAddress,
        name: "getWalletInfo",
        params: [account],
      },
      {
        address: poolAddress,
        name: "getPendingBal",
        params: [account],
      },
    ];

    const [canStake, isInPool, walletInfo, pendingBal] = await multicall(
      chainId,
      POOL_ABI,
      userCalls
    );

    setState((prev) => ({
      ...prev,
      canStake: Boolean(canStake[0]),
      isInPool: Boolean(isInPool[0]),
      stakedBal: new BigNumber(walletInfo[0].toString()),
      startTime: Number(walletInfo[1]),
      unlockTime: Number(walletInfo[2]),
      pendingBal: new BigNumber(pendingBal[0].toString()),
    }));
  }, [account, chainId, poolAddress]);

  useEffect(() => {
    if (account) {
      fetchWalletPoolData();
    }
  }, [account, fetchWalletPoolData, slowRefresh]);

  return { ...state, fetchWalletPoolData };
};

export default useWalletPoolData;
