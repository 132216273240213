import styled from "styled-components/macro";

import { AboutUs } from "./components/AboutUs";
import { Features } from "./components/Features";
import { Header } from "./components/Header";

const PageWrapper = styled.div`
  max-width: 1000px;
  width: 100%;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    max-width: 500px;
  `};
`;

//supporting the Bitcoin blockchain through rapidly expanding large-scale mining in Georgia and Canada.

export default function Home() {
  return (
    <>
      <PageWrapper>
        <Header />
        <Features />
        <AboutUs />
      </PageWrapper>
      {/* <SwitchLocaleLink /> */}
    </>
  );
}
