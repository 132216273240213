import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import BnbIcon from "assets/images/tokens/BNB.png";
import BigNumber from "bignumber.js";
import { useAccountDrawer } from "components/AccountDrawer";
import { ButtonPrimary, ButtonYellow } from "components/Button";
import { BlueCard, DarkCard, LightCard } from "components/Card";
import { AutoColumn } from "components/Column";
import { AutoRow, RowBetween, RowFixed } from "components/Row";
import { NFT_POOL_ADDRESS } from "constants/addresses";
import { useNFTConversor, useNftPoolContract } from "hooks/useContract";
import { useNativeCurrencyBalances } from "lib/hooks/useCurrencyBalance";
import { darken } from "polished";
import { useCallback, useState } from "react";
import { ExternalLink as ExternalLinkIcon } from "react-feather";
import { Navigate, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { CustomLightSpinner, ExternalLink, ThemedText } from "theme";
import { DEFAULT_TOKEN_DECIMALS } from "utils/bigNumber";
import { getBalanceAmount, getFullDisplayBalance } from "utils/formatBalance";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";
import { isMobile } from "utils/userAgent";

import Charts from "./components/Charts";
import { StakeModal } from "./components/StakeModal";
import { UnstakeModal } from "./components/UnstakeModal";
import useDistributorData from "./hooks/useDistributorData";
import useGbtPrice from "./hooks/useGbtPrice";
import useGbtSupply from "./hooks/useGbtSupply";
import usePublicPoolData from "./hooks/usePublicPoolData";
import useWalletBalances from "./hooks/useWalletBalances";
import useWalletPoolData from "./hooks/useWalletPoolData";

const PageWrapper = styled.div`
  min-width: 800px;
  max-width: 960px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    min-width: 680px;
    max-width: 680px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    min-width: 600px;
    max-width: 600px;
  `};

  @media only screen and (max-width: 620px) {
    min-width: 500px;
    max-width: 500px;
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    min-width: 340px;
    max-width: 340px;
  `};
`;

const Label = styled(({ end, ...props }) => (
  <ThemedText.DeprecatedLabel {...props} />
))<{ end?: boolean }>`
  display: flex;
  font-size: 16px;
  justify-content: ${({ end }) => (end ? "flex-end" : "flex-start")};
  align-items: center;
`;

const ResponsiveRow = styled(RowBetween)`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    width: 100%:
  `};
`;

const ButtonStake = styled(ButtonPrimary)`
  background: #2172e5;
  &:focus {
    box-shadow: 0 0 0 1pt ${darken(0.05, "#2172e5")};
    background-color: ${darken(0.05, "#2172e5")};
  }
  &:hover {
    background-color: ${darken(0.05, "#2172e5")};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.05, "#2172e5")};
    background-color: ${darken(0.05, "#2172e5")};
  }
`;

const ButtonUnstake = styled(ButtonPrimary)`
  background: #df1f38;
  &:focus {
    box-shadow: 0 0 0 1pt ${darken(0.05, "#df1f38")};
    background-color: ${darken(0.05, "#df1f38")};
  }
  &:hover {
    background-color: ${darken(0.05, "#df1f38")};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.05, "#df1f38")};
    background-color: ${darken(0.05, "#df1f38")};
  }
`;

const ButtonClaim = styled(ButtonPrimary)`
  background: #25b728;
  &:focus {
    box-shadow: 0 0 0 1pt ${darken(0.05, "#25b728")};
    background-color: ${darken(0.05, "#25b728")};
  }
  &:hover {
    background-color: ${darken(0.05, "#25b728")};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.05, "#25b728")};
    background-color: ${darken(0.05, "#25b728")};
  }
`;

const allowedChains = (chainId: number) => {
  let supported = false;
  switch (chainId) {
    case 56:
    case 97:
      supported = true;
      break;
    default:
      supported = false;
      break;
  }
  return supported;
};

export default function Stake() {
  const { account, chainId, connector } = useWeb3React();
  const [, toggleAccountDrawer] = useAccountDrawer();

  const activeChain = chainId ? chainId : 56;
  const asics = 24;
  const hashPower = new BigNumber(asics * 9050).times(DEFAULT_TOKEN_DECIMALS);

  const [claiming, setClaiming] = useState(false);
  const [distributing, setDistributing] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const handleOpenModal = (n: number) => {
    setOpenModal(n);
  };

  const treasuryWallet = "0x6E9a9104649848eB45B5200c323B9e46C0515902";
  const treasuryBalance = useNativeCurrencyBalances(
    treasuryWallet ? [treasuryWallet] : []
  )?.[treasuryWallet ?? ""];

  const poolContract = useNftPoolContract();
  const distributorContract = useNFTConversor();
  const walletData = useWalletBalances(activeChain);
  const supplyData = useGbtSupply(activeChain);
  const poolData = usePublicPoolData(activeChain);
  const walletPoolData = useWalletPoolData(activeChain);
  const priceData = useGbtPrice(activeChain);

  const canDistribute = useDistributorData(activeChain);

  const { gbtPrice, gbtPriceUsd, bnbPrice, btcPrice, treasuryUSD } = priceData;
  const { totalHolders, totalStaked, totalDistributed, rewardsXblock } =
    poolData;
  const { isInPool, stakedBal, pendingBal } = walletPoolData;

  const treasuryBalanceUsd = new BigNumber(
    treasuryBalance?.toSignificant() ?? 0
  ).times(bnbPrice);
  const totalDistributedUsd = totalDistributed
    .times(btcPrice)
    .div(DEFAULT_TOKEN_DECIMALS);
  const totalStakedUsd = gbtPriceUsd
    .times(totalStaked)
    .div(DEFAULT_TOKEN_DECIMALS);
  // const ratio = totalStaked.div(hashPower).times(DEFAULT_TOKEN_DECIMALS)
  // const ratioUSD = ratio.times(gbtPriceUsd).div(DEFAULT_TOKEN_DECIMALS)
  const stakedBalUsd = gbtPriceUsd.times(stakedBal).div(DEFAULT_TOKEN_DECIMALS);
  const pendingBalUsd = pendingBal.times(btcPrice).div(DEFAULT_TOKEN_DECIMALS);
  // const apy = useMemo(() => {
  // const BLOCKS_PER_YEAR = (60 / 3) * 60 * 24 * 7 // 10512000
  // const totalRewardPricePerYear = BIG_ONE.times(rewardsXblock).times(BLOCKS_PER_YEAR).div(DEFAULT_TOKEN_DECIMALS)
  // const totalRewardPricePerYearUSD = totalRewardPricePerYear.times(btcPrice)
  // const totalStakingTokenInPool = gbtPrice.times(bnbPrice).times(totalStaked).div(DEFAULT_TOKEN_DECIMALS)
  // const apr = totalStakingTokenInPool.gt(0)
  //     ? totalRewardPricePerYearUSD.times(DEFAULT_TOKEN_DECIMALS).div(totalStakingTokenInPool).times(100)
  //     : BIG_ZERO
  // return apr
  // }, [bnbPrice, btcPrice, gbtPrice, rewardsXblock, totalStaked])

  const handleDistribute = useCallback(async () => {
    try {
      setDistributing(true);
      const tx = await distributorContract?.distribute();
      await tx?.wait();
      poolData.fetchPublicPoolData();
      walletPoolData.fetchWalletPoolData();
      setDistributing(false);
    } catch (e) {
      setDistributing(false);
      console.error(e);
    } finally {
      setDistributing(false);
    }
  }, [distributorContract, poolData, walletPoolData]);

  const handleClaim = useCallback(async () => {
    try {
      setClaiming(true);
      const tx = await poolContract?.harvest();
      await tx?.wait();
      walletData.fetchWalletBalanceData();
      poolData.fetchPublicPoolData();
      walletPoolData.fetchWalletPoolData();
      setClaiming(false);
    } catch (e) {
      setClaiming(false);
      console.error(e);
    } finally {
      setClaiming(false);
    }
  }, [poolContract, poolData, walletData, walletPoolData]);

  const supportedChain = allowedChains(activeChain);
  const location = useLocation();
  if (!supportedChain) {
    return <Navigate to={{ ...location, pathname: "/home" }} replace />;
  }

  return (
    <>
      <PageWrapper>
        <AutoColumn gap="md">
          <AutoColumn gap="sm">
            {canDistribute && (
              <BlueCard>
                <ResponsiveRow>
                  <AutoRow justify={isMobile ? "center" : "left"}>
                    <img src={BnbIcon} alt="bnb-logo" width={24} />
                    <ThemedText.LargeHeader
                      fontSize={isMobile ? 14 : 16}
                      textAlign="center"
                      color="#fff"
                      ml="4px"
                    >
                      <Trans>Rewards pending distribution</Trans>
                    </ThemedText.LargeHeader>
                  </AutoRow>
                  <ButtonYellow
                    width="fit-content"
                    size="10px"
                    disabled={distributing}
                    onClick={handleDistribute}
                  >
                    {distributing ? (
                      <>
                        <Trans>Distributing</Trans>
                        <CustomLightSpinner
                          src="/images/blue-loader.svg"
                          alt="loader"
                          size="16px"
                          style={{ marginLeft: "0.5rem" }}
                        />
                      </>
                    ) : (
                      <Trans>Distribute</Trans>
                    )}
                  </ButtonYellow>
                </ResponsiveRow>
              </BlueCard>
            )}
            <RowBetween>
              <RowFixed>
                <ThemedText.LargeHeader fontSize={isMobile ? 18 : 36} mr={2}>
                  <Trans>Stake NFT</Trans>
                </ThemedText.LargeHeader>
              </RowFixed>
              {/* {rewardsXblock.gt(0) && (
                <RowFixed gap="0.5rem">
                  <MouseoverTooltip
                    text={
                      <ThemedText.SubHeader>
                        <Trans>Weekly percentage yield, ROI per week</Trans>.
                      </ThemedText.SubHeader>
                    }
                  >
                    <AutoRow align="center">
                      <FontAwesomeIcon icon={faQuestionCircle} width={18} height={18} />
                      <ThemedText.LargeHeader  fontSize={isMobile ? 14 : 24} mr={2}>
                        WPY:
                      </ThemedText.LargeHeader>
                    </AutoRow>
                  </MouseoverTooltip>

                  <ThemedText.LargeHeader  fontSize={isMobile ? 14 : 24} mr={2}>
                    <> {apy?.toFixed(2)} %</>
                  </ThemedText.LargeHeader>
                </RowFixed>
              )} */}
            </RowBetween>
          </AutoColumn>
          {/* <DarkCard width="100%" height="100%">
            <ThemedText.LargeHeader textAlign="center" color="yellow3" mb="1rem">
              <Trans>Farm Info</Trans>
            </ThemedText.LargeHeader>
            <ResponsiveRow>
              <AutoRow justify="center">
                <AutoColumn gap="0.5rem">
                  <MouseoverTooltip
                    text={
                      <ThemedText.SubHeader>
                        <Trans>
                          Amount of funds collected with the sell tax destined to the purchase of new equipment. Balance
                          in dollars of BNB plus BUSD and USDT
                        </Trans>
                        .
                      </ThemedText.SubHeader>
                    }
                  >
                    <AutoRow align="center">
                      <ThemedText.MediumHeader mr="4px">
                        <Trans>Treasury</Trans>
                      </ThemedText.MediumHeader>
                      <FontAwesomeIcon icon={faQuestionCircle} width={18} height={18} />
                    </AutoRow>
                  </MouseoverTooltip>
                  <ThemedText.MediumHeader textAlign="center">
                    {getFullDisplayBalance(treasuryUSD.plus(treasuryBalanceUsd))} USD
                  </ThemedText.MediumHeader>
                </AutoColumn>
              </AutoRow>
              <AutoRow justify="center" gap="1rem">
                <AutoColumn justify="flex-start" gap="0.5rem">
                  <ThemedText.SubHeader fontSize={isMobile ? 14 : 16}>
                    <Trans>Mining Power</Trans>:
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader fontSize={isMobile ? 14 : 16}>
                    <Trans>Algorithm</Trans>:
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader fontSize={isMobile ? 14 : 16}>Hardware:</ThemedText.SubHeader>
                </AutoColumn>
                <AutoColumn justify="flex-end" gap="0.5rem">
                  <ThemedText.SubHeader fontSize={isMobile ? 14 : 16}>
                    ~{getFullDisplayBalance(hashPower, 18, 0)} MHs
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader fontSize={isMobile ? 14 : 16}>Scrypt</ThemedText.SubHeader>
                  <ThemedText.SubHeader fontSize={isMobile ? 14 : 16}>{asics}x Antminer L7</ThemedText.SubHeader>
                </AutoColumn>
              </AutoRow>
            </ResponsiveRow>
          </DarkCard> */}
          <ResponsiveRow align="flex-start">
            <DarkCard
              width="100%"
              height="100%"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-between",
                marginRight: "12px",
              }}
            >
              <Charts supplyData={supplyData} />

              <ExternalLink
                id="pool-link"
                href={getExplorerLink(
                  activeChain,
                  NFT_POOL_ADDRESS[activeChain],
                  ExplorerDataType.ADDRESS
                )}
                target="_blank"
              >
                <ThemedText.DeprecatedMain mt="1rem">
                  <Trans>Pool contract</Trans>
                  <ExternalLinkIcon
                    opacity={0.6}
                    size={16}
                    style={{ marginLeft: 5, marginBottom: -2 }}
                  />
                </ThemedText.DeprecatedMain>
              </ExternalLink>
            </DarkCard>
            <AutoColumn gap="sm" style={{ width: "100%", height: "100%" }}>
              <DarkCard>
                <AutoColumn gap="md" style={{ width: "100%" }}>
                  <RowBetween style={{ alignItems: "flex-start" }}>
                    <AutoColumn gap="md">
                      <Label>
                        <Trans>Pool Info</Trans>
                      </Label>
                    </AutoColumn>
                  </RowBetween>
                  <LightCard padding="12px 16px">
                    <AutoColumn gap="lg">
                      <RowBetween>
                        <ThemedText.DeprecatedMain>
                          <Trans>Total Distributed</Trans>
                        </ThemedText.DeprecatedMain>
                        <AutoColumn justify="center">
                          <ThemedText.SubHeader>
                            {getBalanceAmount(totalDistributed).toFixed(4)} WETH
                          </ThemedText.SubHeader>
                          <ThemedText.DeprecatedItalic>
                            {getFullDisplayBalance(totalDistributedUsd, 18, 2)}{" "}
                            USD
                          </ThemedText.DeprecatedItalic>
                        </AutoColumn>
                      </RowBetween>
                      <RowBetween>
                        <ThemedText.DeprecatedMain>
                          <Trans>Total Staked</Trans>
                        </ThemedText.DeprecatedMain>
                        <AutoColumn justify="center">
                          <ThemedText.SubHeader>
                            {totalStaked} NFTs
                          </ThemedText.SubHeader>
                          {/* <ThemedText.DeprecatedItalic>
                            {getFullDisplayBalance(totalStakedUsd, 18, 2)} USD
                          </ThemedText.DeprecatedItalic> */}
                        </AutoColumn>
                      </RowBetween>
                      <RowBetween>
                        <ThemedText.DeprecatedMain>
                          Stake Holders
                        </ThemedText.DeprecatedMain>
                        <ThemedText.SubHeader>
                          {totalHolders.toString()}
                        </ThemedText.SubHeader>
                      </RowBetween>
                      {/* <RowBetween>
                        <ThemedText.DeprecatedMain>
                          <Trans>Ratio</Trans>
                        </ThemedText.DeprecatedMain>
                        <AutoColumn justify="center">
                          <ThemedText.SubHeader>{getFullDisplayBalance(ratio, 18, 4)} GBT per MHs</ThemedText.SubHeader>
                          <ThemedText.DeprecatedItalic>
                            {getFullDisplayBalance(ratioUSD, 18, 4)} USD per MHs
                          </ThemedText.DeprecatedItalic>
                        </AutoColumn>
                      </RowBetween> */}
                    </AutoColumn>
                  </LightCard>
                </AutoColumn>
              </DarkCard>
              <DarkCard>
                <AutoColumn gap="md" style={{ width: "100%" }}>
                  <AutoColumn gap="md">
                    <RowBetween style={{ alignItems: "flex-start" }}>
                      <AutoColumn gap="md">
                        <Label>
                          <Trans>Wallet Info</Trans>
                        </Label>
                      </AutoColumn>
                    </RowBetween>
                  </AutoColumn>
                  {account ? (
                    <LightCard padding="12px 16px">
                      <AutoColumn gap="lg">
                        <RowBetween>
                          <ThemedText.DeprecatedMain>
                            <Trans>Staked</Trans>
                          </ThemedText.DeprecatedMain>
                          <AutoColumn justify="center">
                            <ThemedText.SubHeader>
                              {stakedBal} NFTs
                            </ThemedText.SubHeader>
                            {/* <ThemedText.DeprecatedItalic>
                              {getFullDisplayBalance(stakedBalUsd, 18, 2)} USD
                            </ThemedText.DeprecatedItalic> */}
                          </AutoColumn>
                        </RowBetween>
                        <RowBetween>
                          <ThemedText.DeprecatedMain>
                            <Trans>Rewards</Trans>
                          </ThemedText.DeprecatedMain>
                          <AutoColumn justify="center">
                            <ThemedText.SubHeader>
                              {getBalanceAmount(pendingBal).toFixed(6)} WETH
                            </ThemedText.SubHeader>
                            <ThemedText.DeprecatedItalic>
                              {getFullDisplayBalance(pendingBalUsd, 18, 2)} USD
                            </ThemedText.DeprecatedItalic>
                          </AutoColumn>
                        </RowBetween>
                        {isInPool ? (
                          <AutoColumn gap="1rem">
                            <ButtonClaim
                              size="10px"
                              disabled={pendingBal.eq(0) || claiming}
                              onClick={handleClaim}
                            >
                              {claiming ? (
                                <>
                                  <Trans>Claiming</Trans>
                                  <CustomLightSpinner
                                    src="/images/blue-loader.svg"
                                    alt="loader"
                                    size="16px"
                                    style={{ marginLeft: "0.5rem" }}
                                  />
                                </>
                              ) : (
                                <Trans>Claim</Trans>
                              )}
                            </ButtonClaim>
                            <RowBetween>
                              <ButtonStake
                                size="10px"
                                width="45%"
                                onClick={() => handleOpenModal(1)}
                              >
                                <Trans>Stake</Trans>
                              </ButtonStake>
                              <ButtonUnstake
                                size="10px"
                                width="45%"
                                onClick={() => handleOpenModal(2)}
                              >
                                <Trans>Unstake</Trans>
                              </ButtonUnstake>
                            </RowBetween>
                          </AutoColumn>
                        ) : (
                          <ButtonPrimary
                            size="10px"
                            onClick={() => handleOpenModal(1)}
                          >
                            <Trans>Stake</Trans>
                          </ButtonPrimary>
                        )}
                      </AutoColumn>
                    </LightCard>
                  ) : (
                    <ButtonPrimary onClick={toggleAccountDrawer}>
                      <Trans>Connect Wallet</Trans>
                    </ButtonPrimary>
                  )}
                </AutoColumn>
              </DarkCard>
            </AutoColumn>
          </ResponsiveRow>
        </AutoColumn>
      </PageWrapper>
      {/* <SwitchLocaleLink /> */}
      <StakeModal
        walletData={walletData}
        poolData={poolData}
        walletPoolData={walletPoolData}
        chainId={activeChain}
        isOpen={openModal === 1}
        onDismiss={() => setOpenModal(0)}
      />
      <UnstakeModal
        walletData={walletData}
        poolData={poolData}
        walletPoolData={walletPoolData}
        isOpen={openModal === 2}
        onDismiss={() => setOpenModal(0)}
      />
    </>
  );
}
