import { Trans } from "@lingui/macro";
import EyeIcon from "assets/images/home/eye.png";
import GemIcon from "assets/images/home/gem.png";
import MissionIcon from "assets/images/home/mission.png";
import { BluePrimaryCard } from "components/Card";
/* import Slide, { Roll } from "react-reveal"; */
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { isMobile } from "utils/userAgent";

const Container = styled.div`
  margin-top: 8rem;
`;

const ResponsiveBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    flex-direction: column;
    gap: 2rem;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    gap: 2rem;
  `};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const PositionImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export function AboutUs() {
  return (
    <Container>
      <ThemedText.Title textAlign="center" style={{ color: "#39AF45" }}>
        <Trans>About Us</Trans>
      </ThemedText.Title>
      <ResponsiveBox>
        <BluePrimaryCard height={481}>
          <Column>
            <PositionImg>
              <img src={MissionIcon} alt="mission-icon" />
            </PositionImg>
            <ThemedText.LargeHeader mt="4rem" style={{ color: "#39AF45" }}>
              <Trans>Mission</Trans>
            </ThemedText.LargeHeader>
            <ThemedText.MediumHeader
              mt="2rem"
              width={isMobile ? "100%" : "296px"}
              height={300}
            >
              <Trans>
                Greenblock powers the most innovative and sustainable blockchain
                infrastructure in the world
              </Trans>
              .
            </ThemedText.MediumHeader>
          </Column>
        </BluePrimaryCard>
        <BluePrimaryCard height={481}>
          <Column>
            <PositionImg>
              <img src={EyeIcon} alt="eye-icon" />
            </PositionImg>
            <ThemedText.LargeHeader mt="4rem" style={{ color: "#39AF45" }}>
              <Trans>Vision</Trans>
            </ThemedText.LargeHeader>
            <ThemedText.MediumHeader
              mt="2rem"
              width={isMobile ? "100%" : "296px"}
              height={300}
            >
              <Trans>
                Transform and democratize the global financial ecosystem for a
                more equitable and sustainable world
              </Trans>
              .
            </ThemedText.MediumHeader>
          </Column>
        </BluePrimaryCard>
        <BluePrimaryCard height={481}>
          <Column>
            <PositionImg>
              <img src={GemIcon} alt="gem-icon" />
            </PositionImg>
            <ThemedText.LargeHeader mt="4rem" style={{ color: "#39AF45" }}>
              <Trans>Valours</Trans>
            </ThemedText.LargeHeader>
            <ThemedText.MediumHeader
              mt="2rem"
              width={isMobile ? "100%" : "296px"}
              height={300}
            >
              <Trans>
                <span style={{ color: "#39AF45" }}>Innovation</span>: Developing
                software for useful tools.{" "}
                <span style={{ color: "#39AF45" }}>Integrity</span>:
                Transparent, values-based decision-making.{" "}
                <span style={{ color: "#39AF45" }}>Agility</span>: Global,
                robust team for optimal solutions
              </Trans>
              .
            </ThemedText.MediumHeader>
          </Column>
        </BluePrimaryCard>
      </ResponsiveBox>
    </Container>
  );
}
