import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { ButtonEmpty, ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoRow } from 'components/Row'
import Tooltip from 'components/Tooltip'
import { transparentize } from 'polished'
import { useState } from 'react'
import { CheckCircle, ChevronDown, ChevronUp, XCircle } from 'react-feather'
import { LeftRibbon, RibbonContainer } from 'react-ribbons'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

const Wrapper = styled.div`
  width: 70%;
  padding: 16px;
  margin: auto;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`

const StyledPositionCard = styled(LightCard)<{ bgColor: any }>`
  border: none;
  background: ${({ theme, bgColor }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${transparentize(0.8, bgColor)} 20%, ${theme.background} 100%) `};
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
`

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '0fr 0.8fr 1fr 1fr' : '0fr 0.5fr 1fr 1.2fr 1fr')};
  padding: 8px;
`

const StyledRibbonContainer = styled(RibbonContainer)`
  min-height: 1px;
`

interface CaveCardProps {
  id: number
  miners: number
  expiration: string
  hasVipStaked: boolean
  jamonStaked: string
  onSale: boolean
}

export default function CaveCard({ id, miners, expiration, hasVipStaked, jamonStaked, onSale }: CaveCardProps) {
  
  const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))

  const [showMore, setShowMore] = useState(false)

  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const handleOpenTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const stake = new BigNumber(jamonStaked)
  const dateExpiration = new Date(Number(expiration) * 1000)

  return (
    <StyledRibbonContainer>
      <StyledPositionCard bgColor={darkmode ? '#4D8FEA' : '#4D8F67'}>
        <ResponsiveGrid isMobile={isMobile}>
          <ThemedText.DeprecatedBody>{id}</ThemedText.DeprecatedBody>
          <Tooltip
            text={
              <ThemedText.DeprecatedBody textAlign="center">
                <Trans>Bonus stake JAMON or deposit NFT Vip</Trans>
              </ThemedText.DeprecatedBody>
            }
            show={showTooltip}
          >
            {hasVipStaked || stake.gt(0) ? (
              <ButtonEmpty onMouseEnter={handleOpenTooltip} onMouseLeave={handleOpenTooltip} size={5}>
                <CheckCircle color="green" />
              </ButtonEmpty>
            ) : (
              <ButtonEmpty onMouseEnter={handleOpenTooltip} onMouseLeave={handleOpenTooltip} size={5}>
                <XCircle color="#F3B71E" />
              </ButtonEmpty>
            )}
          </Tooltip>
          <ThemedText.DeprecatedBody fontSize={isMobile ? 14 : 16}>
            {miners}/5 <Trans>Miners</Trans>
          </ThemedText.DeprecatedBody>
          {!isMobile && (
            <AutoRow>
              <ThemedText.DeprecatedBody>
                <Trans>Expires on</Trans> {dateExpiration.toLocaleString()}
              </ThemedText.DeprecatedBody>
            </AutoRow>
          )}
          {isMobile ? (
            <ButtonEmpty padding="6px 8px" $borderRadius="12px" width="100%" onClick={() => setShowMore(!showMore)}>
              {showMore ? (
                <>
                  <Trans>Manage</Trans>
                  <ChevronUp size="20" style={{ marginLeft: '8px', height: '20px', minWidth: '20px' }} />
                </>
              ) : (
                <>
                  <Trans>Manage</Trans>
                  <ChevronDown size="20" style={{ marginLeft: '8px', height: '20px', minWidth: '20px' }} />
                </>
              )}
            </ButtonEmpty>
          ) : (
            <ButtonEmpty as={Link} to={`/manage-cave/${id}`} padding="6px 8px" $borderRadius="12px" width="100%">
              <Trans>Manage</Trans>
            </ButtonEmpty>
          )}
        </ResponsiveGrid>
        {isMobile && showMore && (
          <Wrapper>
            <Row>
              <ThemedText.DeprecatedBody>ID:</ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody>{id}</ThemedText.DeprecatedBody>
            </Row>
            <Row>
              <ThemedText.DeprecatedBody>
                <Trans>Expires on</Trans>:
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody>{dateExpiration.toLocaleString()}</ThemedText.DeprecatedBody>
            </Row>
            <Row>
              <ButtonPrimary
                as={Link}
                to={`/manage-cave/${id}`}
                padding="8px"
                margin="auto"
                $borderRadius="8px"
                width="100%"
                mt="1rem"
              >
                <Trans>Manage</Trans>
              </ButtonPrimary>
            </Row>
          </Wrapper>
        )}
      </StyledPositionCard>
      {/* {onSale && (
        <LeftRibbon
          className="custom"
          backgroundColor={darkmode ? '#4D8FEA' : '#4D8F67'}
          color="#fff"
          fontFamily="Poppins"
        >
          <Trans>On Sale</Trans>
        </LeftRibbon>
      )} */}
    </StyledRibbonContainer>
  )
}
