import { t, Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import Modal from 'components/Modal'
import { RowBetween } from 'components/Row'
import { usePresaleNFTContract } from 'hooks/useContract'
import { useState } from 'react'
import { X } from 'react-feather'
import { useCloseModal } from 'state/application/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { getFullDisplayBalance } from 'utils/formatBalance'

const MOONPAY_DARK_BACKGROUND = '#1c1c1e'
const Wrapper = styled.div<{ isDarkMode: boolean }>`
  // #1c1c1e is the background color for the darkmode moonpay iframe as of 2/16/2023
  background-color: ${({ isDarkMode, theme }) => (isDarkMode ? MOONPAY_DARK_BACKGROUND : theme.white)};
  border-radius: 20px;
  box-shadow: ${({ theme }) => theme.deepShadow};
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  flex: 1 1;
  min-width: 375px;
  position: relative;
  width: 100%;
  padding: 1rem;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.textPrimary};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const RowCenter = styled.div`
  display: grid;
  place-items: center;
  margin-top: 1rem;
`

interface MintModalProps {
  isOpen: boolean
  onDismiss: () => void
  amount: number
  price: BigNumber
  useGBT: boolean
}

// eslint-disable-next-line import/no-unused-modules
export default function MintModal({ isOpen, onDismiss, amount, price, useGBT }: MintModalProps) {
  const isDarkMode = useIsDarkMode()
  const closeModal = useCloseModal()
  const [confirming, setConfirming] = useState(false)

  const getPrimaryText = () => {
    if (confirming) {
      return t`Confirming...`
    }
    return t`Confirm`
  }

  const presaleNFT = usePresaleNFTContract()

  const handleMint = async () => {
    setConfirming(true)
    try {
      const tx = await presaleNFT?.buy(amount, useGBT)
      await tx?.wait()
      setConfirming(false)
      onDismiss()
    } catch (error) {
      console.log(error)
      setConfirming(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={closeModal}>
      <Wrapper isDarkMode={isDarkMode}>
        <RowBetween>
          <ThemedText.DeprecatedMediumHeader>
            <Trans>Mint</Trans>
          </ThemedText.DeprecatedMediumHeader>
          <StyledClosed>
            <X size={24} onClick={onDismiss} />
          </StyledClosed>
        </RowBetween>
        <LightCard mt="1rem">
          <AutoColumn gap="1rem">
            <RowBetween>
              <ThemedText.BodySecondary>
                <Trans>Amount</Trans>
              </ThemedText.BodySecondary>
              <ThemedText.BodyPrimary>{amount} NFTs</ThemedText.BodyPrimary>
            </RowBetween>
            <RowBetween>
              <ThemedText.BodySecondary>
                <Trans>Price</Trans>
              </ThemedText.BodySecondary>
              <ThemedText.BodyPrimary>
                {getFullDisplayBalance(price)} {useGBT ? 'GBT' : 'USDT'}
              </ThemedText.BodyPrimary>
            </RowBetween>
          </AutoColumn>
        </LightCard>
        <RowCenter>
          <ButtonPrimary width="fit-content" disabled={confirming} onClick={handleMint}>
            {getPrimaryText()}
          </ButtonPrimary>
        </RowCenter>
      </Wrapper>
    </Modal>
  )
}
