// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { ButtonPrimary, ButtonRadioChecked } from 'components/Button'
import React, { useMemo, useState } from 'react'
import { Info } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import AddCaveModal from '../Actions/AddCaveModal'
import CaveCard from './CaveCard'
import { useWeb3React } from '@web3-react/core'

const FilterOptions = styled.div<{ account?: boolean; isMobile?: boolean }>`
  display: grid;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${({ account }) => (account ? (isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr 1fr') : '1fr 1fr 1fr')};
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const ButtonChecked = styled(ButtonRadioChecked)<{ isMobile?: boolean; account?: boolean }>`
  width: ${({ isMobile, account }) => (account ? (isMobile ? '80%' : '90%') : isMobile ? '80%' : '90%')};
  font-size: ${({ isMobile }) => (isMobile ? '10px' : '16px')};
  height: 2.5em;
  margin: auto;
  border-radius: 20px;
  border-color: ${({ theme }) => theme.deprecated_primary2};
`

const SearchFarm = styled.input<{ isMobile?: boolean; account?: boolean }>`
  width: ${({ isMobile, account }) => (account ? (isMobile ? '80%' : '90%') : isMobile ? '80%' : '90%')};
  height: 2.5em;
  text-align: center;
  font-size: ${({ isMobile }) => (isMobile ? '10px' : '16px')};
  color: ${({ theme }) => theme.deprecated_text4};
  background: transparent;
  border-color: ${({ theme }) => theme.deprecated_primary2};
  border-radius: 20px;
  margin: auto;
  outline: none;
`
const ButtonCaves = styled(ButtonPrimary)<{ account?: boolean }>`
  width: 90%;
  height: 2.5em;
  text-align: center;
  font-size: 16px;
  color: white;
  border-radius: 20px;
  margin: auto;
  outline: none;
`

const ButtonCavesMobile = styled(ButtonPrimary)`
  width: 40%;
  height: 2.5em;
  text-align: center;
  font-size: 10px;
  color: ${({ theme }) => theme.deprecated_text4};
  border-radius: 20px;
  margin: auto;
  outline: none;
  margin-bottom: 2rem;
`

const Dropdown = styled.select<{ isMobile?: boolean; account?: boolean }>`
  width: ${({ isMobile, account }) => (account ? (isMobile ? '80%' : '90%') : isMobile ? '80%' : '90%')};
  height: 2.5em;
  text-align: center;
  font-size: ${({ isMobile }) => (isMobile ? '10px' : '16px')};
  color: #fff;
  background: ${({ theme }) => theme.deprecated_primary2};
  border: none;
  border-radius: 20px;
  margin: auto;
`

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`

const Arrow = styled.div`
  color: ${({ theme }) => theme.deprecated_text4};
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`

const BodyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top 4rem;
  margin-bottom: 1rem;
`

interface MinerRow {
  id: string
  owner: string
  cave: {
    id: number
  }
  expiration: string
  stakedPower: string
  onSale: boolean
  salePrice: string
}

interface CaveRow {
  id: string
  owner: string
  expiration: string
  stakedPower: string
  jamonStaked: string
  jamonUnlockDate: string
  hasVipStaked: boolean
  vipId: string
  onSale: boolean
  salePrice: string
  miners: MinerRow[]
}

interface CavesData {
  caves?: CaveRow[]
}

export default function CavesList({ caves }: CavesData) {
  const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))
  const { account, chainId, connector } = useWeb3React()

  const [openModal, setOpenModal] = useState(false)
  const handleDissmis = () => {
    setOpenModal(false)
  }

  const activeChain = chainId ? chainId : 137

  const [onSale, setOnSale] = useState<boolean>(false)
  const handleOnSale = () => {
    setOnSale(!onSale)
    setPage(1)
  }

  const [showUserCaves, setShowUserCaves] = useState<boolean>(false)
  const handleUserShowCaves = () => {
    setShowUserCaves(!showUserCaves)
    setPage(1)
  }

  const [query, setQuery] = useState('')
  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const [showOrder, setShowOrder] = useState(0)

  const [page, setPage] = useState(1)
  const ITEMS = isMobile ? 5 : 10

  let totalCaves = caves

  if (showUserCaves) {
    totalCaves = caves?.filter((item) => item.owner.toLowerCase() === account?.toLowerCase())
  }

  if (onSale) {
    if (showUserCaves) {
      totalCaves = caves?.filter((item) => item.owner.toLowerCase() === account?.toLowerCase() && item.onSale)
    } else {
      totalCaves = caves?.filter((item) => item.onSale)
    }
  }

  switch (showOrder) {
    case 1:
      totalCaves = caves?.filter((item) => item.miners.length < 5)
      break
    case 2:
      caves?.sort((a, b) => parseInt(b.miners.length.toFixed()) - parseInt(a.miners.length.toFixed()))
      break
    case 3:
      caves?.sort((a, b) => parseInt(b.expiration) - parseInt(a.expiration))
      break
    default:
      caves?.sort((a, b) => Number(a.id) - Number(b.id))
      break
  }

  if (query) {
    totalCaves = caves?.filter((item) => Number(item.id) === Number(query))
  }

  const sortedCaves = useMemo(() => {
    if (totalCaves) {
      return totalCaves.length > 0 ? totalCaves.slice(ITEMS * (page - 1), page * ITEMS) : []
    }
    return []
  }, [totalCaves, ITEMS, page])

  const maxPage = totalCaves && totalCaves.length > 0 ? Math.ceil(totalCaves.length / ITEMS) : 0

  return (
    <>
      <FilterOptions account={Boolean(account)} isMobile={isMobile}>
        {account && (
          <ButtonChecked
            account={Boolean(account)}
            active={showUserCaves}
            onClick={handleUserShowCaves}
            isMobile={isMobile}
          >
            <Trans>My Stake</Trans>
          </ButtonChecked>
        )}
        <ButtonChecked account={Boolean(account)} active={onSale} onClick={handleOnSale} isMobile={isMobile}>
          <Trans>On Sale</Trans>
        </ButtonChecked>
        <Dropdown
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setShowOrder(Number(event.target.value))}
          isMobile={isMobile}
          account={Boolean(account)}
        >
          <option value={0} selected>{t`Order By`}</option>
          <option value={1}>{t`Available`}</option>
          <option value={2}>{t`Miners`}</option>
          <option value={3}>{t`Expiration`}</option>
        </Dropdown>
        <SearchFarm
          account={Boolean(account)}
          isMobile={isMobile}
          placeholder={t`Search ID`}
          onChange={handleChangeQuery}
        />
        {account && !isMobile && (
          <ButtonCaves onClick={() => setOpenModal(true)}>
            + <Trans>Add Cave</Trans>
          </ButtonCaves>
        )}
      </FilterOptions>
      {account && isMobile && (
        <ButtonCavesMobile onClick={() => setOpenModal(true)}>
          + <Trans>Add Cave</Trans>
        </ButtonCavesMobile>
      )}
      {sortedCaves.map((item, index) => (
        <React.Fragment key={index}>
          <CaveCard
            id={Number(item.id)}
            miners={item.miners.length}
            expiration={item.expiration}
            hasVipStaked={item.hasVipStaked}
            jamonStaked={item.jamonStaked}
            onSale={item.onSale}
          />
        </React.Fragment>
      ))}
      {sortedCaves && sortedCaves.length > 0 ? (
        <PageButtons>
          <Arrow
            onClick={() => {
              setPage(page === 1 ? page : page - 1)
            }}
          >
            <ThemedText.DeprecatedBody color={page === 1 ? 'text' : 'primary'}> {'<'} </ThemedText.DeprecatedBody>
          </Arrow>

          <ThemedText.DeprecatedBody>
            <Trans>Page</Trans> {page} <Trans>of</Trans> {maxPage}
          </ThemedText.DeprecatedBody>
          <Arrow
            onClick={() => {
              setPage(page === maxPage ? page : page + 1)
            }}
          >
            <ThemedText.DeprecatedBody color={page === maxPage ? 'textDisabled' : 'primary'}> {'>'} </ThemedText.DeprecatedBody>
          </Arrow>
        </PageButtons>
      ) : (
        <BodyMessage>
          <Info color={darkmode ? '#5199FF' : '#2172E5'} />
          <ThemedText.DeprecatedBlue textAlign="center" ml="0.5rem" fontSize={24}>
            <Trans>No caves available</Trans>
          </ThemedText.DeprecatedBlue>
        </BodyMessage>
      )}
      <AddCaveModal isOpen={openModal} onDismiss={handleDissmis} chainId={activeChain} />
    </>
  )
}
