// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import Circle from 'assets/images/blue-loader.svg'
import MineIcon from 'assets/images/home/logo-title-color.png'
import BigNumber from 'bignumber.js'
import { ButtonPrimary } from 'components/Button'
import { SUBGRAPH_URL } from 'constants/subgraph'
import request, { gql } from 'graphql-request'
import useRefresh from 'hooks/useRefresh'
import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { CustomLightSpinner, ThemedText } from 'theme'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { isMobile } from 'utils/userAgent'

import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import CavesList from './components/Caves/CavesList'
import MinersList from './components/Miners/MinersList'
import useNFTsBalance from './hooks/useNFTsBalance'

const PageWrapper = styled.div`
  max-width: 1000px;
  width: 100%;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    max-width: 500px;
    margin-top: 5rem;
  `};
`

const Heading = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
`

const HeadingInfo = styled.div<{ isMobile?: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '45%')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.deprecated_primary2};
  border-radius: 20px;
  padding: 24px;
  margin-top: ${({ isMobile }) => (isMobile ? '2rem' : '0')};
`

const ButtonsSection = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
`

interface MinerRow {
  id: string
  owner: string
  cave: {
    id: number
  }
  expiration: string
  stakedPower: string
  onSale: boolean
  salePrice: string
}

interface CaveRow {
  id: string
  owner: string
  expiration: string
  stakedPower: string
  jamonStaked: string
  jamonUnlockDate: string
  hasVipStaked: boolean
  vipId: string
  onSale: boolean
  salePrice: string
  miners: MinerRow[]
}

interface MineData {
  totalCaves: string
  totalMiners: string
  cavesPower: string
  minersPower: string
  totalPower: string
  caves: CaveRow[]
  miners: MinerRow[]
}

const allowedChains = (chainId: number) => {
  let supported = false
  switch (chainId) {
    case 137:
    case 80001:
      supported = true
      break
    default:
      supported = false
      break
  }
  return supported
}

export default function Mine() {
  const { chainId, connector } = useWeb3React()
  const [mine, setMine] = useState<MineData>()
  const [section, setSection] = useState(0)
  const { slowRefresh } = useRefresh()

  const activeChain = chainId ? chainId : 137

  const apiUrl = SUBGRAPH_URL[activeChain]

  const NFTsBalance = useNFTsBalance(activeChain)

  useEffect(() => {
    request(
      apiUrl,
      gql`
        query {
          mines(first: 1) {
            totalCaves
            totalMiners
            cavesPower
            minersPower
            totalPower
            caves(orderBy: id, orderDirection: asc, first: 1000) {
              id
              owner
              expiration
              stakedPower
              jamonStaked
              jamonUnlockDate
              hasVipStaked
              vipId
              onSale
              salePrice
              miners {
                id
                owner
                cave {
                  id
                }
                expiration
                stakedPower
                onSale
                salePrice
              }
            }
            miners(orderBy: id, orderDirection: asc, first: 1000) {
              id
              owner
              cave {
                id
              }
              expiration
              stakedPower
              onSale
              salePrice
            }
          }
        }
      `
    )
      .then(({ mines }) => {
        if (mines.length > 0) {
          setMine(mines[0])
        }
      })
      .catch(({ error }) => {
        console.log(error)
      })
  }, [apiUrl, NFTsBalance, slowRefresh])

  const supportedChain = allowedChains(activeChain)
  const location = useLocation()
  if (!supportedChain) {
    return <Navigate to={{ ...location, pathname: '/home' }} replace />
  }

  return (
    <>
      <PageWrapper>
        <Heading isMobile={isMobile}>
          <img src={MineIcon} alt="" width={isMobile ? '100%' : 450} />
          <HeadingInfo isMobile={isMobile}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <ThemedText.DeprecatedBody textAlign="left">
                <Trans>Total Caves</Trans>:
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody textAlign="left">
                <Trans>Total Miners</Trans>:
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody textAlign="left">
                <Trans>Caves Power</Trans>:
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody textAlign="left">
                <Trans>Miners Power</Trans>:
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody textAlign="left">
                <Trans>Total Power</Trans>:
              </ThemedText.DeprecatedBody>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <ThemedText.DeprecatedBody textAlign="right">
                {mine ? (
                  mine.totalCaves
                ) : (
                  <CustomLightSpinner src={Circle} alt="loader" size="16px" style={{ marginLeft: '0.5rem' }} />
                )}
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody textAlign="right">
                {mine ? (
                  mine.totalMiners
                ) : (
                  <CustomLightSpinner src={Circle} alt="loader" size="16px" style={{ marginLeft: '0.5rem' }} />
                )}
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody textAlign="right">
                {mine ? (
                  <div>{getFullDisplayBalance(new BigNumber(mine.cavesPower), 6, 2)} MHs</div>
                ) : (
                  <CustomLightSpinner src={Circle} alt="loader" size="16px" style={{ marginLeft: '0.5rem' }} />
                )}
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody textAlign="right">
                {mine ? (
                  <div>{getFullDisplayBalance(new BigNumber(mine.minersPower), 6, 2)} MHs</div>
                ) : (
                  <CustomLightSpinner src={Circle} alt="loader" size="16px" style={{ marginLeft: '0.5rem' }} />
                )}
              </ThemedText.DeprecatedBody>
              <ThemedText.DeprecatedBody textAlign="right">
                {mine ? (
                  <div>{getFullDisplayBalance(new BigNumber(mine.totalPower), 6, 2)} MHs</div>
                ) : (
                  <CustomLightSpinner src={Circle} alt="loader" size="16px" style={{ marginLeft: '0.5rem' }} />
                )}
              </ThemedText.DeprecatedBody>
            </div>
          </HeadingInfo>
        </Heading>
        <ButtonsSection>
          <ButtonPrimary
            size="10px"
            width={isMobile ? '6rem' : '10rem'}
            onClick={() => setSection(0)}
            disabled={section === 0}
            mr="1rem"
          >
            <Trans>Caves</Trans>
          </ButtonPrimary>
          <ButtonPrimary
            size="10px"
            width={isMobile ? '6rem' : '10rem'}
            onClick={() => setSection(1)}
            disabled={section === 1}
            ml="1rem"
          >
            <Trans>Miners</Trans>
          </ButtonPrimary>
        </ButtonsSection>
        {section === 0 && <CavesList caves={mine?.caves} />}
        {section === 1 && <MinersList miners={mine?.miners} chainId={activeChain} />}
      </PageWrapper>
      <SwitchLocaleLink />
    </>
  )
}
