import Logo from "assets/images/menu/logo-title-white.png";
import styled from "styled-components/macro";
import { isMobile } from "utils/userAgent";

import NavMenu from "./Menu";
import MenuMobile from "./MenuMobile";

const Nav = styled.div`
  padding: 2rem;
  width: 100%;
  height: 118px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    padding: 0.8rem;
    height: 100px;
  `};
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    padding: 0.8rem;
    height: 100px;
  `};
`;

export default function NavBarMenu() {
  return (
    <Nav>
      <img
        src={Logo}
        alt="gbc-logo"
        width={isMobile ? 110 : 224}
        height={isMobile ? 45 : 86}
      />
      {isMobile ? <MenuMobile /> : <NavMenu />}
    </Nav>
  );
}
