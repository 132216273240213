/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { ButtonPrimary } from 'components/Button'
import { BlueCard } from 'components/Card'
import { SUBGRAPH_URL } from 'constants/subgraph'
import { formatDistanceToNowStrict } from 'date-fns'
import { enGB, es } from 'date-fns/locale'
import request, { gql } from 'graphql-request'
import { useActiveLocale } from 'hooks/useActiveLocale'
import { useLockerContract } from 'hooks/useContract'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { CustomLightSpinner, ThemedText } from 'theme'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { isMobile } from 'utils/userAgent'

import { currentTimestamp } from '..'
import { WalletBalancesData } from '../types'
import { useWeb3React } from '@web3-react/core'

const TableWrapper = styled.div`
  flex-direction: column;
`

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr 1fr 1fr' : '1fr 0.5fr 0.5fr 1fr')};
  margin-bottom: 1rem;
`

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`

const Arrow = styled.div`
  color: ${({ theme }) => theme.deprecated_text4};
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`

const Row = styled(ThemedText.DeprecatedBody)<{ isWallet?: boolean }>`
  text-align: center;
  color: ${({ isWallet, theme }) => (isWallet ? theme.deprecated_yellow1 : '')};
`

interface LockRow {
  id: string
  minerId: string
  amountLocked: string
  lockDate: string
  unlockDate: string
}

interface LockHistoryProps {
  chainId: number
  walletData: WalletBalancesData
}

interface RowProps {
  index: number
  walletData: WalletBalancesData
  rowData: LockRow
}

const RenderRow: React.FC<RowProps> = ({ index, walletData, rowData }) => {
  const [confirming, setConfirming] = useState(false)
  const lockerContract = useLockerContract()
  const activeLocale = useActiveLocale()
  const datePurchase = formatDistanceToNowStrict(parseInt(rowData.lockDate, 10) * 1000, {
    addSuffix: true,
    locale: activeLocale === 'es-ES' ? es : enGB,
  })
  const dateWithdraw = formatDistanceToNowStrict(parseInt(rowData.unlockDate, 10) * 1000, {
    addSuffix: true,
    locale: activeLocale === 'es-ES' ? es : enGB,
  })

  const canWithdrawn = Number(rowData.unlockDate) * 1000 < currentTimestamp()

  const handleRemovve = useCallback(
    async (id: string) => {
      try {
        setConfirming(true)
        const tx = await lockerContract?.unlockJamon(id)
        await tx?.wait()
        walletData.fetchWalletData()
        setConfirming(false)
      } catch (e) {
        setConfirming(false)
        console.error(e)
      } finally {
        setConfirming(false)
      }
    },
    [lockerContract, walletData]
  )

  return (
    <React.Fragment key={index}>
      <Row fontSize={isMobile ? 12 : 16}>{datePurchase}</Row>
      {!isMobile && (
        <Row fontSize={isMobile ? 12 : 16}>{getFullDisplayBalance(new BigNumber(rowData.amountLocked))}</Row>
      )}
      <Row fontSize={isMobile ? 12 : 16}>{rowData.minerId}</Row>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ButtonPrimary
          width="fit-content"
          size="10px"
          disabled={!canWithdrawn || confirming}
          onClick={() => handleRemovve(rowData.id)}
        >
          {!canWithdrawn ? (
            dateWithdraw
          ) : confirming ? (
            <>
              <Trans>Confirming</Trans>
              <CustomLightSpinner
                src="/images/blue-loader.svg"
                alt="loader"
                size={'24px'}
                style={{ marginLeft: '0.5rem' }}
              />
            </>
          ) : (
            <Trans>Withdraw</Trans>
          )}
        </ButtonPrimary>
      </div>
    </React.Fragment>
  )
}
const LockHistory: React.FC<LockHistoryProps> = ({ chainId, walletData }) => {
  const { account } = useWeb3React()
  const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))

  const [page, setPage] = useState(1)

  const apiUrl = SUBGRAPH_URL[chainId]

  const [history, setHistory] = useState<LockRow[]>([])

  useEffect(() => {
    request(
      apiUrl,
      gql`
        query GetHolderInfo($address: String!) {
          holders(where: { id: $address }) {
            locks(orderBy: lockDate, orderDirection: desc, first: 1000) {
              id
              minerId
              amountLocked
              lockDate
              unlockDate
            }
          }
        }
      `,
      {
        address: account?.toLowerCase(),
      }
    )
      .then(({ holders }) => {
        if (holders.length > 0) {
          setHistory(holders[0].locks)
        }
      })
      .catch(({ error }) => {
        console.log(error)
      })
  }, [account, apiUrl, walletData.jamonBalance])

  const ITEMS = isMobile ? 5 : 10

  const sortedHistory = useMemo(() => {
    return history.length > 0 ? history.slice(ITEMS * (page - 1), page * ITEMS) : []
  }, [history, page])

  const maxPage = sortedHistory.length > 0 ? Math.ceil(sortedHistory.length / ITEMS) : 0

  return history.length > 0 ? (
    <BlueCard mt="2rem">
      <ThemedText.LargeHeader textAlign="center" marginY="1rem" color={darkmode ? '#5199FF' : '#60CF3C'}>
        <Trans>My Locks</Trans>
      </ThemedText.LargeHeader>
      {history.length > 0 ? (
        <TableWrapper>
          <ResponsiveGrid isMobile={isMobile}>
            <ThemedText.DeprecatedBody fontSize={isMobile ? 12 : 16} textAlign="center" color="secondary3">
              <Trans>Lock Date</Trans>
            </ThemedText.DeprecatedBody>
            {!isMobile && (
              <ThemedText.DeprecatedBody fontSize={isMobile ? 12 : 16} textAlign="center" color="secondary3">
                <Trans>Amount</Trans>
              </ThemedText.DeprecatedBody>
            )}
            <ThemedText.DeprecatedBody fontSize={isMobile ? 12 : 16} textAlign="center" color="secondary3">
              NFT ID
            </ThemedText.DeprecatedBody>
            <ThemedText.DeprecatedBody fontSize={isMobile ? 12 : 16} textAlign="center" color="secondary3">
              <Trans>Unlock Date</Trans>
            </ThemedText.DeprecatedBody>
            {sortedHistory.map((item, index) => {
              return <RenderRow key={index} index={index} walletData={walletData} rowData={item} />
            })}
          </ResponsiveGrid>
          <PageButtons>
            <Arrow
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <ThemedText.DeprecatedBody color={page === 1 ? 'text' : 'primary'}> {'<'} </ThemedText.DeprecatedBody>
            </Arrow>

            <ThemedText.DeprecatedBody>
              <Trans>Page</Trans> {page} <Trans>of</Trans> {maxPage}
            </ThemedText.DeprecatedBody>
            <Arrow
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <ThemedText.DeprecatedBody color={page === maxPage ? 'textDisabled' : 'primary'}> {'>'} </ThemedText.DeprecatedBody>
            </Arrow>
          </PageButtons>
        </TableWrapper>
      ) : (
        <ThemedText.DeprecatedYellow fontSize={isMobile ? '18px' : '20px'} textAlign="center">
          <Trans>No history</Trans>!
        </ThemedText.DeprecatedYellow>
      )}
    </BlueCard>
  ) : null
}

export default LockHistory
