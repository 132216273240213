// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { ButtonError, ButtonRadioChecked } from 'components/Button'
import React, { useMemo, useState } from 'react'
import { Info } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import BurnMinerModal from '../Actions/BurnMinerModal'
import MinerCard from './MinerCard'
import { useWeb3React } from '@web3-react/core'

const FilterOptions = styled.div<{ account?: boolean; isMobile?: boolean }>`
  display: grid;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${({ account }) => (account ? (isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr') : '1fr 1fr 1fr')};
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const ButtonChecked = styled(ButtonRadioChecked)<{ isMobile?: boolean; account?: boolean }>`
  width: ${({ isMobile, account }) => (account ? (isMobile ? '80%' : '90%') : isMobile ? '80%' : '90%')};
  font-size: ${({ isMobile }) => (isMobile ? '10px' : '16px')};
  height: 2.5em;
  margin: auto;
  border-radius: 20px;
  border-color: ${({ theme }) => theme.deprecated_primary2};
`
const WrappedButtonError = styled(ButtonError)<{ isMobile?: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '80%' : '90%')};
  font-size: ${({ isMobile }) => (isMobile ? '10px' : '16px')};
  height: 2.5em;
  margin: auto;
  border-radius: 20px;
  border-color: ${({ theme }) => theme.deprecated_primary2};
`

const SearchFarm = styled.input<{ isMobile?: boolean; account?: boolean }>`
  width: ${({ isMobile, account }) => (account ? (isMobile ? '80%' : '90%') : isMobile ? '80%' : '90%')};
  height: 2.5em;
  text-align: center;
  font-size: ${({ isMobile }) => (isMobile ? '10px' : '16px')};
  color: ${({ theme }) => theme.deprecated_text4};
  background: transparent;
  border-color: ${({ theme }) => theme.deprecated_primary2};
  border-radius: 20px;
  margin: auto;
  outline: none;
`

const Dropdown = styled.select<{ isMobile?: boolean; account?: boolean }>`
  width: ${({ isMobile, account }) => (account ? (isMobile ? '80%' : '90%') : isMobile ? '80%' : '90%')};
  height: 2.5em;
  text-align: center;
  font-size: ${({ isMobile }) => (isMobile ? '10px' : '16px')};
  color: #fff;
  background: ${({ theme }) => theme.deprecated_primary2};
  border: none;
  border-radius: 20px;
  margin: auto;
`

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`

const Arrow = styled.div`
  color: ${({ theme }) => theme.deprecated_text4};
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`

const BodyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top 4rem;
  margin-bottom: 1rem;
`

interface MinerRow {
  id: string
  owner: string
  cave: {
    id: number
  }
  expiration: string
  stakedPower: string
  onSale: boolean
  salePrice: string
}

interface MinersData {
  miners?: MinerRow[]
  chainId: number
}

export default function MinersList({ miners, chainId }: MinersData) {
  const { account } = useWeb3React()
  
  const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))

  const [showUserMiners, setshowUserMiners] = useState<boolean>(false)
  const [showBurnModal, setshowBurnModal] = useState<boolean>(false)

  const handleDissmisBurnModal = () => {
    setshowBurnModal(false)
  }

  const handleUserShowMiners = () => {
    setshowUserMiners(!showUserMiners)
    setPage(1)
  }

  const [query, setQuery] = useState('')
  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const [showOrder, setShowOrder] = useState(0)

  const [page, setPage] = useState(1)
  const ITEMS = isMobile ? 5 : 10

  let totalMiners = miners

  if (showUserMiners) {
    totalMiners = miners?.filter((item) => item.owner.toLowerCase() === account?.toLowerCase())
  }

  switch (showOrder) {
    case 1:
      miners?.sort((a, b) => a.cave.id - b.cave.id)
      break
    case 2:
      miners?.sort((a, b) => parseInt(b.stakedPower) - parseInt(a.stakedPower))
      break
    case 3:
      miners?.sort((a, b) => parseInt(b.expiration) - parseInt(a.expiration))
      break
    default:
      miners?.sort((a, b) => Number(a.id) - Number(b.id))
      break
  }

  if (query) {
    totalMiners = miners?.filter((item) => Number(item.id) === Number(query))
  }

  const sortedMiners = useMemo(() => {
    if (totalMiners) {
      return totalMiners.length > 0 ? totalMiners.slice(ITEMS * (page - 1), page * ITEMS) : []
    }
    return []
  }, [totalMiners, ITEMS, page])

  const maxPage = totalMiners && totalMiners.length > 0 ? Math.ceil(totalMiners.length / ITEMS) : 0

  return (
    <>
      <FilterOptions account={Boolean(account)} isMobile={isMobile}>
        {account && (
          <ButtonChecked
            account={Boolean(account)}
            active={showUserMiners}
            onClick={handleUserShowMiners}
            isMobile={isMobile}
          >
            <Trans>My Stake</Trans>
          </ButtonChecked>
        )}
        <Dropdown
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setShowOrder(Number(event.target.value))}
          isMobile={isMobile}
          account={Boolean(account)}
        >
          <option value={0} selected>{t`Order By`}</option>
          <option value={1}>{t`Caves`}</option>
          <option value={2}>{t`Power`}</option>
          <option value={3}>{t`Expiration`}</option>
        </Dropdown>
        <SearchFarm
          account={Boolean(account)}
          isMobile={isMobile}
          placeholder={t`Search ID`}
          onChange={handleChangeQuery}
        />
        <WrappedButtonError error onClick={() => setshowBurnModal(true)} isMobile={isMobile}>
          <Trans>Burn</Trans>
        </WrappedButtonError>
      </FilterOptions>
      {sortedMiners.map((item, index) => (
        <React.Fragment key={index}>
          <MinerCard
            id={Number(item.id)}
            owner={item.owner}
            cave={item.cave.id}
            expiration={item.expiration}
            stakedPower={item.stakedPower}
            onSale={item.onSale}
            salePrice={item.salePrice}
          />
        </React.Fragment>
      ))}
      {sortedMiners && sortedMiners.length > 0 ? (
        <PageButtons>
          <Arrow
            onClick={() => {
              setPage(page === 1 ? page : page - 1)
            }}
          >
            <ThemedText.DeprecatedBody color={page === 1 ? 'text' : 'primary'}> {'<'} </ThemedText.DeprecatedBody>
          </Arrow>

          <ThemedText.DeprecatedBody>
            <Trans>Page</Trans> {page} <Trans>of</Trans> {maxPage}
          </ThemedText.DeprecatedBody>
          <Arrow
            onClick={() => {
              setPage(page === maxPage ? page : page + 1)
            }}
          >
            <ThemedText.DeprecatedBody color={page === maxPage ? 'textDisabled' : 'primary'}> {'>'} </ThemedText.DeprecatedBody>
          </Arrow>
        </PageButtons>
      ) : (
        <BodyMessage>
          <Info color={darkmode ? '#5199FF' : '#2172E5'} />
          <ThemedText.DeprecatedBlue textAlign="center" ml="0.5rem" fontSize={24}>
            <Trans>No miners available</Trans>
          </ThemedText.DeprecatedBlue>
        </BodyMessage>
      )}
      <BurnMinerModal chainId={chainId} onDismiss={handleDissmisBurnModal} isOpen={showBurnModal} />
    </>
  )
}
