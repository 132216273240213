import SALES_ABI from 'abis/GreenBlockSale.json'
import BigNumber from 'bignumber.js'
import { SALES_ADDRESS } from 'constants/addresses'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
//import { BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'

import { currentCaveSalePack } from '../config'
import { PublicMinerSaleData } from '../types'

/**
 * Gets all public data of Sales contract
 */
const useGetPublicMinerSaleData = (chainId: number): PublicMinerSaleData => {
  const address = SALES_ADDRESS[chainId]
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    total4Sale: 380,
    usdPrice: new BigNumber(85000000),
    power: new BigNumber(87000000),
    duration: 31556926,
    sold: 0,
    loading: true,
  })

  const fetchMinerSaleData = useCallback(async () => {
    const saleCalls = [
      {
        address,
        name: 'minerSaleInfo',
        params: [currentCaveSalePack],
      },
    ]

    const [minerSaleInfo] = await multicall(chainId, SALES_ABI, saleCalls)

    setState((prev) => ({
      ...prev,
      total4Sale: Number(minerSaleInfo[0][0]),
      usdPrice: new BigNumber(minerSaleInfo[0][1].toString()),
      power: new BigNumber(minerSaleInfo[0][2].toString()),
      duration: Number(minerSaleInfo[0][3]),
      sold: Number(minerSaleInfo[0][4]),
      loading: false,
    }))
  }, [address, chainId])

  useEffect(() => {
    fetchMinerSaleData()
  }, [fetchMinerSaleData, slowRefresh])

  return { ...state, fetchMinerSaleData }
}

export default useGetPublicMinerSaleData
