/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import Modal from 'components/Modal'
import { JAMON } from 'constants/tokens'
import { ethers } from 'ethers'
import { useCavePoolContract, useTokenContract } from 'hooks/useContract'
import { JamonBonus } from 'pages/Mine/config'
import useWalletBalances from 'pages/Sale/hooks/useWalletBalances'
import { useCallback, useState } from 'react'
import { Info, X } from 'react-feather'
import styled from 'styled-components/macro'
import { CustomLightSpinner, ThemedText } from 'theme'
import { getFullDisplayBalance } from 'utils/formatBalance'

interface AddBonusProps {
  chainId: number
  caveId: number
  onDismiss: () => void
  update: () => void
  isOpen: boolean
}

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_blue4};
  border-radius: 12px;
  width: 100%;
  padding: 16px;
`

export default function AddBonusJamon({ chainId, caveId, onDismiss, isOpen, update }: AddBonusProps) {
  const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))
  const [approving, setApproving] = useState(false)
  const [confirming, setConfirming] = useState(false)

  const balanceData = useWalletBalances(chainId)
  const jamonContract = useTokenContract(JAMON[chainId].address)
  const cavePoolContract = useCavePoolContract()

  const { jamonBalance, jamonCaveAllowance } = balanceData

  const haveJamonBalance = jamonBalance.gte(JamonBonus)
  const isJamonAllowed = jamonCaveAllowance.gt(0)

  const handleApprove = useCallback(async () => {
    try {
      setApproving(true)
      const tx = await jamonContract?.approve(cavePoolContract?.address ?? '', ethers.constants.MaxUint256)
      await tx?.wait()
      balanceData.fetchWalletData()
      setApproving(false)
    } catch (e) {
      setApproving(false)
      console.error(e)
    } finally {
      setApproving(false)
    }
  }, [balanceData, cavePoolContract?.address, jamonContract])

  const handleAdd = useCallback(async () => {
    try {
      setConfirming(true)
      const tx = await cavePoolContract?.stakeJamon(caveId)
      await tx?.wait()
      balanceData.fetchWalletData()
      setConfirming(false)
      update()
      onDismiss()
    } catch (e) {
      setConfirming(false)
      console.error(e)
    } finally {
      setConfirming(false)
    }
  }, [balanceData, caveId, cavePoolContract, onDismiss, update])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <StyledClosed>
          <X size={24} onClick={onDismiss} />
        </StyledClosed>
        <ThemedText.LargeHeader textAlign="center" marginY="1rem" style={{ textTransform: 'uppercase' }}>
          <Trans>Bonus Jamon</Trans>
        </ThemedText.LargeHeader>
        <ModalBody>
          <Info size={28} color={darkmode ? '#5199FF' : '#2172E5'} />
          <ThemedText.DeprecatedBody textAlign="center" color="blue2" ml="4px">
            <Trans>Stake 100.000 JAMON to get bonus</Trans>
          </ThemedText.DeprecatedBody>
        </ModalBody>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '1rem',
            marginBottom: '1rem',
            fontSize: '14px',
            marginRight: '0.6rem',
          }}
        >
          <Trans>Balance</Trans>: {getFullDisplayBalance(jamonBalance, 18)} JAMON
        </div>
        {isJamonAllowed ? (
          <ButtonPrimary mt="1rem" disabled={!haveJamonBalance || confirming} onClick={handleAdd}>
            {!haveJamonBalance ? (
              <Trans>Insufficient Balance</Trans>
            ) : confirming ? (
              <>
                <Trans>Confirming</Trans>
                <CustomLightSpinner
                  src="/images/blue-loader.svg"
                  alt="loader"
                  size="24px"
                  style={{ marginLeft: '0.5rem' }}
                />
              </>
            ) : (
              <Trans>Confirm</Trans>
            )}
          </ButtonPrimary>
        ) : (
          <ButtonPrimary mt="1rem" disabled={approving} onClick={handleApprove}>
            {approving ? (
              <>
                <Trans>Enabling</Trans>
                <CustomLightSpinner
                  src="/images/blue-loader.svg"
                  alt="loader"
                  size="24px"
                  style={{ marginLeft: '0.5rem' }}
                />
              </>
            ) : (
              <Trans>Enable</Trans>
            )}
          </ButtonPrimary>
        )}
      </ModalWrapper>
    </Modal>
  )
}
