import { Trans } from "@lingui/macro";
import PriceIcon from "assets/images/home/cost-ico.png";
import EnergyIcon from "assets/images/home/energy-ico.png";
import HostIcon from "assets/images/home/host.png";
import MaintenanceIcon from "assets/images/home/maintenance-ico.png";
import MiningIcon from "assets/images/home/mining-ico.png";
import TickIcon from "assets/images/home/tick.png";
import { RadiantPrimaryCard, RadiantSecondaryCard } from "components/Card";
/* import Slide, { Roll } from "react-reveal"; */
import styled from "styled-components/macro";
import { ThemedText } from "theme";

const Container = styled.div`
  margin-top: 8rem;
`;

const ResponsiveBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  gap: 2rem;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    flex-direction: column;
    gap: 2rem;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    gap: 2rem;
  `};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export function Features() {
  return (
    <Container>
      <ResponsiveBox>
        <RadiantPrimaryCard height={292}>
          <Column>
            <img src={MiningIcon} alt="mining-icon" width={140} />
            <ThemedText.SubTitle textAlign="center">
              <Trans>Bitcoin mining power</Trans>
            </ThemedText.SubTitle>
          </Column>
        </RadiantPrimaryCard>
        <RadiantSecondaryCard height={292}>
          <Column>
            <img src={PriceIcon} alt="price-icon" width={140} />
            <ThemedText.SubTitle textAlign="center">
              <Trans>Best price</Trans>
            </ThemedText.SubTitle>
          </Column>
        </RadiantSecondaryCard>
        <RadiantPrimaryCard height={292}>
          <Column>
            <img src={TickIcon} alt="tick-icon" width={140} />
            <ThemedText.SubTitle textAlign="center">
              <Trans>Free of cost</Trans>
            </ThemedText.SubTitle>
          </Column>
        </RadiantPrimaryCard>
      </ResponsiveBox>
      <ResponsiveBox>
        <RadiantPrimaryCard height={292}>
          <Column>
            <img src={MaintenanceIcon} alt="maintenance-icon" width={140} />
            <ThemedText.SubTitle textAlign="center">
              <Trans>Maintenance</Trans>
            </ThemedText.SubTitle>
          </Column>
        </RadiantPrimaryCard>
        <RadiantSecondaryCard height={292}>
          <Column>
            <img src={HostIcon} alt="hosting-icon" width={140} />
            <ThemedText.SubTitle textAlign="center">
              <Trans>Hosting</Trans>
            </ThemedText.SubTitle>
          </Column>
        </RadiantSecondaryCard>
        <RadiantPrimaryCard height={292}>
          <Column>
            <img src={EnergyIcon} alt="energy-icon" width={140} />
            <ThemedText.SubTitle textAlign="center">
              <Trans>Energy</Trans>
            </ThemedText.SubTitle>
          </Column>
        </RadiantPrimaryCard>
      </ResponsiveBox>
    </Container>
  );
}
