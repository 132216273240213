// eslint-disable-next-line no-restricted-imports
import { t, Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { ButtonPrimary } from "components/Button";
import Modal from "components/Modal";
import { ZERO_ADDRESS } from "constants/misc";
import { useMinerNFTContract } from "hooks/useContract";
import useMinerAttributes from "pages/Mine/hooks/useMinerAttributes";
import useNFTsBalance from "pages/Mine/hooks/useNFTsBalance";
import { useCallback, useState } from "react";
import { AlertCircle, Info, X } from "react-feather";
import styled from "styled-components/macro";
import { CustomLightSpinner, ThemedText } from "theme";
import { getFullDisplayBalance } from "utils/formatBalance";
import { isMobile } from "utils/userAgent";

interface Props {
  chainId: number;
  onDismiss: () => void;
  isOpen: boolean;
}

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`;

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`;
const Dropdown = styled.select<{ isMobile?: boolean; account?: boolean }>`
  width: 100%;
  height: 2.5em;
  text-align: center;
  font-size: ${({ isMobile }) => (isMobile ? "10px" : "16px")};
  color: white;
  background: ${({ theme }) => theme.deprecated_primary2};
  border: none;
  border-radius: 20px;
  margin: auto;
  option {
    &:disabled {
      color: #b5b2b2;
    }
  }
`;

const BodyMessageInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_blue4};
  border-radius: 12px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 16px;
`;

const BodyAlertInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_yellow2};
  border-radius: 12px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 16px;
`;

const currentDate = () => new Date();

export default function BurnMinerModal({ chainId, onDismiss, isOpen }: Props) {
  const { account } = useWeb3React();
  const darkmode = Boolean(window.matchMedia("(prefers-color-scheme: dark)"));

  const burnWallet = "0x0a02F26C8711269ba81d422FAA4f58c1882FA2d0";

  const [confirming, setConfirming] = useState(false);
  const [value, setValue] = useState<number | undefined>(undefined);

  const { minerBalance, miners, fetchWalletBalanceData } =
    useNFTsBalance(chainId);

  const minerNFTContract = useMinerNFTContract();

  const NFTs = useMinerAttributes(chainId, miners);
  const NFTsReverse = NFTs.slice().reverse();

  const handleBurn = useCallback(async () => {
    if (value !== undefined) {
      try {
        setConfirming(true);
        const tx = await minerNFTContract?.transferFrom(
          account ?? ZERO_ADDRESS,
          burnWallet,
          value.toString()
        );
        await tx?.wait();
        fetchWalletBalanceData();
        setConfirming(false);
        onDismiss();
      } catch (e) {
        setConfirming(false);
        console.error(e);
      } finally {
        setConfirming(false);
      }
    }
  }, [account, fetchWalletBalanceData, minerNFTContract, onDismiss, value]);

  // let totalMiners = 0
  // NFTsReverse.map((miner) => {
  // if (new Date(miner.expiration * 1000) > currentDate()) {
  //     totalMiners = totalMiners + 1
  // }
  // return totalMiners
  // })

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <StyledClosed>
          <X size={24} onClick={onDismiss} />
        </StyledClosed>
        <ThemedText.LargeHeader
          textAlign="center"
          marginY="1rem"
          style={{ textTransform: "uppercase" }}
        >
          <Trans>Burn Miner</Trans>
        </ThemedText.LargeHeader>
        {minerBalance > 0 ? (
          <>
            <BodyMessageInfo>
              <Info color={darkmode ? "#5199FF" : "#2172E5"} />
              <ThemedText.DeprecatedBlue textAlign="center" ml="0.5rem">
                <Trans>You have</Trans> {minerBalance}{" "}
                {minerBalance > 1 ? "NFTs" : "NFT"}
              </ThemedText.DeprecatedBlue>
            </BodyMessageInfo>
            <Dropdown
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                setValue(
                  event.target.value ? Number(event.target.value) : undefined
                )
              }
              isMobile={isMobile}
              account={Boolean(account)}
            >
              <option value="" selected>{t`Select Miner`}</option>
              {NFTsReverse.map((attr, index) => {
                const power = getFullDisplayBalance(attr.power, 6);
                const expiration = new Date(attr.expiration * 1000);
                return (
                  <option key={index} value={attr.id}>
                    ID: {attr.id} - {power} Mhs -{" "}
                    {expiration < currentDate()
                      ? t`Expired`
                      : expiration.toLocaleString()}
                  </option>
                );
              })}
            </Dropdown>

            <ButtonPrimary
              mt="1rem"
              disabled={value === undefined || confirming}
              onClick={handleBurn}
            >
              {confirming ? (
                <>
                  <Trans>Burning</Trans>
                  <CustomLightSpinner
                    src="/images/blue-loader.svg"
                    alt="loader"
                    size="24px"
                    style={{ marginLeft: "0.5rem" }}
                  />
                </>
              ) : (
                <Trans>Burn</Trans>
              )}
            </ButtonPrimary>
          </>
        ) : (
          <div>
            <BodyAlertInfo>
              <AlertCircle color="red" />
              <ThemedText.DeprecatedBody
                textAlign="center"
                ml="0.5rem"
                style={{ color: "#FF8F00" }}
              >
                <Trans>You not have NFTs</Trans>
              </ThemedText.DeprecatedBody>
            </BodyAlertInfo>
          </div>
        )}
      </ModalWrapper>
    </Modal>
  );
}
