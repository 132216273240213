/* eslint-disable @typescript-eslint/no-restricted-imports */
/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from "@lingui/macro";
import BigNumber from "bignumber.js";
import { ButtonLight, ButtonPrimary } from "components/Button";
import { LightCard } from "components/Card";
import Modal from "components/Modal";
import { Input as NumericalInput } from "components/NumericalInput";
import { RowBetween } from "components/Row";
import { GBC } from "constants/tokens";
import { ethers } from "ethers";
import { useGbcPoolContract, useTokenContract } from "hooks/useContract";
import { useCallback, useMemo, useState } from "react";
import { Info, X } from "react-feather";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { BIG_TEN } from "utils/bigNumber";
import { getBalanceAmount, getFullDisplayBalance } from "utils/formatBalance";

import { PublicPoolData, WalletBalancesData, WalletPoolData } from "../types";

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`;

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`;

const StyledInput = styled(NumericalInput)`
  background-color: transparent;
  text-align: left;
  width: 100%;
  font-size: 24px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    font-size: 12px;
  `};
`;

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_blue4};
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  margin-top: 1rem;
`;

interface StakeModalProps {
  walletData: WalletBalancesData;
  poolData: PublicPoolData;
  walletPoolData: WalletPoolData;
  chainId: number;
  onDismiss: () => void;
  isOpen: boolean;
}

export function StakeModal({
  walletData,
  chainId,
  poolData,
  walletPoolData,
  onDismiss,
  isOpen,
}: StakeModalProps) {
  const [val, setVal] = useState("");
  const [enabling, setEnabling] = useState(false);
  const [confirming, setConfirming] = useState(false);

  const minStake = new BigNumber("2000").times(BIG_TEN.pow(18));

  const gbcContract = useTokenContract(GBC[chainId].address);
  const poolContract = useGbcPoolContract();

  const { gbtBalance, gbtPoolAllowance } = walletData;

  const { canStake, isInPool } = walletPoolData;

  const bigAmount = useMemo(() => {
    return new BigNumber(val);
  }, [val]);

  const valueWithTokenDecimals = bigAmount.times(BIG_TEN.pow(18));

  const isGbtAllowed = useMemo(() => {
    return gbtPoolAllowance.gte(valueWithTokenDecimals);
  }, [gbtPoolAllowance, valueWithTokenDecimals]);

  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(gbtBalance);
  }, [gbtBalance]);

  const handleChange = useCallback(
    (value: string) => {
      setVal(value.replace(/,/g, "."));
    },
    [setVal]
  );

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance);
  }, [fullBalance, setVal]);

  const handleApprove = useCallback(async () => {
    try {
      setEnabling(true);
      const tx = await gbcContract?.approve(
        poolContract?.address ?? "",
        ethers.constants.MaxUint256
      );
      await tx?.wait();
      walletData.fetchWalletData();
      setEnabling(false);
    } catch (e) {
      setEnabling(false);
      console.error(e);
    } finally {
      setEnabling(false);
      walletData.fetchWalletData();
    }
  }, [gbcContract, poolContract?.address, walletData]);

  const handleStake = useCallback(async () => {
    try {
      setConfirming(true);
      const tx = await poolContract?.stake(valueWithTokenDecimals.toFixed(0));
      await tx?.wait();
      walletData.fetchWalletData();
      poolData.fetchPublicPoolData();
      walletPoolData.fetchWalletPoolData();
      setConfirming(false);
      onDismiss();
    } catch (e) {
      setConfirming(false);
      console.error(e);
    } finally {
      setConfirming(false);
    }
  }, [
    onDismiss,
    poolContract,
    poolData,
    valueWithTokenDecimals,
    walletData,
    walletPoolData,
  ]);

  const isPrimaryEnabled = () => {
    if (!canStake) {
      return false;
    }
    if (!val) {
      return false;
    }
    if (valueWithTokenDecimals.eq(0)) {
      return false;
    }
    if (valueWithTokenDecimals.gt(gbtBalance)) {
      return false;
    }
    if (!isInPool && valueWithTokenDecimals.lt(minStake)) {
      return false;
    }
    if (enabling) {
      return false;
    }
    if (confirming) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    if (!canStake) {
      return t`Forbidden`;
    }
    if (!val) {
      return t`Enter Amount`;
    }
    if (valueWithTokenDecimals.eq(0)) {
      return t`Enter Amount`;
    }
    if (valueWithTokenDecimals.gt(gbtBalance)) {
      return t`Insufficient balance`;
    }
    if (!isInPool && valueWithTokenDecimals.lt(minStake)) {
      return t`Min 2000 GBC`;
    }
    if (enabling) {
      return t`Approving...`;
    }
    if (!isGbtAllowed) {
      return t`Approve`;
    }

    if (confirming) {
      return t`Staking...`;
    }

    return t`Stake`;
  };

  const onClickPrimary = () => {
    if (!isGbtAllowed) {
      handleApprove();
      return;
    }

    handleStake();
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <RowBetween>
          <ThemedText.MediumHeader>Stake GBC</ThemedText.MediumHeader>
          <StyledClosed>
            <X size={24} onClick={onDismiss} />
          </StyledClosed>
        </RowBetween>
        <ModalBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "20%",
            }}
          >
            <Info size={28} color="#FFD300" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "80%",
              color: "#FFD300",
            }}
          >
            <ThemedText.DeprecatedBody
              textAlign="left"
              color="#FFD300"
              ml="4px"
            >
              <Trans>
                The stake has a blocking time of 3 months, once the stake has
                started you will not be able to unstake until after 3 months.
                Every time you add new tokens to stake the time will reset
              </Trans>
              .
            </ThemedText.DeprecatedBody>
            <ThemedText.DeprecatedBody
              textAlign="left"
              color="#FFD300"
              ml="4px"
            >
              <Trans>The minimum amount to stake is 2000 GBC</Trans>.
            </ThemedText.DeprecatedBody>
          </div>
        </ModalBody>
        <LightCard $borderRadius="8px" mt="2rem">
          <RowBetween>
            <StyledInput
              className="amount"
              type="number"
              step={0.0}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              value={val}
              onUserInput={handleChange}
            />
            <ButtonLight
              width="20%"
              height="10px"
              disabled={gbtBalance.eq(0)}
              onClick={handleSelectMax}
            >
              Max
            </ButtonLight>
          </RowBetween>
        </LightCard>
        <ThemedText.DeprecatedBody textAlign="right" mt="1rem">
          <Trans>Balance</Trans>: {getBalanceAmount(gbtBalance).toString()} GBC
        </ThemedText.DeprecatedBody>
        <ButtonPrimary
          mt="1rem"
          disabled={!isPrimaryEnabled()}
          onClick={onClickPrimary}
        >
          {getPrimaryText()}
        </ButtonPrimary>
      </ModalWrapper>
    </Modal>
  );
}
