/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from "@lingui/macro";
import { ButtonPrimary } from "components/Button";
import Modal from "components/Modal";
import { useNftPoolContract } from "hooks/useContract";
import { useCallback, useState } from "react";
import { AlertCircle, Info, X } from "react-feather";
import styled from "styled-components/macro";
import { CustomLightSpinner, ThemedText } from "theme";
import { isMobile } from "utils/userAgent";

import { PublicPoolData, WalletNFTsBalancesData } from "../types";
import { WalletPoolData } from "../types";

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`;

const Dropdown = styled.select<{ isMobile?: boolean }>`
  width: 100%;
  height: 2.5em;
  text-align: center;
  font-size: ${({ isMobile }) => (isMobile ? "10px" : "16px")};
  color: white;
  background: ${({ theme }) => theme.deprecated_primary2};
  border: none;
  border-radius: 20px;
  margin: auto;
`;

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`;

const BodyMessageInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_blue4};
  border-radius: 12px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 16px;
`;

const BodyAlertInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_yellow1};
  border-radius: 12px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 16px;
`;

interface UnstakeModalProps {
  walletData: WalletNFTsBalancesData;
  poolData: PublicPoolData;
  walletPoolData: WalletPoolData;
  onDismiss: () => void;
  isOpen: boolean;
}

export function UnstakeModal({
  walletData,
  poolData,
  walletPoolData,
  onDismiss,
  isOpen,
}: UnstakeModalProps) {
  const darkmode = Boolean(window.matchMedia("(prefers-color-scheme: dark)"));
  const [val, setVal] = useState<number | undefined>();
  const [unStaking, setUnStaking] = useState(false);

  const poolContract = useNftPoolContract();

  const { stakedBal, ids } = walletPoolData;

  const handleUnstake = useCallback(async () => {
    if (val !== undefined) {
      try {
        setUnStaking(true);
        const tx = await poolContract?.withdrawn(val.toString());
        await tx?.wait();
        walletData.fetchWalletBalanceData();
        poolData.fetchPublicPoolData();
        walletPoolData.fetchWalletPoolData();
        setUnStaking(false);
        onDismiss();
      } catch (e) {
        setUnStaking(false);
        console.error(e);
      } finally {
        setUnStaking(false);
      }
    }
  }, [onDismiss, poolContract, poolData, walletData, val, walletPoolData]);

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <StyledClosed>
          <X size={24} onClick={onDismiss} />
        </StyledClosed>
        <ThemedText.LargeHeader
          textAlign="center"
          marginY="1rem"
          style={{ textTransform: "uppercase" }}
        >
          <Trans>Unstake NFT</Trans>
        </ThemedText.LargeHeader>
        {stakedBal > 0 ? (
          <>
            <BodyMessageInfo>
              <Info color={darkmode ? "#5199FF" : "#2172E5"} />
              <ThemedText.DeprecatedBlue textAlign="center" ml="0.5rem">
                <Trans>You have staked</Trans> {stakedBal.toString()}{" "}
                {stakedBal > 1 ? "NFTs" : "NFT"}
              </ThemedText.DeprecatedBlue>
            </BodyMessageInfo>
            <Dropdown
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                setVal(
                  event.target.value ? Number(event.target.value) : undefined
                )
              }
              isMobile={isMobile}
            >
              <option value={undefined} selected>{t`Select NFT`}</option>
              {ids.map((attr, index) => {
                return (
                  <option key={index} value={attr}>
                    ID: {attr}
                  </option>
                );
              })}
            </Dropdown>
            <ButtonPrimary
              mt="1rem"
              disabled={val === undefined || unStaking}
              onClick={handleUnstake}
            >
              {unStaking ? (
                <>
                  <Trans>Confirming</Trans>
                  <CustomLightSpinner
                    src="/images/blue-loader.svg"
                    alt="loader"
                    size="24px"
                    style={{ marginLeft: "0.5rem" }}
                  />
                </>
              ) : (
                <Trans>Confirm</Trans>
              )}
            </ButtonPrimary>
          </>
        ) : (
          <div>
            <BodyAlertInfo>
              <AlertCircle color="#FF8F00" />
              <ThemedText.DeprecatedBody
                textAlign="center"
                ml="0.5rem"
                style={{ color: "#FF8F00" }}
              >
                <Trans>You not have NFTs</Trans>
              </ThemedText.DeprecatedBody>
            </BodyAlertInfo>
          </div>
        )}
      </ModalWrapper>
    </Modal>
  );
}
