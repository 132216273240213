/* eslint-disable @typescript-eslint/no-restricted-imports */
/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { ButtonEmpty, ButtonPrimary } from "components/Button";
import Modal from "components/Modal";
import { useGbcNftContract, useNftPoolContract } from "hooks/useContract";
import { useCallback, useState } from "react";
import { AlertCircle, Info, X } from "react-feather";
import styled from "styled-components/macro";
import { CustomLightSpinner, ThemedText } from "theme";
import { isMobile } from "utils/userAgent";

import {
  PublicPoolData,
  WalletNFTsBalancesData,
  WalletPoolData,
} from "../types";

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`;

const Dropdown = styled.select<{ isMobile?: boolean; account?: boolean }>`
  width: 100%;
  height: 2.5em;
  text-align: center;
  font-size: ${({ isMobile }) => (isMobile ? "10px" : "16px")};
  color: white;
  background: ${({ theme }) => theme.deprecated_primary2};
  border: none;
  border-radius: 20px;
  margin: auto;
`;

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`;

const ButtonBuy = styled(ButtonEmpty)`
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.deprecated_secondary2};
  }
`;

const BodyMessageInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_blue4};
  border-radius: 12px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 16px;
`;

const BodyAlertInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_yellow1};
  border-radius: 12px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 16px;
`;

interface StakeModalProps {
  walletData: WalletNFTsBalancesData;
  poolData: PublicPoolData;
  walletPoolData: WalletPoolData;
  chainId: number;
  onDismiss: () => void;
  isOpen: boolean;
}

export function StakeModal({
  walletData,
  chainId,
  poolData,
  walletPoolData,
  onDismiss,
  isOpen,
}: StakeModalProps) {
  const darkmode = Boolean(window.matchMedia("(prefers-color-scheme: dark)"));
  const { account } = useWeb3React();
  const [approving, setApproving] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [value, setValue] = useState<number | undefined>();

  const gbcContract = useGbcNftContract();
  const poolContract = useNftPoolContract();

  const { nftBalance, nftAllowance, ids, fetchWalletBalanceData } = walletData;

  const handleApprove = useCallback(async () => {
    try {
      setApproving(true);
      const tx = await gbcContract?.setApprovalForAll(
        poolContract?.address ?? "",
        true
      );
      await tx?.wait();
      fetchWalletBalanceData();
      setApproving(false);
    } catch (e) {
      setApproving(false);
      console.error(e);
    } finally {
      setApproving(false);
    }
  }, [gbcContract, poolContract?.address, fetchWalletBalanceData]);

  const handleStake = useCallback(async () => {
    if (value !== undefined) {
      try {
        setConfirming(true);
        const tx = await poolContract?.stake(value.toString());
        await tx?.wait();
        fetchWalletBalanceData();
        poolData.fetchPublicPoolData();
        walletPoolData.fetchWalletPoolData();
        setConfirming(false);
        onDismiss();
      } catch (e) {
        setConfirming(false);
        console.error(e);
      } finally {
        setConfirming(false);
      }
    }
  }, [poolContract, fetchWalletBalanceData, onDismiss, value]);

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <StyledClosed>
          <X size={24} onClick={onDismiss} />
        </StyledClosed>
        <ThemedText.LargeHeader
          textAlign="center"
          marginY="1rem"
          style={{ textTransform: "uppercase" }}
        >
          <Trans>Stake NFT</Trans>
        </ThemedText.LargeHeader>
        {nftBalance > 0 ? (
          <>
            <BodyMessageInfo>
              <Info color={darkmode ? "#5199FF" : "#2172E5"} />
              <ThemedText.DeprecatedBlue textAlign="center" ml="0.5rem">
                <Trans>You have</Trans> {nftBalance}{" "}
                {nftBalance > 1 ? "NFTs" : "NFT"}
              </ThemedText.DeprecatedBlue>
            </BodyMessageInfo>
            <Dropdown
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                setValue(
                  event.target.value ? Number(event.target.value) : undefined
                )
              }
              isMobile={isMobile}
              account={Boolean(account)}
            >
              <option value={undefined} selected>{t`Select NFT`}</option>
              {ids.map((attr, index) => {
                return (
                  <option key={index} value={attr}>
                    ID: {attr}
                  </option>
                );
              })}
            </Dropdown>
            {nftAllowance ? (
              <ButtonPrimary
                mt="1rem"
                disabled={value === undefined || confirming}
                onClick={handleStake}
              >
                {confirming ? (
                  <>
                    <Trans>Confirming</Trans>
                    <CustomLightSpinner
                      src="/images/blue-loader.svg"
                      alt="loader"
                      size="24px"
                      style={{ marginLeft: "0.5rem" }}
                    />
                  </>
                ) : (
                  <Trans>Confirm</Trans>
                )}
              </ButtonPrimary>
            ) : (
              <ButtonPrimary
                mt="1rem"
                disabled={approving}
                onClick={handleApprove}
              >
                {approving ? (
                  <>
                    <Trans>Enabling</Trans>
                    <CustomLightSpinner
                      src="/images/blue-loader.svg"
                      alt="loader"
                      size="24px"
                      style={{ marginLeft: "0.5rem" }}
                    />
                  </>
                ) : (
                  <Trans>Enable</Trans>
                )}
              </ButtonPrimary>
            )}
          </>
        ) : (
          <div>
            <BodyAlertInfo>
              <AlertCircle color="#FF8F00" />
              <ThemedText.DeprecatedBody
                textAlign="center"
                ml="0.5rem"
                style={{ color: "#FF8F00" }}
              >
                <Trans>You not have NFTs</Trans>
              </ThemedText.DeprecatedBody>
            </BodyAlertInfo>
          </div>
        )}
      </ModalWrapper>
    </Modal>
  );
}
