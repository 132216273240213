/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import Modal from 'components/Modal'
import { ZERO_ADDRESS } from 'constants/misc'
import { USDC } from 'constants/tokens'
import { ethers } from 'ethers'
import { useSalesContract, useTokenContract } from 'hooks/useContract'
import { currentTimestamp } from 'pages/Sale'
import { currentCaveSalePack, whitelist, whitelistEnd } from 'pages/Sale/config'
import { PublicMinerSaleData, WalletBalancesData } from 'pages/Sale/types'
import { useCallback, useState } from 'react'
import { X } from 'react-feather'
/* import { useDarkModeManager, useUserReferrer } from 'state/user/hooks' */
import styled from 'styled-components/macro'
import { CustomLightSpinner, ThemedText } from 'theme'
import { getFullDisplayBalance } from 'utils/formatBalance'

interface AddBonusProps {
  chainId: number
  saleData: PublicMinerSaleData
  walletData: WalletBalancesData
  onDismiss: () => void
  isOpen: boolean
}

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text4};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const Selector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`

export default function BuyMiner({ chainId, saleData, walletData, onDismiss, isOpen }: AddBonusProps) {
  const { account } = useWeb3React()
  const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))
  const [localValue, setLocalValue] = useState(1)
  const [approving, setApproving] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const { total4Sale, usdPrice, sold } = saleData
  const { usdtBalance, usdtAllowance } = walletData

  const availableUSDT = total4Sale - sold

  const haveStock = availableUSDT >= localValue

  const bigTotalUSDT = usdPrice.times(localValue)
  const haveUsdtBalance = usdtBalance.gte(bigTotalUSDT)
  const isUsdtAllowed = usdtAllowance.gt(0)

  const salesContract = useSalesContract()
  const usdtContract = useTokenContract(USDC[chainId].address)

  /* const userReferrer = useUserReferrer() */
  const referrer = /* userReferrer === account ?  */ZERO_ADDRESS /* : userReferrer */

  const whitelistActive = whitelistEnd > currentTimestamp()

  const onWhitelistNorm = whitelist.includes(account ?? ZERO_ADDRESS)
  const onWhitelistLow = whitelist.includes(account?.toLowerCase() ?? ZERO_ADDRESS)
  const onWhitelistUp = whitelist.includes(account?.toUpperCase() ?? ZERO_ADDRESS)
  const onWhitelist = onWhitelistNorm || onWhitelistLow || onWhitelistUp

  const allowed = whitelistActive ? onWhitelist : true

  const handleApproveUsdt = useCallback(async () => {
    try {
      setApproving(true)
      const tx = await usdtContract?.approve(salesContract?.address ?? '', ethers.constants.MaxUint256)
      await tx?.wait()
      walletData.fetchWalletData()
      setApproving(false)
    } catch (e) {
      setApproving(false)
      console.error(e)
    } finally {
      setApproving(false)
    }
  }, [usdtContract, salesContract?.address, walletData])

  const handleBuy = useCallback(async () => {
    try {
      setConfirming(true)
      const tx = await salesContract?.buyMiner(currentCaveSalePack, localValue, referrer ?? ZERO_ADDRESS)
      await tx?.wait()
      walletData.fetchWalletData()
      saleData.fetchMinerSaleData()
      setConfirming(false)
      onDismiss()
    } catch (e) {
      setConfirming(false)
      console.error(e)
    } finally {
      setConfirming(false)
    }
  }, [localValue, onDismiss, referrer, saleData, salesContract, walletData])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <StyledClosed>
          <X size={24} onClick={onDismiss} />
        </StyledClosed>
        <ThemedText.LargeHeader textAlign="center" marginY="1rem" style={{ textTransform: 'uppercase' }}>
          <Trans>Buy Miner</Trans>
        </ThemedText.LargeHeader>
        <Selector>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '50%' }}>
            <ThemedText.MediumHeader fontWeight="bold" fontSize="1rem" color={darkmode ? '#5199FF' : '#25B728'}>
              {localValue}
            </ThemedText.MediumHeader>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'right', width: '50%' }}>
            <ButtonPrimary
              size="5px"
              marginX="0.5rem"
              onClick={() => setLocalValue(localValue - 1)}
              disabled={localValue === 1}
            >
              -
            </ButtonPrimary>
            <ButtonPrimary
              size="5px"
              marginX="0.5rem"
              onClick={() => setLocalValue(localValue + 1)}
              disabled={localValue === 10}
            >
              +
            </ButtonPrimary>
            <ButtonPrimary disabled={localValue === 10} size="10px" marginX="0.5rem" onClick={() => setLocalValue(10)}>
              Max
            </ButtonPrimary>
          </div>
        </Selector>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginBottom: '1rem',
            fontSize: '14px',
            marginRight: '0.6rem',
          }}
        >
          <>
            {' '}
            <Trans>Balance</Trans>: {getFullDisplayBalance(usdtBalance, 6)} USDC
          </>
        </div>
        <>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Trans>Cost</Trans>: {getFullDisplayBalance(bigTotalUSDT, 6)} USDC
          </div>
          {isUsdtAllowed ? (
            <ButtonPrimary
              mt="1rem"
              disabled={!haveStock || !haveUsdtBalance || confirming || !allowed}
              onClick={handleBuy}
            >
              {!haveStock ? (
                <Trans>Insufficient Stock</Trans>
              ) : !haveUsdtBalance ? (
                <Trans>Insufficient Balance</Trans>
              ) : confirming ? (
                <>
                  <Trans>Confirming</Trans>
                  <CustomLightSpinner
                    src="/images/blue-loader.svg"
                    alt="loader"
                    size={'24px'}
                    style={{ marginLeft: '0.5rem' }}
                  />
                </>
              ) : allowed ? (
                <Trans>Buy</Trans>
              ) : (
                <Trans>Not whitelisted</Trans>
              )}
            </ButtonPrimary>
          ) : (
            <ButtonPrimary mt="1rem" disabled={approving} onClick={handleApproveUsdt}>
              {approving ? (
                <>
                  <Trans>Enabling</Trans>
                  <CustomLightSpinner
                    src="/images/blue-loader.svg"
                    alt="loader"
                    size={'24px'}
                    style={{ marginLeft: '0.5rem' }}
                  />
                </>
              ) : (
                <Trans>Enable</Trans>
              )}
            </ButtonPrimary>
          )}
        </>
      </ModalWrapper>
    </Modal>
  )
}
