import SALES_ABI from 'abis/GreenBlockSale.json'
import BigNumber from 'bignumber.js'
import { SALES_ADDRESS } from 'constants/addresses'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
//import { BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'

import { currentCaveSalePack } from '../config'
import { PublicCaveSaleData } from '../types'

/**
 * Gets all public data of Sales contract
 */
const useGetPublicCaveSaleData = (chainId: number): PublicCaveSaleData => {
  const address = SALES_ADDRESS[chainId]
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState({
    total4Sale: 380,
    usdPrice: new BigNumber(500000000),
    power: new BigNumber(103000000),
    duration: 157784630,
    sold: 0,
    total4Jamon: 60,
    soldInJamon: 0,
    jamonPrice: new BigNumber(446408240000000000000000),
    loading: true,
  })

  const fetchCaveSaleData = useCallback(async () => {
    const saleCalls = [
      {
        address,
        name: 'caveSaleInfo',
        params: [currentCaveSalePack],
      },
      {
        address,
        name: 'caveJamonPrice',
        params: [currentCaveSalePack],
      },
    ]

    const [caveSaleInfo, caveJamonPrice] = await multicall(chainId, SALES_ABI, saleCalls)

    setState((prev) => ({
      ...prev,
      total4Sale: Number(caveSaleInfo[0][0]),
      usdPrice: new BigNumber(caveSaleInfo[0][1].toString()),
      power: new BigNumber(caveSaleInfo[0][2].toString()),
      duration: Number(caveSaleInfo[0][3]),
      sold: Number(caveSaleInfo[0][4]),
      total4Jamon: Number(caveSaleInfo[0][5]),
      soldInJamon: Number(caveSaleInfo[0][6]),
      jamonPrice: new BigNumber(caveJamonPrice[0].toString()),
      loading: false,
    }))
  }, [address, chainId])

  useEffect(() => {
    fetchCaveSaleData()
  }, [fetchCaveSaleData, slowRefresh])

  return { ...state, fetchCaveSaleData }
}

export default useGetPublicCaveSaleData
